// **
// * Variables
// **

$colors: (
  primary: #25373e,
  secondary: #e9a844,
  tertiary: #3d6574,
  white: #fff,
  light-primary: #25373eed,
  extra-light-primary: #25383d0f,
  light-secondary: #e9a8449c,
);

$font-family-base: 'Open Sans';
$font-family-heading: 'Hind';

$breakpoints: (
  'xs': 600px,
  'sm': 782px,
  'md': 1024px,
  'lg': 1220px,
  'xl': 1460px,
);

/**
* Igniter Utilities settings
*/
$grid-active: true; // include grid container and column classes
$helper-zindex: true;
$helper-positions: true;
$helper-border: true;
$spacer: (
  '0': 0,
  'xs': 0.25rem,
  'sm': 0.5rem,
  '1': 1rem,
  '2': 2rem,
  '3': 3rem,
  '4': 4rem,
  '5': 5rem,
  '6': 6rem,
  '7': 7rem,
  '8': 8rem,
  '9': 9rem,
  '10': 10rem,
  '15': 15rem,
  '20': 20rem,
  '30': 30rem,
  '40': 40rem,
  '50': 50rem,
  '60': 60rem,
  '70': 70rem,
  '80': 80rem,
  '90': 90rem,
  '100': 100rem,
  '10p': 10%,
  '20p': 20%,
  '30p': 30%,
  '40p': 40%,
  '50p': 50%,
  '60p': 60%,
  '70p': 70%,
  '80p': 80%,
  '90p': 90%,
  '100p': 100%,
);
