/**
 * Global
 */

body {
  font-family: $font-family-base;
}

a {
  color: color('primary');
  text-decoration: none;
  background-color: transparent;
  transition: opacity 100ms ease-in-out;

  &:hover {
    opacity: 0.85;
  }
}

svg,
img,
embed,
object {
  display: block;
  height: auto;
  max-width: 100%;
}

#map {
  height: 600px;
  width: 100%;
}
