.site-footer {
  background: color('light-primary');
  color: white;

  a {
    color: color('secondary');
  }

  h4 {
    color: color('secondary');
  }

  .main-logo {
    width: 13em;
  }

  nav.site-footer--nav {
    li {
      list-style: none;

      a {
        color: white;
        font-family: $font-family-base;
        text-transform: inherit;
      }
    }
  }

  .pre-footer {
    background: color('light-primary');

    nav.site-footer--nav {
      display: flex;

      li {
        padding-right: 1em;

        a {
          font-family: $font-family-heading;
          text-transform: uppercase;
        }
      }
    }
  }
}
