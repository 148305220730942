/**
* Gutenberg Color helper classes
* - rememer to set colors for backend
*/
@each $color-name, $color-value in $colors {
  .has-#{'' + $color-name}-color {
    color: $color-value;
  }

  .has-#{'' + $color-name}-background-color {
    background-color: $color-value;
  }
}

/**
* Container settings for Gutenberg Markup
*/

// force center for some gutenberg blocks
.container {
  margin-left: auto !important;
  margin-right: auto !important;
}

.wp-block-group {
  &__inner-container {
    @extend .container !optional;
  }

  &.no-container-margin {
    .wp-block-group__inner-container {
      margin: 0 !important;
      max-width: unset;
    }
  }

  &.marker-before {
    padding-left: 4em;
    margin-top: -50px;

    &::before {
      content: '';
      background-size: 50px 50px;
      background-repeat: no-repeat;
      display: inline-block;
      width: 50px;
      height: 50px;
      position: relative;
      top: 53px;
      left: -4em;
      background-image: url('../img/green-marker.svg');
    }

    &.marker-white {
      &::before {
        background-image: url('../img/white-marker.svg');
      }
    }
    // &.engage {}
    &.marker-bestattungen {
      padding-left: 5em;
      margin-top: 0;

      &::before {
        position: absolute;
        top: 0;
        left: 2em;
      }
    }
  }
}

.wp-block-column {
  .mail-tel {
    a {
      color: color('secondary');

      &::before {
        content: '';
        background-size: 30px 30px;
        background-repeat: no-repeat;
        display: inline-block;
        width: 40px;
        height: 30px;
        position: relative;
        top: 10px;
      }

      &:first-of-type::before {
        background-image: url('../img/telefon-icon.svg');
      }

      &:last-of-type::before {
        background-image: url('../img/mail-icon.svg');
      }
    }
  }
}

.wp-block-separator.is-style-wide {
  border: 1px solid;
}

.site-content {
  > * {
    @extend .container !optional;
  }
}

.alignfull {
  max-width: 100%;
}

.wp-block-button {
  a {
    font-family: $font-family-heading;
    text-transform: uppercase;
  }
}

/**
* Gutenberg Circle Block
*/

.circles {
  &.circles-hide-mobile {

    @media (max-width: 991px) {
      display: none;
    }
  }

  position: absolute;
  height: 22em;
  width: 100%;
  z-index: 0;

  .circle-animated {
    content: '';
    border-radius: 50%;
    border: 1px solid color('primary');
    width: 150px;
    height: 150px;
    position: absolute;
    opacity: 0;
    left: -5em;
    top: 5em;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.1, 0.1);
    opacity: 1;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

/**
* Gutenberg Accordion Plugin
*/

.c-accordion__title {
  color: color('primary');
  border-bottom: 1px solid color('primary');
  padding-bottom: 0.5em;
  padding-right: 3em;
  margin: 1em 0;
  font-size: 1em;
  @media (max-width: 991px) {
    padding-right: 4em;
    font-size: 1em;
  }

  span {
    display: flex;
    font-size: 17px;
    color: black;
    font-weight: normal;
    margin-top: 5px;
  }

  &::after {
    content: '˅';
    top: 30%;
    background-color: none;
    border-radius: 28px;
    color: color('primary');
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin: 0;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
    transition: all 0.5s ease;
    @media (max-width: 991px) {
      font-size: 12px;
      padding: 8px 14px;
    }
  }

  &:focus {
    outline: none;
  }
}

.is-open {
  .c-accordion__title {
    &::after {
      content: '˄';
      font-weight: bold;
      padding: 12px 15px;
    }
  }
}

.c-accordion__content {
  img {
    height: auto;
  }
}

/**
* Add Helper Classes
*/

// display Helper
@media (min-width: breakpoint(xs)) {
  .xs-show,
  .xs-block {
    display: block !important;
  }

  .xs-flex {
    display: flex !important;
  }

  .xs-initial {
    display: initial !important;
  }
}

@media (min-width: breakpoint(sm)) {
  .sm-show,
  .sm-block {
    display: block !important;
  }

  .sm-flex {
    display: flex !important;
  }

  .sm-initial {
    display: initial !important;
  }
}

@media (min-width: breakpoint(md)) {
  .md-show,
  .md-block {
    display: block !important;
  }

  .md-flex {
    display: flex !important;
  }

  .md-initial {
    display: initial !important;
  }
}

@media (min-width: breakpoint(lg)) {
  .lg-show,
  .lg-block {
    display: block !important;
  }

  .lg-flex {
    display: flex !important;
  }

  .lg-initial {
    display: initial !important;
  }
}

@media (min-width: breakpoint(xl)) {
  .xl-show,
  .xl-block {
    display: block !important;
  }

  .xl-flex {
    display: flex !important;
  }

  .xl-initial {
    display: initial !important;
  }
}

// Block media-text reverse-mobile
@media (max-width: breakpoint(xs)) {
  .block-media-text-reverse-mobile {
    .wp-block-media-text__media {
      grid-row: 2 !important;
    }

    .wp-block-media-text__content {
      grid-row: 1 !important;
    }

    &.has-media-on-the-right {
      .wp-block-media-text__media {
        grid-row: 1 !important;
      }

      .wp-block-media-text__content {
        grid-row: 2 !important;
      }
    }
  }
}
