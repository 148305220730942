/**
 * Form
 */

.hf-form {
  color: color('primary');

  .hf-message {
    text-align: center;
    font-family: $font-family-heading;
    font-size: 2em;
    margin: 3em 0;
  }

  input[type='text'],
  input[type='email'],
  textarea,
  select {
    background: color('extra-light-primary');
    color: color('primary');
    padding: 1rem;
    border: 0;

    &:focus {
      border: 1px solid color('primary') !important;
      outline: 0;
    }
  }

  input[type='submit'],
  button[type='submit'] {
    border: 0;
    background: color('secondary');
    padding: 1em;
    color: color('primary');

    &:hover {
      cursor: pointer;
    }
  }
}
