@charset "UTF-8";
/** Config */
/**
* Igniter Utilities settings
*/
/**
 * External
 *
 * Optional - only activate if required
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type=checkbox],
input[type=radio] {
  cursor: pointer;
  vertical-align: baseline;
}

.container, .site-content > *, .wp-block-group__inner-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

@media (min-width: 1460px) {
  .container, .site-content > *, .wp-block-group__inner-container {
    max-width: 1460px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.col,
.row {
  box-sizing: border-box;
  -webkit-box-flex: 0;
}

.col {
  padding-right: 1rem;
  padding-left: 1rem;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
}

.xs\:5 {
  flex-basis: 5%;
  max-width: 5%;
}

.xs\:10 {
  flex-basis: 10%;
  max-width: 10%;
}

.xs\:15 {
  flex-basis: 15%;
  max-width: 15%;
}

.xs\:20 {
  flex-basis: 20%;
  max-width: 20%;
}

.xs\:25 {
  flex-basis: 25%;
  max-width: 25%;
}

.xs\:30 {
  flex-basis: 30%;
  max-width: 30%;
}

.xs\:35 {
  flex-basis: 35%;
  max-width: 35%;
}

.xs\:40 {
  flex-basis: 40%;
  max-width: 40%;
}

.xs\:45 {
  flex-basis: 45%;
  max-width: 45%;
}

.xs\:50 {
  flex-basis: 50%;
  max-width: 50%;
}

.xs\:55 {
  flex-basis: 55%;
  max-width: 55%;
}

.xs\:60 {
  flex-basis: 60%;
  max-width: 60%;
}

.xs\:65 {
  flex-basis: 65%;
  max-width: 65%;
}

.xs\:70 {
  flex-basis: 70%;
  max-width: 70%;
}

.xs\:75 {
  flex-basis: 75%;
  max-width: 75%;
}

.xs\:80 {
  flex-basis: 80%;
  max-width: 80%;
}

.xs\:85 {
  flex-basis: 85%;
  max-width: 85%;
}

.xs\:90 {
  flex-basis: 90%;
  max-width: 90%;
}

.xs\:95 {
  flex-basis: 95%;
  max-width: 95%;
}

.xs\:100 {
  flex-basis: 100%;
  max-width: 100%;
}

.xs\:33 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.xs\:66 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.xs\:half {
  flex-basis: 50%;
  max-width: 50%;
}

.xs\:full {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 782px) {
  .sm\:5 {
    flex-basis: 5%;
    max-width: 5%;
  }

  .sm\:10 {
    flex-basis: 10%;
    max-width: 10%;
  }

  .sm\:15 {
    flex-basis: 15%;
    max-width: 15%;
  }

  .sm\:20 {
    flex-basis: 20%;
    max-width: 20%;
  }

  .sm\:25 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .sm\:30 {
    flex-basis: 30%;
    max-width: 30%;
  }

  .sm\:35 {
    flex-basis: 35%;
    max-width: 35%;
  }

  .sm\:40 {
    flex-basis: 40%;
    max-width: 40%;
  }

  .sm\:45 {
    flex-basis: 45%;
    max-width: 45%;
  }

  .sm\:50 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .sm\:55 {
    flex-basis: 55%;
    max-width: 55%;
  }

  .sm\:60 {
    flex-basis: 60%;
    max-width: 60%;
  }

  .sm\:65 {
    flex-basis: 65%;
    max-width: 65%;
  }

  .sm\:70 {
    flex-basis: 70%;
    max-width: 70%;
  }

  .sm\:75 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .sm\:80 {
    flex-basis: 80%;
    max-width: 80%;
  }

  .sm\:85 {
    flex-basis: 85%;
    max-width: 85%;
  }

  .sm\:90 {
    flex-basis: 90%;
    max-width: 90%;
  }

  .sm\:95 {
    flex-basis: 95%;
    max-width: 95%;
  }

  .sm\:100 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .sm\:33 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .sm\:66 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .sm\:half {
    flex-basis: 50%;
    max-width: 50%;
  }

  .sm\:full {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .md\:5 {
    flex-basis: 5%;
    max-width: 5%;
  }

  .md\:10 {
    flex-basis: 10%;
    max-width: 10%;
  }

  .md\:15 {
    flex-basis: 15%;
    max-width: 15%;
  }

  .md\:20 {
    flex-basis: 20%;
    max-width: 20%;
  }

  .md\:25 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .md\:30 {
    flex-basis: 30%;
    max-width: 30%;
  }

  .md\:35 {
    flex-basis: 35%;
    max-width: 35%;
  }

  .md\:40 {
    flex-basis: 40%;
    max-width: 40%;
  }

  .md\:45 {
    flex-basis: 45%;
    max-width: 45%;
  }

  .md\:50 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .md\:55 {
    flex-basis: 55%;
    max-width: 55%;
  }

  .md\:60 {
    flex-basis: 60%;
    max-width: 60%;
  }

  .md\:65 {
    flex-basis: 65%;
    max-width: 65%;
  }

  .md\:70 {
    flex-basis: 70%;
    max-width: 70%;
  }

  .md\:75 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .md\:80 {
    flex-basis: 80%;
    max-width: 80%;
  }

  .md\:85 {
    flex-basis: 85%;
    max-width: 85%;
  }

  .md\:90 {
    flex-basis: 90%;
    max-width: 90%;
  }

  .md\:95 {
    flex-basis: 95%;
    max-width: 95%;
  }

  .md\:100 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .md\:33 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .md\:66 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .md\:half {
    flex-basis: 50%;
    max-width: 50%;
  }

  .md\:full {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1220px) {
  .lg\:5 {
    flex-basis: 5%;
    max-width: 5%;
  }

  .lg\:10 {
    flex-basis: 10%;
    max-width: 10%;
  }

  .lg\:15 {
    flex-basis: 15%;
    max-width: 15%;
  }

  .lg\:20 {
    flex-basis: 20%;
    max-width: 20%;
  }

  .lg\:25 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .lg\:30 {
    flex-basis: 30%;
    max-width: 30%;
  }

  .lg\:35 {
    flex-basis: 35%;
    max-width: 35%;
  }

  .lg\:40 {
    flex-basis: 40%;
    max-width: 40%;
  }

  .lg\:45 {
    flex-basis: 45%;
    max-width: 45%;
  }

  .lg\:50 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .lg\:55 {
    flex-basis: 55%;
    max-width: 55%;
  }

  .lg\:60 {
    flex-basis: 60%;
    max-width: 60%;
  }

  .lg\:65 {
    flex-basis: 65%;
    max-width: 65%;
  }

  .lg\:70 {
    flex-basis: 70%;
    max-width: 70%;
  }

  .lg\:75 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .lg\:80 {
    flex-basis: 80%;
    max-width: 80%;
  }

  .lg\:85 {
    flex-basis: 85%;
    max-width: 85%;
  }

  .lg\:90 {
    flex-basis: 90%;
    max-width: 90%;
  }

  .lg\:95 {
    flex-basis: 95%;
    max-width: 95%;
  }

  .lg\:100 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .lg\:33 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .lg\:66 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .lg\:half {
    flex-basis: 50%;
    max-width: 50%;
  }

  .lg\:full {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1460px) {
  .xl\:5 {
    flex-basis: 5%;
    max-width: 5%;
  }

  .xl\:10 {
    flex-basis: 10%;
    max-width: 10%;
  }

  .xl\:15 {
    flex-basis: 15%;
    max-width: 15%;
  }

  .xl\:20 {
    flex-basis: 20%;
    max-width: 20%;
  }

  .xl\:25 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .xl\:30 {
    flex-basis: 30%;
    max-width: 30%;
  }

  .xl\:35 {
    flex-basis: 35%;
    max-width: 35%;
  }

  .xl\:40 {
    flex-basis: 40%;
    max-width: 40%;
  }

  .xl\:45 {
    flex-basis: 45%;
    max-width: 45%;
  }

  .xl\:50 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .xl\:55 {
    flex-basis: 55%;
    max-width: 55%;
  }

  .xl\:60 {
    flex-basis: 60%;
    max-width: 60%;
  }

  .xl\:65 {
    flex-basis: 65%;
    max-width: 65%;
  }

  .xl\:70 {
    flex-basis: 70%;
    max-width: 70%;
  }

  .xl\:75 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .xl\:80 {
    flex-basis: 80%;
    max-width: 80%;
  }

  .xl\:85 {
    flex-basis: 85%;
    max-width: 85%;
  }

  .xl\:90 {
    flex-basis: 90%;
    max-width: 90%;
  }

  .xl\:95 {
    flex-basis: 95%;
    max-width: 95%;
  }

  .xl\:100 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .xl\:33 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xl\:66 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .xl\:half {
    flex-basis: 50%;
    max-width: 50%;
  }

  .xl\:full {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex  !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.hidden,
.dnone {
  display: none !important;
}

.flex-column {
  flex-direction: column;
}

.flex-column.reverse,
.column-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-row {
  flex-direction: row;
}

.row-reverse,
.reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center,
.y-center,
.vertical-center {
  align-items: center;
}

.flex-start,
.items-start {
  align-items: flex-start;
}

.flex-end,
.items-end {
  align-items: flex-end;
}

.center {
  align-items: center;
  text-align: center;
}

.content-start {
  align-content: flex-start;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center,
.x-center,
.horizontal-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between,
.justify-between {
  justify-content: space-between;
}

.space-around,
.justify-around {
  justify-content: space-around;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-size-xxl {
  font-size: 5rem !important;
}

.font-size-xl {
  font-size: 2rem !important;
}

.font-size-lg {
  font-size: 1.5rem !important;
}

.font-size-md {
  font-size: 1.25rem !important;
}

.font-size-default {
  font-size: 1rem !important;
}

.font-size-sm {
  font-size: 0.75rem !important;
}

.font-size-xs {
  font-size: 0.5rem !important;
}

.w-1\/4,
.w-25 {
  width: 25% !important;
}

.w-1\/3,
.w-33 {
  width: 33.33% !important;
}

.w-1\/2,
.w-2\/4,
.w-half,
.w-50 {
  width: 50% !important;
}

.w-2\/3,
.w-66 {
  width: 66.66% !important;
}

.w-3\/4,
.w-75 {
  width: 75% !important;
}

.w-100,
.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mxw-xs {
  max-width: 600px !important;
}

.mxw-sm {
  max-width: 782px !important;
}

.mxw-md {
  max-width: 1024px !important;
}

.mxw-lg {
  max-width: 1220px !important;
}

.mxw-xl {
  max-width: 1460px !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-50 {
  height: 50% !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-inherit {
  height: inherit !important;
}

.color-primary {
  color: #25373e !important;
}

.bg-primary {
  background-color: #25373e !important;
}

.color-secondary {
  color: #e9a844 !important;
}

.bg-secondary {
  background-color: #e9a844 !important;
}

.color-tertiary {
  color: #3d6574 !important;
}

.bg-tertiary {
  background-color: #3d6574 !important;
}

.color-white {
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.color-light-primary {
  color: #25373eed !important;
}

.bg-light-primary {
  background-color: #25373eed !important;
}

.color-extra-light-primary {
  color: #25383d0f !important;
}

.bg-extra-light-primary {
  background-color: #25383d0f !important;
}

.color-light-secondary {
  color: #e9a8449c !important;
}

.bg-light-secondary {
  background-color: #e9a8449c !important;
}

.p-none {
  padding: 0 !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-xs {
  padding: 0.25rem !important;
}

.px-xs {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-xs {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pr-xs {
  padding-right: 0.25rem !important;
}

.pl-xs {
  padding-left: 0.25rem !important;
}

.pt-xs {
  padding-top: 0.25rem !important;
}

.pb-xs {
  padding-bottom: 0.25rem !important;
}

.p-sm {
  padding: 0.5rem !important;
}

.px-sm {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-sm {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pr-sm {
  padding-right: 0.5rem !important;
}

.pl-sm {
  padding-left: 0.5rem !important;
}

.pt-sm {
  padding-top: 0.5rem !important;
}

.pb-sm {
  padding-bottom: 0.5rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.px-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pr-5 {
  padding-right: 5rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.px-6 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pr-6 {
  padding-right: 6rem !important;
}

.pl-6 {
  padding-left: 6rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.px-7 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pr-7 {
  padding-right: 7rem !important;
}

.pl-7 {
  padding-left: 7rem !important;
}

.pt-7 {
  padding-top: 7rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.px-8 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pr-8 {
  padding-right: 8rem !important;
}

.pl-8 {
  padding-left: 8rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.px-9 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.pr-9 {
  padding-right: 9rem !important;
}

.pl-9 {
  padding-left: 9rem !important;
}

.pt-9 {
  padding-top: 9rem !important;
}

.pb-9 {
  padding-bottom: 9rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.px-10 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pr-10 {
  padding-right: 10rem !important;
}

.pl-10 {
  padding-left: 10rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.p-15 {
  padding: 15rem !important;
}

.px-15 {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.py-15 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.pr-15 {
  padding-right: 15rem !important;
}

.pl-15 {
  padding-left: 15rem !important;
}

.pt-15 {
  padding-top: 15rem !important;
}

.pb-15 {
  padding-bottom: 15rem !important;
}

.p-20 {
  padding: 20rem !important;
}

.px-20 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.py-20 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.pr-20 {
  padding-right: 20rem !important;
}

.pl-20 {
  padding-left: 20rem !important;
}

.pt-20 {
  padding-top: 20rem !important;
}

.pb-20 {
  padding-bottom: 20rem !important;
}

.p-30 {
  padding: 30rem !important;
}

.px-30 {
  padding-left: 30rem !important;
  padding-right: 30rem !important;
}

.py-30 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important;
}

.pr-30 {
  padding-right: 30rem !important;
}

.pl-30 {
  padding-left: 30rem !important;
}

.pt-30 {
  padding-top: 30rem !important;
}

.pb-30 {
  padding-bottom: 30rem !important;
}

.p-40 {
  padding: 40rem !important;
}

.px-40 {
  padding-left: 40rem !important;
  padding-right: 40rem !important;
}

.py-40 {
  padding-top: 40rem !important;
  padding-bottom: 40rem !important;
}

.pr-40 {
  padding-right: 40rem !important;
}

.pl-40 {
  padding-left: 40rem !important;
}

.pt-40 {
  padding-top: 40rem !important;
}

.pb-40 {
  padding-bottom: 40rem !important;
}

.p-50 {
  padding: 50rem !important;
}

.px-50 {
  padding-left: 50rem !important;
  padding-right: 50rem !important;
}

.py-50 {
  padding-top: 50rem !important;
  padding-bottom: 50rem !important;
}

.pr-50 {
  padding-right: 50rem !important;
}

.pl-50 {
  padding-left: 50rem !important;
}

.pt-50 {
  padding-top: 50rem !important;
}

.pb-50 {
  padding-bottom: 50rem !important;
}

.p-60 {
  padding: 60rem !important;
}

.px-60 {
  padding-left: 60rem !important;
  padding-right: 60rem !important;
}

.py-60 {
  padding-top: 60rem !important;
  padding-bottom: 60rem !important;
}

.pr-60 {
  padding-right: 60rem !important;
}

.pl-60 {
  padding-left: 60rem !important;
}

.pt-60 {
  padding-top: 60rem !important;
}

.pb-60 {
  padding-bottom: 60rem !important;
}

.p-70 {
  padding: 70rem !important;
}

.px-70 {
  padding-left: 70rem !important;
  padding-right: 70rem !important;
}

.py-70 {
  padding-top: 70rem !important;
  padding-bottom: 70rem !important;
}

.pr-70 {
  padding-right: 70rem !important;
}

.pl-70 {
  padding-left: 70rem !important;
}

.pt-70 {
  padding-top: 70rem !important;
}

.pb-70 {
  padding-bottom: 70rem !important;
}

.p-80 {
  padding: 80rem !important;
}

.px-80 {
  padding-left: 80rem !important;
  padding-right: 80rem !important;
}

.py-80 {
  padding-top: 80rem !important;
  padding-bottom: 80rem !important;
}

.pr-80 {
  padding-right: 80rem !important;
}

.pl-80 {
  padding-left: 80rem !important;
}

.pt-80 {
  padding-top: 80rem !important;
}

.pb-80 {
  padding-bottom: 80rem !important;
}

.p-90 {
  padding: 90rem !important;
}

.px-90 {
  padding-left: 90rem !important;
  padding-right: 90rem !important;
}

.py-90 {
  padding-top: 90rem !important;
  padding-bottom: 90rem !important;
}

.pr-90 {
  padding-right: 90rem !important;
}

.pl-90 {
  padding-left: 90rem !important;
}

.pt-90 {
  padding-top: 90rem !important;
}

.pb-90 {
  padding-bottom: 90rem !important;
}

.p-100 {
  padding: 100rem !important;
}

.px-100 {
  padding-left: 100rem !important;
  padding-right: 100rem !important;
}

.py-100 {
  padding-top: 100rem !important;
  padding-bottom: 100rem !important;
}

.pr-100 {
  padding-right: 100rem !important;
}

.pl-100 {
  padding-left: 100rem !important;
}

.pt-100 {
  padding-top: 100rem !important;
}

.pb-100 {
  padding-bottom: 100rem !important;
}

.p-10p {
  padding: 10% !important;
}

.px-10p {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.py-10p {
  padding-top: 10% !important;
  padding-bottom: 10% !important;
}

.pr-10p {
  padding-right: 10% !important;
}

.pl-10p {
  padding-left: 10% !important;
}

.pt-10p {
  padding-top: 10% !important;
}

.pb-10p {
  padding-bottom: 10% !important;
}

.p-20p {
  padding: 20% !important;
}

.px-20p {
  padding-left: 20% !important;
  padding-right: 20% !important;
}

.py-20p {
  padding-top: 20% !important;
  padding-bottom: 20% !important;
}

.pr-20p {
  padding-right: 20% !important;
}

.pl-20p {
  padding-left: 20% !important;
}

.pt-20p {
  padding-top: 20% !important;
}

.pb-20p {
  padding-bottom: 20% !important;
}

.p-30p {
  padding: 30% !important;
}

.px-30p {
  padding-left: 30% !important;
  padding-right: 30% !important;
}

.py-30p {
  padding-top: 30% !important;
  padding-bottom: 30% !important;
}

.pr-30p {
  padding-right: 30% !important;
}

.pl-30p {
  padding-left: 30% !important;
}

.pt-30p {
  padding-top: 30% !important;
}

.pb-30p {
  padding-bottom: 30% !important;
}

.p-40p {
  padding: 40% !important;
}

.px-40p {
  padding-left: 40% !important;
  padding-right: 40% !important;
}

.py-40p {
  padding-top: 40% !important;
  padding-bottom: 40% !important;
}

.pr-40p {
  padding-right: 40% !important;
}

.pl-40p {
  padding-left: 40% !important;
}

.pt-40p {
  padding-top: 40% !important;
}

.pb-40p {
  padding-bottom: 40% !important;
}

.p-50p {
  padding: 50% !important;
}

.px-50p {
  padding-left: 50% !important;
  padding-right: 50% !important;
}

.py-50p {
  padding-top: 50% !important;
  padding-bottom: 50% !important;
}

.pr-50p {
  padding-right: 50% !important;
}

.pl-50p {
  padding-left: 50% !important;
}

.pt-50p {
  padding-top: 50% !important;
}

.pb-50p {
  padding-bottom: 50% !important;
}

.p-60p {
  padding: 60% !important;
}

.px-60p {
  padding-left: 60% !important;
  padding-right: 60% !important;
}

.py-60p {
  padding-top: 60% !important;
  padding-bottom: 60% !important;
}

.pr-60p {
  padding-right: 60% !important;
}

.pl-60p {
  padding-left: 60% !important;
}

.pt-60p {
  padding-top: 60% !important;
}

.pb-60p {
  padding-bottom: 60% !important;
}

.p-70p {
  padding: 70% !important;
}

.px-70p {
  padding-left: 70% !important;
  padding-right: 70% !important;
}

.py-70p {
  padding-top: 70% !important;
  padding-bottom: 70% !important;
}

.pr-70p {
  padding-right: 70% !important;
}

.pl-70p {
  padding-left: 70% !important;
}

.pt-70p {
  padding-top: 70% !important;
}

.pb-70p {
  padding-bottom: 70% !important;
}

.p-80p {
  padding: 80% !important;
}

.px-80p {
  padding-left: 80% !important;
  padding-right: 80% !important;
}

.py-80p {
  padding-top: 80% !important;
  padding-bottom: 80% !important;
}

.pr-80p {
  padding-right: 80% !important;
}

.pl-80p {
  padding-left: 80% !important;
}

.pt-80p {
  padding-top: 80% !important;
}

.pb-80p {
  padding-bottom: 80% !important;
}

.p-90p {
  padding: 90% !important;
}

.px-90p {
  padding-left: 90% !important;
  padding-right: 90% !important;
}

.py-90p {
  padding-top: 90% !important;
  padding-bottom: 90% !important;
}

.pr-90p {
  padding-right: 90% !important;
}

.pl-90p {
  padding-left: 90% !important;
}

.pt-90p {
  padding-top: 90% !important;
}

.pb-90p {
  padding-bottom: 90% !important;
}

.p-100p {
  padding: 100% !important;
}

.px-100p {
  padding-left: 100% !important;
  padding-right: 100% !important;
}

.py-100p {
  padding-top: 100% !important;
  padding-bottom: 100% !important;
}

.pr-100p {
  padding-right: 100% !important;
}

.pl-100p {
  padding-left: 100% !important;
}

.pt-100p {
  padding-top: 100% !important;
}

.pb-100p {
  padding-bottom: 100% !important;
}

.m-none {
  margin: 0 !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.m-xs {
  margin: 0.25rem !important;
}

.my-xs {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-xs {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mt-xs {
  margin-top: 0.25rem !important;
}

.mb-xs {
  margin-bottom: 0.25rem !important;
}

.mr-xs {
  margin-right: 0.25rem !important;
}

.ml-xs {
  margin-left: 0.25rem !important;
}

.-m-xs {
  margin: -0.25rem !important;
}

.-mx-xs {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mt-xs {
  margin-top: -0.25rem !important;
}

.-mb-xs {
  margin-bottom: -0.25rem !important;
}

.-ml-xs {
  margin-left: -0.25rem !important;
}

.-mr-xs {
  margin-right: -0.25rem !important;
}

.m-sm {
  margin: 0.5rem !important;
}

.my-sm {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-sm {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mt-sm {
  margin-top: 0.5rem !important;
}

.mb-sm {
  margin-bottom: 0.5rem !important;
}

.mr-sm {
  margin-right: 0.5rem !important;
}

.ml-sm {
  margin-left: 0.5rem !important;
}

.-m-sm {
  margin: -0.5rem !important;
}

.-mx-sm {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mt-sm {
  margin-top: -0.5rem !important;
}

.-mb-sm {
  margin-bottom: -0.5rem !important;
}

.-ml-sm {
  margin-left: -0.5rem !important;
}

.-mr-sm {
  margin-right: -0.5rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.-m-1 {
  margin: -1rem !important;
}

.-mx-1 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mt-1 {
  margin-top: -1rem !important;
}

.-mb-1 {
  margin-bottom: -1rem !important;
}

.-ml-1 {
  margin-left: -1rem !important;
}

.-mr-1 {
  margin-right: -1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.-m-2 {
  margin: -2rem !important;
}

.-mx-2 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mt-2 {
  margin-top: -2rem !important;
}

.-mb-2 {
  margin-bottom: -2rem !important;
}

.-ml-2 {
  margin-left: -2rem !important;
}

.-mr-2 {
  margin-right: -2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.-m-3 {
  margin: -3rem !important;
}

.-mx-3 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mt-3 {
  margin-top: -3rem !important;
}

.-mb-3 {
  margin-bottom: -3rem !important;
}

.-ml-3 {
  margin-left: -3rem !important;
}

.-mr-3 {
  margin-right: -3rem !important;
}

.m-4 {
  margin: 4rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.-m-4 {
  margin: -4rem !important;
}

.-mx-4 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mt-4 {
  margin-top: -4rem !important;
}

.-mb-4 {
  margin-bottom: -4rem !important;
}

.-ml-4 {
  margin-left: -4rem !important;
}

.-mr-4 {
  margin-right: -4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.my-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-5 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.-m-5 {
  margin: -5rem !important;
}

.-mx-5 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-mt-5 {
  margin-top: -5rem !important;
}

.-mb-5 {
  margin-bottom: -5rem !important;
}

.-ml-5 {
  margin-left: -5rem !important;
}

.-mr-5 {
  margin-right: -5rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx-6 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mr-6 {
  margin-right: 6rem !important;
}

.ml-6 {
  margin-left: 6rem !important;
}

.-m-6 {
  margin: -6rem !important;
}

.-mx-6 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-mt-6 {
  margin-top: -6rem !important;
}

.-mb-6 {
  margin-bottom: -6rem !important;
}

.-ml-6 {
  margin-left: -6rem !important;
}

.-mr-6 {
  margin-right: -6rem !important;
}

.m-7 {
  margin: 7rem !important;
}

.my-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.mx-7 {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mr-7 {
  margin-right: 7rem !important;
}

.ml-7 {
  margin-left: 7rem !important;
}

.-m-7 {
  margin: -7rem !important;
}

.-mx-7 {
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.-mt-7 {
  margin-top: -7rem !important;
}

.-mb-7 {
  margin-bottom: -7rem !important;
}

.-ml-7 {
  margin-left: -7rem !important;
}

.-mr-7 {
  margin-right: -7rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-8 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mr-8 {
  margin-right: 8rem !important;
}

.ml-8 {
  margin-left: 8rem !important;
}

.-m-8 {
  margin: -8rem !important;
}

.-mx-8 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-mt-8 {
  margin-top: -8rem !important;
}

.-mb-8 {
  margin-bottom: -8rem !important;
}

.-ml-8 {
  margin-left: -8rem !important;
}

.-mr-8 {
  margin-right: -8rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.mx-9 {
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.mr-9 {
  margin-right: 9rem !important;
}

.ml-9 {
  margin-left: 9rem !important;
}

.-m-9 {
  margin: -9rem !important;
}

.-mx-9 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-mt-9 {
  margin-top: -9rem !important;
}

.-mb-9 {
  margin-bottom: -9rem !important;
}

.-ml-9 {
  margin-left: -9rem !important;
}

.-mr-9 {
  margin-right: -9rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mx-10 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mr-10 {
  margin-right: 10rem !important;
}

.ml-10 {
  margin-left: 10rem !important;
}

.-m-10 {
  margin: -10rem !important;
}

.-mx-10 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-mt-10 {
  margin-top: -10rem !important;
}

.-mb-10 {
  margin-bottom: -10rem !important;
}

.-ml-10 {
  margin-left: -10rem !important;
}

.-mr-10 {
  margin-right: -10rem !important;
}

.m-15 {
  margin: 15rem !important;
}

.my-15 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.mx-15 {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.mt-15 {
  margin-top: 15rem !important;
}

.mb-15 {
  margin-bottom: 15rem !important;
}

.mr-15 {
  margin-right: 15rem !important;
}

.ml-15 {
  margin-left: 15rem !important;
}

.-m-15 {
  margin: -15rem !important;
}

.-mx-15 {
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.-mt-15 {
  margin-top: -15rem !important;
}

.-mb-15 {
  margin-bottom: -15rem !important;
}

.-ml-15 {
  margin-left: -15rem !important;
}

.-mr-15 {
  margin-right: -15rem !important;
}

.m-20 {
  margin: 20rem !important;
}

.my-20 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.mx-20 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.mt-20 {
  margin-top: 20rem !important;
}

.mb-20 {
  margin-bottom: 20rem !important;
}

.mr-20 {
  margin-right: 20rem !important;
}

.ml-20 {
  margin-left: 20rem !important;
}

.-m-20 {
  margin: -20rem !important;
}

.-mx-20 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.-mt-20 {
  margin-top: -20rem !important;
}

.-mb-20 {
  margin-bottom: -20rem !important;
}

.-ml-20 {
  margin-left: -20rem !important;
}

.-mr-20 {
  margin-right: -20rem !important;
}

.m-30 {
  margin: 30rem !important;
}

.my-30 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important;
}

.mx-30 {
  margin-left: 30rem !important;
  margin-right: 30rem !important;
}

.mt-30 {
  margin-top: 30rem !important;
}

.mb-30 {
  margin-bottom: 30rem !important;
}

.mr-30 {
  margin-right: 30rem !important;
}

.ml-30 {
  margin-left: 30rem !important;
}

.-m-30 {
  margin: -30rem !important;
}

.-mx-30 {
  margin-left: -30rem !important;
  margin-right: -30rem !important;
}

.-mt-30 {
  margin-top: -30rem !important;
}

.-mb-30 {
  margin-bottom: -30rem !important;
}

.-ml-30 {
  margin-left: -30rem !important;
}

.-mr-30 {
  margin-right: -30rem !important;
}

.m-40 {
  margin: 40rem !important;
}

.my-40 {
  margin-top: 40rem !important;
  margin-bottom: 40rem !important;
}

.mx-40 {
  margin-left: 40rem !important;
  margin-right: 40rem !important;
}

.mt-40 {
  margin-top: 40rem !important;
}

.mb-40 {
  margin-bottom: 40rem !important;
}

.mr-40 {
  margin-right: 40rem !important;
}

.ml-40 {
  margin-left: 40rem !important;
}

.-m-40 {
  margin: -40rem !important;
}

.-mx-40 {
  margin-left: -40rem !important;
  margin-right: -40rem !important;
}

.-mt-40 {
  margin-top: -40rem !important;
}

.-mb-40 {
  margin-bottom: -40rem !important;
}

.-ml-40 {
  margin-left: -40rem !important;
}

.-mr-40 {
  margin-right: -40rem !important;
}

.m-50 {
  margin: 50rem !important;
}

.my-50 {
  margin-top: 50rem !important;
  margin-bottom: 50rem !important;
}

.mx-50 {
  margin-left: 50rem !important;
  margin-right: 50rem !important;
}

.mt-50 {
  margin-top: 50rem !important;
}

.mb-50 {
  margin-bottom: 50rem !important;
}

.mr-50 {
  margin-right: 50rem !important;
}

.ml-50 {
  margin-left: 50rem !important;
}

.-m-50 {
  margin: -50rem !important;
}

.-mx-50 {
  margin-left: -50rem !important;
  margin-right: -50rem !important;
}

.-mt-50 {
  margin-top: -50rem !important;
}

.-mb-50 {
  margin-bottom: -50rem !important;
}

.-ml-50 {
  margin-left: -50rem !important;
}

.-mr-50 {
  margin-right: -50rem !important;
}

.m-60 {
  margin: 60rem !important;
}

.my-60 {
  margin-top: 60rem !important;
  margin-bottom: 60rem !important;
}

.mx-60 {
  margin-left: 60rem !important;
  margin-right: 60rem !important;
}

.mt-60 {
  margin-top: 60rem !important;
}

.mb-60 {
  margin-bottom: 60rem !important;
}

.mr-60 {
  margin-right: 60rem !important;
}

.ml-60 {
  margin-left: 60rem !important;
}

.-m-60 {
  margin: -60rem !important;
}

.-mx-60 {
  margin-left: -60rem !important;
  margin-right: -60rem !important;
}

.-mt-60 {
  margin-top: -60rem !important;
}

.-mb-60 {
  margin-bottom: -60rem !important;
}

.-ml-60 {
  margin-left: -60rem !important;
}

.-mr-60 {
  margin-right: -60rem !important;
}

.m-70 {
  margin: 70rem !important;
}

.my-70 {
  margin-top: 70rem !important;
  margin-bottom: 70rem !important;
}

.mx-70 {
  margin-left: 70rem !important;
  margin-right: 70rem !important;
}

.mt-70 {
  margin-top: 70rem !important;
}

.mb-70 {
  margin-bottom: 70rem !important;
}

.mr-70 {
  margin-right: 70rem !important;
}

.ml-70 {
  margin-left: 70rem !important;
}

.-m-70 {
  margin: -70rem !important;
}

.-mx-70 {
  margin-left: -70rem !important;
  margin-right: -70rem !important;
}

.-mt-70 {
  margin-top: -70rem !important;
}

.-mb-70 {
  margin-bottom: -70rem !important;
}

.-ml-70 {
  margin-left: -70rem !important;
}

.-mr-70 {
  margin-right: -70rem !important;
}

.m-80 {
  margin: 80rem !important;
}

.my-80 {
  margin-top: 80rem !important;
  margin-bottom: 80rem !important;
}

.mx-80 {
  margin-left: 80rem !important;
  margin-right: 80rem !important;
}

.mt-80 {
  margin-top: 80rem !important;
}

.mb-80 {
  margin-bottom: 80rem !important;
}

.mr-80 {
  margin-right: 80rem !important;
}

.ml-80 {
  margin-left: 80rem !important;
}

.-m-80 {
  margin: -80rem !important;
}

.-mx-80 {
  margin-left: -80rem !important;
  margin-right: -80rem !important;
}

.-mt-80 {
  margin-top: -80rem !important;
}

.-mb-80 {
  margin-bottom: -80rem !important;
}

.-ml-80 {
  margin-left: -80rem !important;
}

.-mr-80 {
  margin-right: -80rem !important;
}

.m-90 {
  margin: 90rem !important;
}

.my-90 {
  margin-top: 90rem !important;
  margin-bottom: 90rem !important;
}

.mx-90 {
  margin-left: 90rem !important;
  margin-right: 90rem !important;
}

.mt-90 {
  margin-top: 90rem !important;
}

.mb-90 {
  margin-bottom: 90rem !important;
}

.mr-90 {
  margin-right: 90rem !important;
}

.ml-90 {
  margin-left: 90rem !important;
}

.-m-90 {
  margin: -90rem !important;
}

.-mx-90 {
  margin-left: -90rem !important;
  margin-right: -90rem !important;
}

.-mt-90 {
  margin-top: -90rem !important;
}

.-mb-90 {
  margin-bottom: -90rem !important;
}

.-ml-90 {
  margin-left: -90rem !important;
}

.-mr-90 {
  margin-right: -90rem !important;
}

.m-100 {
  margin: 100rem !important;
}

.my-100 {
  margin-top: 100rem !important;
  margin-bottom: 100rem !important;
}

.mx-100 {
  margin-left: 100rem !important;
  margin-right: 100rem !important;
}

.mt-100 {
  margin-top: 100rem !important;
}

.mb-100 {
  margin-bottom: 100rem !important;
}

.mr-100 {
  margin-right: 100rem !important;
}

.ml-100 {
  margin-left: 100rem !important;
}

.-m-100 {
  margin: -100rem !important;
}

.-mx-100 {
  margin-left: -100rem !important;
  margin-right: -100rem !important;
}

.-mt-100 {
  margin-top: -100rem !important;
}

.-mb-100 {
  margin-bottom: -100rem !important;
}

.-ml-100 {
  margin-left: -100rem !important;
}

.-mr-100 {
  margin-right: -100rem !important;
}

.m-10p {
  margin: 10% !important;
}

.my-10p {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
}

.mx-10p {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.mt-10p {
  margin-top: 10% !important;
}

.mb-10p {
  margin-bottom: 10% !important;
}

.mr-10p {
  margin-right: 10% !important;
}

.ml-10p {
  margin-left: 10% !important;
}

.-m-10p {
  margin: -10% !important;
}

.-mx-10p {
  margin-left: -10% !important;
  margin-right: -10% !important;
}

.-mt-10p {
  margin-top: -10% !important;
}

.-mb-10p {
  margin-bottom: -10% !important;
}

.-ml-10p {
  margin-left: -10% !important;
}

.-mr-10p {
  margin-right: -10% !important;
}

.m-20p {
  margin: 20% !important;
}

.my-20p {
  margin-top: 20% !important;
  margin-bottom: 20% !important;
}

.mx-20p {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

.mt-20p {
  margin-top: 20% !important;
}

.mb-20p {
  margin-bottom: 20% !important;
}

.mr-20p {
  margin-right: 20% !important;
}

.ml-20p {
  margin-left: 20% !important;
}

.-m-20p {
  margin: -20% !important;
}

.-mx-20p {
  margin-left: -20% !important;
  margin-right: -20% !important;
}

.-mt-20p {
  margin-top: -20% !important;
}

.-mb-20p {
  margin-bottom: -20% !important;
}

.-ml-20p {
  margin-left: -20% !important;
}

.-mr-20p {
  margin-right: -20% !important;
}

.m-30p {
  margin: 30% !important;
}

.my-30p {
  margin-top: 30% !important;
  margin-bottom: 30% !important;
}

.mx-30p {
  margin-left: 30% !important;
  margin-right: 30% !important;
}

.mt-30p {
  margin-top: 30% !important;
}

.mb-30p {
  margin-bottom: 30% !important;
}

.mr-30p {
  margin-right: 30% !important;
}

.ml-30p {
  margin-left: 30% !important;
}

.-m-30p {
  margin: -30% !important;
}

.-mx-30p {
  margin-left: -30% !important;
  margin-right: -30% !important;
}

.-mt-30p {
  margin-top: -30% !important;
}

.-mb-30p {
  margin-bottom: -30% !important;
}

.-ml-30p {
  margin-left: -30% !important;
}

.-mr-30p {
  margin-right: -30% !important;
}

.m-40p {
  margin: 40% !important;
}

.my-40p {
  margin-top: 40% !important;
  margin-bottom: 40% !important;
}

.mx-40p {
  margin-left: 40% !important;
  margin-right: 40% !important;
}

.mt-40p {
  margin-top: 40% !important;
}

.mb-40p {
  margin-bottom: 40% !important;
}

.mr-40p {
  margin-right: 40% !important;
}

.ml-40p {
  margin-left: 40% !important;
}

.-m-40p {
  margin: -40% !important;
}

.-mx-40p {
  margin-left: -40% !important;
  margin-right: -40% !important;
}

.-mt-40p {
  margin-top: -40% !important;
}

.-mb-40p {
  margin-bottom: -40% !important;
}

.-ml-40p {
  margin-left: -40% !important;
}

.-mr-40p {
  margin-right: -40% !important;
}

.m-50p {
  margin: 50% !important;
}

.my-50p {
  margin-top: 50% !important;
  margin-bottom: 50% !important;
}

.mx-50p {
  margin-left: 50% !important;
  margin-right: 50% !important;
}

.mt-50p {
  margin-top: 50% !important;
}

.mb-50p {
  margin-bottom: 50% !important;
}

.mr-50p {
  margin-right: 50% !important;
}

.ml-50p {
  margin-left: 50% !important;
}

.-m-50p {
  margin: -50% !important;
}

.-mx-50p {
  margin-left: -50% !important;
  margin-right: -50% !important;
}

.-mt-50p {
  margin-top: -50% !important;
}

.-mb-50p {
  margin-bottom: -50% !important;
}

.-ml-50p {
  margin-left: -50% !important;
}

.-mr-50p {
  margin-right: -50% !important;
}

.m-60p {
  margin: 60% !important;
}

.my-60p {
  margin-top: 60% !important;
  margin-bottom: 60% !important;
}

.mx-60p {
  margin-left: 60% !important;
  margin-right: 60% !important;
}

.mt-60p {
  margin-top: 60% !important;
}

.mb-60p {
  margin-bottom: 60% !important;
}

.mr-60p {
  margin-right: 60% !important;
}

.ml-60p {
  margin-left: 60% !important;
}

.-m-60p {
  margin: -60% !important;
}

.-mx-60p {
  margin-left: -60% !important;
  margin-right: -60% !important;
}

.-mt-60p {
  margin-top: -60% !important;
}

.-mb-60p {
  margin-bottom: -60% !important;
}

.-ml-60p {
  margin-left: -60% !important;
}

.-mr-60p {
  margin-right: -60% !important;
}

.m-70p {
  margin: 70% !important;
}

.my-70p {
  margin-top: 70% !important;
  margin-bottom: 70% !important;
}

.mx-70p {
  margin-left: 70% !important;
  margin-right: 70% !important;
}

.mt-70p {
  margin-top: 70% !important;
}

.mb-70p {
  margin-bottom: 70% !important;
}

.mr-70p {
  margin-right: 70% !important;
}

.ml-70p {
  margin-left: 70% !important;
}

.-m-70p {
  margin: -70% !important;
}

.-mx-70p {
  margin-left: -70% !important;
  margin-right: -70% !important;
}

.-mt-70p {
  margin-top: -70% !important;
}

.-mb-70p {
  margin-bottom: -70% !important;
}

.-ml-70p {
  margin-left: -70% !important;
}

.-mr-70p {
  margin-right: -70% !important;
}

.m-80p {
  margin: 80% !important;
}

.my-80p {
  margin-top: 80% !important;
  margin-bottom: 80% !important;
}

.mx-80p {
  margin-left: 80% !important;
  margin-right: 80% !important;
}

.mt-80p {
  margin-top: 80% !important;
}

.mb-80p {
  margin-bottom: 80% !important;
}

.mr-80p {
  margin-right: 80% !important;
}

.ml-80p {
  margin-left: 80% !important;
}

.-m-80p {
  margin: -80% !important;
}

.-mx-80p {
  margin-left: -80% !important;
  margin-right: -80% !important;
}

.-mt-80p {
  margin-top: -80% !important;
}

.-mb-80p {
  margin-bottom: -80% !important;
}

.-ml-80p {
  margin-left: -80% !important;
}

.-mr-80p {
  margin-right: -80% !important;
}

.m-90p {
  margin: 90% !important;
}

.my-90p {
  margin-top: 90% !important;
  margin-bottom: 90% !important;
}

.mx-90p {
  margin-left: 90% !important;
  margin-right: 90% !important;
}

.mt-90p {
  margin-top: 90% !important;
}

.mb-90p {
  margin-bottom: 90% !important;
}

.mr-90p {
  margin-right: 90% !important;
}

.ml-90p {
  margin-left: 90% !important;
}

.-m-90p {
  margin: -90% !important;
}

.-mx-90p {
  margin-left: -90% !important;
  margin-right: -90% !important;
}

.-mt-90p {
  margin-top: -90% !important;
}

.-mb-90p {
  margin-bottom: -90% !important;
}

.-ml-90p {
  margin-left: -90% !important;
}

.-mr-90p {
  margin-right: -90% !important;
}

.m-100p {
  margin: 100% !important;
}

.my-100p {
  margin-top: 100% !important;
  margin-bottom: 100% !important;
}

.mx-100p {
  margin-left: 100% !important;
  margin-right: 100% !important;
}

.mt-100p {
  margin-top: 100% !important;
}

.mb-100p {
  margin-bottom: 100% !important;
}

.mr-100p {
  margin-right: 100% !important;
}

.ml-100p {
  margin-left: 100% !important;
}

.-m-100p {
  margin: -100% !important;
}

.-mx-100p {
  margin-left: -100% !important;
  margin-right: -100% !important;
}

.-mt-100p {
  margin-top: -100% !important;
}

.-mb-100p {
  margin-bottom: -100% !important;
}

.-ml-100p {
  margin-left: -100% !important;
}

.-mr-100p {
  margin-right: -100% !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.trbl-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-100 {
  z-index: 100 !important;
}

.-z-1 {
  z-index: -1 !important;
}

.border-0 {
  border: 0 !important;
}

.round {
  border-radius: 100vw !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-xxl {
  border-radius: 2rem !important;
}

.rounded-xl {
  border-radius: 1rem !important;
}

.rounded-lg {
  border-radius: 0.75rem !important;
}

.rounded-md {
  border-radius: 0.5rem !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded-xs {
  border-radius: 0.125rem !important;
}

@media (min-width: 600px) {
  .xs\:flex {
    display: flex !important;
  }

  .xs\:inline-flex {
    display: inline-flex  !important;
  }

  .xs\:block {
    display: block !important;
  }

  .xs\:inline-block {
    display: inline-block !important;
  }

  .xs\:hidden,
.xs\:dnone {
    display: none !important;
  }

  .xs\:flex-column {
    flex-direction: column;
  }

  .xs\:flex-column.reverse,
.xs\:column-reverse {
    flex-direction: column-reverse;
  }

  .xs\:flex-1 {
    flex: 1 1 0%;
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:row-reverse,
.xs\:reverse {
    flex-direction: row-reverse;
  }

  .xs\:flex-wrap {
    flex-wrap: wrap;
  }

  .xs\:items-center,
.xs\:y-center,
.xs\:vertical-center {
    align-items: center;
  }

  .xs\:flex-start,
.xs\:items-start {
    align-items: flex-start;
  }

  .xs\:flex-end,
.xs\:items-end {
    align-items: flex-end;
  }

  .xs\:center {
    align-items: center;
    text-align: center;
  }

  .xs\:content-start {
    align-content: flex-start;
  }

  .xs\:content-center {
    align-content: center;
  }

  .xs\:content-end {
    align-content: flex-end;
  }

  .xs\:content-between {
    align-content: space-between;
  }

  .xs\:content-around {
    align-content: space-around;
  }

  .xs\:self-auto {
    align-self: auto;
  }

  .xs\:self-start {
    align-self: flex-start;
  }

  .xs\:self-center {
    align-self: center;
  }

  .xs\:self-end {
    align-self: flex-end;
  }

  .xs\:justify-start {
    justify-content: flex-start;
  }

  .xs\:justify-center,
.xs\:x-center,
.xs\:horizontal-center {
    justify-content: center;
  }

  .xs\:justify-end {
    justify-content: flex-end;
  }

  .xs\:space-between,
.xs\:justify-between {
    justify-content: space-between;
  }

  .xs\:space-around,
.xs\:justify-around {
    justify-content: space-around;
  }

  .xs\:text-center {
    text-align: center !important;
  }

  .xs\:text-left {
    text-align: left !important;
  }

  .xs\:text-right {
    text-align: right !important;
  }

  .xs\:text-uppercase {
    text-transform: uppercase !important;
  }

  .xs\:font-bold {
    font-weight: 700 !important;
  }

  .xs\:font-size-xxl {
    font-size: 5rem !important;
  }

  .xs\:font-size-xl {
    font-size: 2rem !important;
  }

  .xs\:font-size-lg {
    font-size: 1.5rem !important;
  }

  .xs\:font-size-md {
    font-size: 1.25rem !important;
  }

  .xs\:font-size-default {
    font-size: 1rem !important;
  }

  .xs\:font-size-sm {
    font-size: 0.75rem !important;
  }

  .xs\:font-size-xs {
    font-size: 0.5rem !important;
  }

  .xs\:w-1\/4,
.xs\:w-25 {
    width: 25% !important;
  }

  .xs\:w-1\/3,
.xs\:w-33 {
    width: 33.33% !important;
  }

  .xs\:w-1\/2,
.xs\:w-2\/4,
.xs\:w-half,
.xs\:w-50 {
    width: 50% !important;
  }

  .xs\:w-2\/3,
.xs\:w-66 {
    width: 66.66% !important;
  }

  .xs\:w-3\/4,
.xs\:w-75 {
    width: 75% !important;
  }

  .xs\:w-100,
.xs\:w-full {
    width: 100% !important;
  }

  .xs\:w-auto {
    width: auto !important;
  }

  .xs\:mxw-xs {
    max-width: 600px !important;
  }

  .xs\:mxw-sm {
    max-width: 782px !important;
  }

  .xs\:mxw-md {
    max-width: 1024px !important;
  }

  .xs\:mxw-lg {
    max-width: 1220px !important;
  }

  .xs\:mxw-xl {
    max-width: 1460px !important;
  }

  .xs\:h-100 {
    height: 100% !important;
  }

  .xs\:h-100vh {
    height: 100vh !important;
  }

  .xs\:h-50 {
    height: 50% !important;
  }

  .xs\:h-50vh {
    height: 50vh !important;
  }

  .xs\:h-inherit {
    height: inherit !important;
  }

  .xs\:color-primary {
    color: #25373e !important;
  }

  .xs\:bg-primary {
    background-color: #25373e !important;
  }

  .xs\:color-secondary {
    color: #e9a844 !important;
  }

  .xs\:bg-secondary {
    background-color: #e9a844 !important;
  }

  .xs\:color-tertiary {
    color: #3d6574 !important;
  }

  .xs\:bg-tertiary {
    background-color: #3d6574 !important;
  }

  .xs\:color-white {
    color: #fff !important;
  }

  .xs\:bg-white {
    background-color: #fff !important;
  }

  .xs\:color-light-primary {
    color: #25373eed !important;
  }

  .xs\:bg-light-primary {
    background-color: #25373eed !important;
  }

  .xs\:color-extra-light-primary {
    color: #25383d0f !important;
  }

  .xs\:bg-extra-light-primary {
    background-color: #25383d0f !important;
  }

  .xs\:color-light-secondary {
    color: #e9a8449c !important;
  }

  .xs\:bg-light-secondary {
    background-color: #e9a8449c !important;
  }

  .xs\:p-none {
    padding: 0 !important;
  }

  .xs\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .xs\:p-0 {
    padding: 0 !important;
  }

  .xs\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xs\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xs\:pr-0 {
    padding-right: 0 !important;
  }

  .xs\:pl-0 {
    padding-left: 0 !important;
  }

  .xs\:pt-0 {
    padding-top: 0 !important;
  }

  .xs\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xs\:p-xs {
    padding: 0.25rem !important;
  }

  .xs\:px-xs {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xs\:py-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xs\:pr-xs {
    padding-right: 0.25rem !important;
  }

  .xs\:pl-xs {
    padding-left: 0.25rem !important;
  }

  .xs\:pt-xs {
    padding-top: 0.25rem !important;
  }

  .xs\:pb-xs {
    padding-bottom: 0.25rem !important;
  }

  .xs\:p-sm {
    padding: 0.5rem !important;
  }

  .xs\:px-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xs\:py-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xs\:pr-sm {
    padding-right: 0.5rem !important;
  }

  .xs\:pl-sm {
    padding-left: 0.5rem !important;
  }

  .xs\:pt-sm {
    padding-top: 0.5rem !important;
  }

  .xs\:pb-sm {
    padding-bottom: 0.5rem !important;
  }

  .xs\:p-1 {
    padding: 1rem !important;
  }

  .xs\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xs\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xs\:pr-1 {
    padding-right: 1rem !important;
  }

  .xs\:pl-1 {
    padding-left: 1rem !important;
  }

  .xs\:pt-1 {
    padding-top: 1rem !important;
  }

  .xs\:pb-1 {
    padding-bottom: 1rem !important;
  }

  .xs\:p-2 {
    padding: 2rem !important;
  }

  .xs\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xs\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xs\:pr-2 {
    padding-right: 2rem !important;
  }

  .xs\:pl-2 {
    padding-left: 2rem !important;
  }

  .xs\:pt-2 {
    padding-top: 2rem !important;
  }

  .xs\:pb-2 {
    padding-bottom: 2rem !important;
  }

  .xs\:p-3 {
    padding: 3rem !important;
  }

  .xs\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xs\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xs\:pr-3 {
    padding-right: 3rem !important;
  }

  .xs\:pl-3 {
    padding-left: 3rem !important;
  }

  .xs\:pt-3 {
    padding-top: 3rem !important;
  }

  .xs\:pb-3 {
    padding-bottom: 3rem !important;
  }

  .xs\:p-4 {
    padding: 4rem !important;
  }

  .xs\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xs\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xs\:pr-4 {
    padding-right: 4rem !important;
  }

  .xs\:pl-4 {
    padding-left: 4rem !important;
  }

  .xs\:pt-4 {
    padding-top: 4rem !important;
  }

  .xs\:pb-4 {
    padding-bottom: 4rem !important;
  }

  .xs\:p-5 {
    padding: 5rem !important;
  }

  .xs\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xs\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xs\:pr-5 {
    padding-right: 5rem !important;
  }

  .xs\:pl-5 {
    padding-left: 5rem !important;
  }

  .xs\:pt-5 {
    padding-top: 5rem !important;
  }

  .xs\:pb-5 {
    padding-bottom: 5rem !important;
  }

  .xs\:p-6 {
    padding: 6rem !important;
  }

  .xs\:px-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xs\:py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xs\:pr-6 {
    padding-right: 6rem !important;
  }

  .xs\:pl-6 {
    padding-left: 6rem !important;
  }

  .xs\:pt-6 {
    padding-top: 6rem !important;
  }

  .xs\:pb-6 {
    padding-bottom: 6rem !important;
  }

  .xs\:p-7 {
    padding: 7rem !important;
  }

  .xs\:px-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xs\:py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xs\:pr-7 {
    padding-right: 7rem !important;
  }

  .xs\:pl-7 {
    padding-left: 7rem !important;
  }

  .xs\:pt-7 {
    padding-top: 7rem !important;
  }

  .xs\:pb-7 {
    padding-bottom: 7rem !important;
  }

  .xs\:p-8 {
    padding: 8rem !important;
  }

  .xs\:px-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xs\:py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xs\:pr-8 {
    padding-right: 8rem !important;
  }

  .xs\:pl-8 {
    padding-left: 8rem !important;
  }

  .xs\:pt-8 {
    padding-top: 8rem !important;
  }

  .xs\:pb-8 {
    padding-bottom: 8rem !important;
  }

  .xs\:p-9 {
    padding: 9rem !important;
  }

  .xs\:px-9 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xs\:py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xs\:pr-9 {
    padding-right: 9rem !important;
  }

  .xs\:pl-9 {
    padding-left: 9rem !important;
  }

  .xs\:pt-9 {
    padding-top: 9rem !important;
  }

  .xs\:pb-9 {
    padding-bottom: 9rem !important;
  }

  .xs\:p-10 {
    padding: 10rem !important;
  }

  .xs\:px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xs\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xs\:pr-10 {
    padding-right: 10rem !important;
  }

  .xs\:pl-10 {
    padding-left: 10rem !important;
  }

  .xs\:pt-10 {
    padding-top: 10rem !important;
  }

  .xs\:pb-10 {
    padding-bottom: 10rem !important;
  }

  .xs\:p-15 {
    padding: 15rem !important;
  }

  .xs\:px-15 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .xs\:py-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .xs\:pr-15 {
    padding-right: 15rem !important;
  }

  .xs\:pl-15 {
    padding-left: 15rem !important;
  }

  .xs\:pt-15 {
    padding-top: 15rem !important;
  }

  .xs\:pb-15 {
    padding-bottom: 15rem !important;
  }

  .xs\:p-20 {
    padding: 20rem !important;
  }

  .xs\:px-20 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .xs\:py-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .xs\:pr-20 {
    padding-right: 20rem !important;
  }

  .xs\:pl-20 {
    padding-left: 20rem !important;
  }

  .xs\:pt-20 {
    padding-top: 20rem !important;
  }

  .xs\:pb-20 {
    padding-bottom: 20rem !important;
  }

  .xs\:p-30 {
    padding: 30rem !important;
  }

  .xs\:px-30 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
  }

  .xs\:py-30 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .xs\:pr-30 {
    padding-right: 30rem !important;
  }

  .xs\:pl-30 {
    padding-left: 30rem !important;
  }

  .xs\:pt-30 {
    padding-top: 30rem !important;
  }

  .xs\:pb-30 {
    padding-bottom: 30rem !important;
  }

  .xs\:p-40 {
    padding: 40rem !important;
  }

  .xs\:px-40 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
  }

  .xs\:py-40 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }

  .xs\:pr-40 {
    padding-right: 40rem !important;
  }

  .xs\:pl-40 {
    padding-left: 40rem !important;
  }

  .xs\:pt-40 {
    padding-top: 40rem !important;
  }

  .xs\:pb-40 {
    padding-bottom: 40rem !important;
  }

  .xs\:p-50 {
    padding: 50rem !important;
  }

  .xs\:px-50 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
  }

  .xs\:py-50 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
  }

  .xs\:pr-50 {
    padding-right: 50rem !important;
  }

  .xs\:pl-50 {
    padding-left: 50rem !important;
  }

  .xs\:pt-50 {
    padding-top: 50rem !important;
  }

  .xs\:pb-50 {
    padding-bottom: 50rem !important;
  }

  .xs\:p-60 {
    padding: 60rem !important;
  }

  .xs\:px-60 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
  }

  .xs\:py-60 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
  }

  .xs\:pr-60 {
    padding-right: 60rem !important;
  }

  .xs\:pl-60 {
    padding-left: 60rem !important;
  }

  .xs\:pt-60 {
    padding-top: 60rem !important;
  }

  .xs\:pb-60 {
    padding-bottom: 60rem !important;
  }

  .xs\:p-70 {
    padding: 70rem !important;
  }

  .xs\:px-70 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
  }

  .xs\:py-70 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
  }

  .xs\:pr-70 {
    padding-right: 70rem !important;
  }

  .xs\:pl-70 {
    padding-left: 70rem !important;
  }

  .xs\:pt-70 {
    padding-top: 70rem !important;
  }

  .xs\:pb-70 {
    padding-bottom: 70rem !important;
  }

  .xs\:p-80 {
    padding: 80rem !important;
  }

  .xs\:px-80 {
    padding-left: 80rem !important;
    padding-right: 80rem !important;
  }

  .xs\:py-80 {
    padding-top: 80rem !important;
    padding-bottom: 80rem !important;
  }

  .xs\:pr-80 {
    padding-right: 80rem !important;
  }

  .xs\:pl-80 {
    padding-left: 80rem !important;
  }

  .xs\:pt-80 {
    padding-top: 80rem !important;
  }

  .xs\:pb-80 {
    padding-bottom: 80rem !important;
  }

  .xs\:p-90 {
    padding: 90rem !important;
  }

  .xs\:px-90 {
    padding-left: 90rem !important;
    padding-right: 90rem !important;
  }

  .xs\:py-90 {
    padding-top: 90rem !important;
    padding-bottom: 90rem !important;
  }

  .xs\:pr-90 {
    padding-right: 90rem !important;
  }

  .xs\:pl-90 {
    padding-left: 90rem !important;
  }

  .xs\:pt-90 {
    padding-top: 90rem !important;
  }

  .xs\:pb-90 {
    padding-bottom: 90rem !important;
  }

  .xs\:p-100 {
    padding: 100rem !important;
  }

  .xs\:px-100 {
    padding-left: 100rem !important;
    padding-right: 100rem !important;
  }

  .xs\:py-100 {
    padding-top: 100rem !important;
    padding-bottom: 100rem !important;
  }

  .xs\:pr-100 {
    padding-right: 100rem !important;
  }

  .xs\:pl-100 {
    padding-left: 100rem !important;
  }

  .xs\:pt-100 {
    padding-top: 100rem !important;
  }

  .xs\:pb-100 {
    padding-bottom: 100rem !important;
  }

  .xs\:p-10p {
    padding: 10% !important;
  }

  .xs\:px-10p {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .xs\:py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }

  .xs\:pr-10p {
    padding-right: 10% !important;
  }

  .xs\:pl-10p {
    padding-left: 10% !important;
  }

  .xs\:pt-10p {
    padding-top: 10% !important;
  }

  .xs\:pb-10p {
    padding-bottom: 10% !important;
  }

  .xs\:p-20p {
    padding: 20% !important;
  }

  .xs\:px-20p {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .xs\:py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }

  .xs\:pr-20p {
    padding-right: 20% !important;
  }

  .xs\:pl-20p {
    padding-left: 20% !important;
  }

  .xs\:pt-20p {
    padding-top: 20% !important;
  }

  .xs\:pb-20p {
    padding-bottom: 20% !important;
  }

  .xs\:p-30p {
    padding: 30% !important;
  }

  .xs\:px-30p {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }

  .xs\:py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }

  .xs\:pr-30p {
    padding-right: 30% !important;
  }

  .xs\:pl-30p {
    padding-left: 30% !important;
  }

  .xs\:pt-30p {
    padding-top: 30% !important;
  }

  .xs\:pb-30p {
    padding-bottom: 30% !important;
  }

  .xs\:p-40p {
    padding: 40% !important;
  }

  .xs\:px-40p {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }

  .xs\:py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }

  .xs\:pr-40p {
    padding-right: 40% !important;
  }

  .xs\:pl-40p {
    padding-left: 40% !important;
  }

  .xs\:pt-40p {
    padding-top: 40% !important;
  }

  .xs\:pb-40p {
    padding-bottom: 40% !important;
  }

  .xs\:p-50p {
    padding: 50% !important;
  }

  .xs\:px-50p {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }

  .xs\:py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }

  .xs\:pr-50p {
    padding-right: 50% !important;
  }

  .xs\:pl-50p {
    padding-left: 50% !important;
  }

  .xs\:pt-50p {
    padding-top: 50% !important;
  }

  .xs\:pb-50p {
    padding-bottom: 50% !important;
  }

  .xs\:p-60p {
    padding: 60% !important;
  }

  .xs\:px-60p {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }

  .xs\:py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }

  .xs\:pr-60p {
    padding-right: 60% !important;
  }

  .xs\:pl-60p {
    padding-left: 60% !important;
  }

  .xs\:pt-60p {
    padding-top: 60% !important;
  }

  .xs\:pb-60p {
    padding-bottom: 60% !important;
  }

  .xs\:p-70p {
    padding: 70% !important;
  }

  .xs\:px-70p {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }

  .xs\:py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }

  .xs\:pr-70p {
    padding-right: 70% !important;
  }

  .xs\:pl-70p {
    padding-left: 70% !important;
  }

  .xs\:pt-70p {
    padding-top: 70% !important;
  }

  .xs\:pb-70p {
    padding-bottom: 70% !important;
  }

  .xs\:p-80p {
    padding: 80% !important;
  }

  .xs\:px-80p {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }

  .xs\:py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }

  .xs\:pr-80p {
    padding-right: 80% !important;
  }

  .xs\:pl-80p {
    padding-left: 80% !important;
  }

  .xs\:pt-80p {
    padding-top: 80% !important;
  }

  .xs\:pb-80p {
    padding-bottom: 80% !important;
  }

  .xs\:p-90p {
    padding: 90% !important;
  }

  .xs\:px-90p {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }

  .xs\:py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }

  .xs\:pr-90p {
    padding-right: 90% !important;
  }

  .xs\:pl-90p {
    padding-left: 90% !important;
  }

  .xs\:pt-90p {
    padding-top: 90% !important;
  }

  .xs\:pb-90p {
    padding-bottom: 90% !important;
  }

  .xs\:p-100p {
    padding: 100% !important;
  }

  .xs\:px-100p {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }

  .xs\:py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }

  .xs\:pr-100p {
    padding-right: 100% !important;
  }

  .xs\:pl-100p {
    padding-left: 100% !important;
  }

  .xs\:pt-100p {
    padding-top: 100% !important;
  }

  .xs\:pb-100p {
    padding-bottom: 100% !important;
  }

  .xs\:m-none {
    margin: 0 !important;
  }

  .xs\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xs\:ml-auto {
    margin-left: auto !important;
  }

  .xs\:mr-auto {
    margin-right: auto !important;
  }

  .xs\:mt-auto {
    margin-top: auto !important;
  }

  .xs\:mb-auto {
    margin-bottom: auto !important;
  }

  .xs\:m-0 {
    margin: 0 !important;
  }

  .xs\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xs\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xs\:mt-0 {
    margin-top: 0 !important;
  }

  .xs\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xs\:mr-0 {
    margin-right: 0 !important;
  }

  .xs\:ml-0 {
    margin-left: 0 !important;
  }

  .xs\:m-xs {
    margin: 0.25rem !important;
  }

  .xs\:my-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xs\:mx-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xs\:mt-xs {
    margin-top: 0.25rem !important;
  }

  .xs\:mb-xs {
    margin-bottom: 0.25rem !important;
  }

  .xs\:mr-xs {
    margin-right: 0.25rem !important;
  }

  .xs\:ml-xs {
    margin-left: 0.25rem !important;
  }

  .xs\:-m-xs {
    margin: -0.25rem !important;
  }

  .xs\:-mx-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xs\:-mt-xs {
    margin-top: -0.25rem !important;
  }

  .xs\:-mb-xs {
    margin-bottom: -0.25rem !important;
  }

  .xs\:-ml-xs {
    margin-left: -0.25rem !important;
  }

  .xs\:-mr-xs {
    margin-right: -0.25rem !important;
  }

  .xs\:m-sm {
    margin: 0.5rem !important;
  }

  .xs\:my-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xs\:mx-sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xs\:mt-sm {
    margin-top: 0.5rem !important;
  }

  .xs\:mb-sm {
    margin-bottom: 0.5rem !important;
  }

  .xs\:mr-sm {
    margin-right: 0.5rem !important;
  }

  .xs\:ml-sm {
    margin-left: 0.5rem !important;
  }

  .xs\:-m-sm {
    margin: -0.5rem !important;
  }

  .xs\:-mx-sm {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xs\:-mt-sm {
    margin-top: -0.5rem !important;
  }

  .xs\:-mb-sm {
    margin-bottom: -0.5rem !important;
  }

  .xs\:-ml-sm {
    margin-left: -0.5rem !important;
  }

  .xs\:-mr-sm {
    margin-right: -0.5rem !important;
  }

  .xs\:m-1 {
    margin: 1rem !important;
  }

  .xs\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xs\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xs\:mt-1 {
    margin-top: 1rem !important;
  }

  .xs\:mb-1 {
    margin-bottom: 1rem !important;
  }

  .xs\:mr-1 {
    margin-right: 1rem !important;
  }

  .xs\:ml-1 {
    margin-left: 1rem !important;
  }

  .xs\:-m-1 {
    margin: -1rem !important;
  }

  .xs\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xs\:-mt-1 {
    margin-top: -1rem !important;
  }

  .xs\:-mb-1 {
    margin-bottom: -1rem !important;
  }

  .xs\:-ml-1 {
    margin-left: -1rem !important;
  }

  .xs\:-mr-1 {
    margin-right: -1rem !important;
  }

  .xs\:m-2 {
    margin: 2rem !important;
  }

  .xs\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xs\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xs\:mt-2 {
    margin-top: 2rem !important;
  }

  .xs\:mb-2 {
    margin-bottom: 2rem !important;
  }

  .xs\:mr-2 {
    margin-right: 2rem !important;
  }

  .xs\:ml-2 {
    margin-left: 2rem !important;
  }

  .xs\:-m-2 {
    margin: -2rem !important;
  }

  .xs\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xs\:-mt-2 {
    margin-top: -2rem !important;
  }

  .xs\:-mb-2 {
    margin-bottom: -2rem !important;
  }

  .xs\:-ml-2 {
    margin-left: -2rem !important;
  }

  .xs\:-mr-2 {
    margin-right: -2rem !important;
  }

  .xs\:m-3 {
    margin: 3rem !important;
  }

  .xs\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xs\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xs\:mt-3 {
    margin-top: 3rem !important;
  }

  .xs\:mb-3 {
    margin-bottom: 3rem !important;
  }

  .xs\:mr-3 {
    margin-right: 3rem !important;
  }

  .xs\:ml-3 {
    margin-left: 3rem !important;
  }

  .xs\:-m-3 {
    margin: -3rem !important;
  }

  .xs\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xs\:-mt-3 {
    margin-top: -3rem !important;
  }

  .xs\:-mb-3 {
    margin-bottom: -3rem !important;
  }

  .xs\:-ml-3 {
    margin-left: -3rem !important;
  }

  .xs\:-mr-3 {
    margin-right: -3rem !important;
  }

  .xs\:m-4 {
    margin: 4rem !important;
  }

  .xs\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xs\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xs\:mt-4 {
    margin-top: 4rem !important;
  }

  .xs\:mb-4 {
    margin-bottom: 4rem !important;
  }

  .xs\:mr-4 {
    margin-right: 4rem !important;
  }

  .xs\:ml-4 {
    margin-left: 4rem !important;
  }

  .xs\:-m-4 {
    margin: -4rem !important;
  }

  .xs\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xs\:-mt-4 {
    margin-top: -4rem !important;
  }

  .xs\:-mb-4 {
    margin-bottom: -4rem !important;
  }

  .xs\:-ml-4 {
    margin-left: -4rem !important;
  }

  .xs\:-mr-4 {
    margin-right: -4rem !important;
  }

  .xs\:m-5 {
    margin: 5rem !important;
  }

  .xs\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xs\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xs\:mt-5 {
    margin-top: 5rem !important;
  }

  .xs\:mb-5 {
    margin-bottom: 5rem !important;
  }

  .xs\:mr-5 {
    margin-right: 5rem !important;
  }

  .xs\:ml-5 {
    margin-left: 5rem !important;
  }

  .xs\:-m-5 {
    margin: -5rem !important;
  }

  .xs\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xs\:-mt-5 {
    margin-top: -5rem !important;
  }

  .xs\:-mb-5 {
    margin-bottom: -5rem !important;
  }

  .xs\:-ml-5 {
    margin-left: -5rem !important;
  }

  .xs\:-mr-5 {
    margin-right: -5rem !important;
  }

  .xs\:m-6 {
    margin: 6rem !important;
  }

  .xs\:my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xs\:mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xs\:mt-6 {
    margin-top: 6rem !important;
  }

  .xs\:mb-6 {
    margin-bottom: 6rem !important;
  }

  .xs\:mr-6 {
    margin-right: 6rem !important;
  }

  .xs\:ml-6 {
    margin-left: 6rem !important;
  }

  .xs\:-m-6 {
    margin: -6rem !important;
  }

  .xs\:-mx-6 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xs\:-mt-6 {
    margin-top: -6rem !important;
  }

  .xs\:-mb-6 {
    margin-bottom: -6rem !important;
  }

  .xs\:-ml-6 {
    margin-left: -6rem !important;
  }

  .xs\:-mr-6 {
    margin-right: -6rem !important;
  }

  .xs\:m-7 {
    margin: 7rem !important;
  }

  .xs\:my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xs\:mx-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xs\:mt-7 {
    margin-top: 7rem !important;
  }

  .xs\:mb-7 {
    margin-bottom: 7rem !important;
  }

  .xs\:mr-7 {
    margin-right: 7rem !important;
  }

  .xs\:ml-7 {
    margin-left: 7rem !important;
  }

  .xs\:-m-7 {
    margin: -7rem !important;
  }

  .xs\:-mx-7 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xs\:-mt-7 {
    margin-top: -7rem !important;
  }

  .xs\:-mb-7 {
    margin-bottom: -7rem !important;
  }

  .xs\:-ml-7 {
    margin-left: -7rem !important;
  }

  .xs\:-mr-7 {
    margin-right: -7rem !important;
  }

  .xs\:m-8 {
    margin: 8rem !important;
  }

  .xs\:my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xs\:mx-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xs\:mt-8 {
    margin-top: 8rem !important;
  }

  .xs\:mb-8 {
    margin-bottom: 8rem !important;
  }

  .xs\:mr-8 {
    margin-right: 8rem !important;
  }

  .xs\:ml-8 {
    margin-left: 8rem !important;
  }

  .xs\:-m-8 {
    margin: -8rem !important;
  }

  .xs\:-mx-8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xs\:-mt-8 {
    margin-top: -8rem !important;
  }

  .xs\:-mb-8 {
    margin-bottom: -8rem !important;
  }

  .xs\:-ml-8 {
    margin-left: -8rem !important;
  }

  .xs\:-mr-8 {
    margin-right: -8rem !important;
  }

  .xs\:m-9 {
    margin: 9rem !important;
  }

  .xs\:my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xs\:mx-9 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xs\:mt-9 {
    margin-top: 9rem !important;
  }

  .xs\:mb-9 {
    margin-bottom: 9rem !important;
  }

  .xs\:mr-9 {
    margin-right: 9rem !important;
  }

  .xs\:ml-9 {
    margin-left: 9rem !important;
  }

  .xs\:-m-9 {
    margin: -9rem !important;
  }

  .xs\:-mx-9 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xs\:-mt-9 {
    margin-top: -9rem !important;
  }

  .xs\:-mb-9 {
    margin-bottom: -9rem !important;
  }

  .xs\:-ml-9 {
    margin-left: -9rem !important;
  }

  .xs\:-mr-9 {
    margin-right: -9rem !important;
  }

  .xs\:m-10 {
    margin: 10rem !important;
  }

  .xs\:my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xs\:mx-10 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xs\:mt-10 {
    margin-top: 10rem !important;
  }

  .xs\:mb-10 {
    margin-bottom: 10rem !important;
  }

  .xs\:mr-10 {
    margin-right: 10rem !important;
  }

  .xs\:ml-10 {
    margin-left: 10rem !important;
  }

  .xs\:-m-10 {
    margin: -10rem !important;
  }

  .xs\:-mx-10 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xs\:-mt-10 {
    margin-top: -10rem !important;
  }

  .xs\:-mb-10 {
    margin-bottom: -10rem !important;
  }

  .xs\:-ml-10 {
    margin-left: -10rem !important;
  }

  .xs\:-mr-10 {
    margin-right: -10rem !important;
  }

  .xs\:m-15 {
    margin: 15rem !important;
  }

  .xs\:my-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .xs\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .xs\:mt-15 {
    margin-top: 15rem !important;
  }

  .xs\:mb-15 {
    margin-bottom: 15rem !important;
  }

  .xs\:mr-15 {
    margin-right: 15rem !important;
  }

  .xs\:ml-15 {
    margin-left: 15rem !important;
  }

  .xs\:-m-15 {
    margin: -15rem !important;
  }

  .xs\:-mx-15 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .xs\:-mt-15 {
    margin-top: -15rem !important;
  }

  .xs\:-mb-15 {
    margin-bottom: -15rem !important;
  }

  .xs\:-ml-15 {
    margin-left: -15rem !important;
  }

  .xs\:-mr-15 {
    margin-right: -15rem !important;
  }

  .xs\:m-20 {
    margin: 20rem !important;
  }

  .xs\:my-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .xs\:mx-20 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .xs\:mt-20 {
    margin-top: 20rem !important;
  }

  .xs\:mb-20 {
    margin-bottom: 20rem !important;
  }

  .xs\:mr-20 {
    margin-right: 20rem !important;
  }

  .xs\:ml-20 {
    margin-left: 20rem !important;
  }

  .xs\:-m-20 {
    margin: -20rem !important;
  }

  .xs\:-mx-20 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .xs\:-mt-20 {
    margin-top: -20rem !important;
  }

  .xs\:-mb-20 {
    margin-bottom: -20rem !important;
  }

  .xs\:-ml-20 {
    margin-left: -20rem !important;
  }

  .xs\:-mr-20 {
    margin-right: -20rem !important;
  }

  .xs\:m-30 {
    margin: 30rem !important;
  }

  .xs\:my-30 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .xs\:mx-30 {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }

  .xs\:mt-30 {
    margin-top: 30rem !important;
  }

  .xs\:mb-30 {
    margin-bottom: 30rem !important;
  }

  .xs\:mr-30 {
    margin-right: 30rem !important;
  }

  .xs\:ml-30 {
    margin-left: 30rem !important;
  }

  .xs\:-m-30 {
    margin: -30rem !important;
  }

  .xs\:-mx-30 {
    margin-left: -30rem !important;
    margin-right: -30rem !important;
  }

  .xs\:-mt-30 {
    margin-top: -30rem !important;
  }

  .xs\:-mb-30 {
    margin-bottom: -30rem !important;
  }

  .xs\:-ml-30 {
    margin-left: -30rem !important;
  }

  .xs\:-mr-30 {
    margin-right: -30rem !important;
  }

  .xs\:m-40 {
    margin: 40rem !important;
  }

  .xs\:my-40 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important;
  }

  .xs\:mx-40 {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }

  .xs\:mt-40 {
    margin-top: 40rem !important;
  }

  .xs\:mb-40 {
    margin-bottom: 40rem !important;
  }

  .xs\:mr-40 {
    margin-right: 40rem !important;
  }

  .xs\:ml-40 {
    margin-left: 40rem !important;
  }

  .xs\:-m-40 {
    margin: -40rem !important;
  }

  .xs\:-mx-40 {
    margin-left: -40rem !important;
    margin-right: -40rem !important;
  }

  .xs\:-mt-40 {
    margin-top: -40rem !important;
  }

  .xs\:-mb-40 {
    margin-bottom: -40rem !important;
  }

  .xs\:-ml-40 {
    margin-left: -40rem !important;
  }

  .xs\:-mr-40 {
    margin-right: -40rem !important;
  }

  .xs\:m-50 {
    margin: 50rem !important;
  }

  .xs\:my-50 {
    margin-top: 50rem !important;
    margin-bottom: 50rem !important;
  }

  .xs\:mx-50 {
    margin-left: 50rem !important;
    margin-right: 50rem !important;
  }

  .xs\:mt-50 {
    margin-top: 50rem !important;
  }

  .xs\:mb-50 {
    margin-bottom: 50rem !important;
  }

  .xs\:mr-50 {
    margin-right: 50rem !important;
  }

  .xs\:ml-50 {
    margin-left: 50rem !important;
  }

  .xs\:-m-50 {
    margin: -50rem !important;
  }

  .xs\:-mx-50 {
    margin-left: -50rem !important;
    margin-right: -50rem !important;
  }

  .xs\:-mt-50 {
    margin-top: -50rem !important;
  }

  .xs\:-mb-50 {
    margin-bottom: -50rem !important;
  }

  .xs\:-ml-50 {
    margin-left: -50rem !important;
  }

  .xs\:-mr-50 {
    margin-right: -50rem !important;
  }

  .xs\:m-60 {
    margin: 60rem !important;
  }

  .xs\:my-60 {
    margin-top: 60rem !important;
    margin-bottom: 60rem !important;
  }

  .xs\:mx-60 {
    margin-left: 60rem !important;
    margin-right: 60rem !important;
  }

  .xs\:mt-60 {
    margin-top: 60rem !important;
  }

  .xs\:mb-60 {
    margin-bottom: 60rem !important;
  }

  .xs\:mr-60 {
    margin-right: 60rem !important;
  }

  .xs\:ml-60 {
    margin-left: 60rem !important;
  }

  .xs\:-m-60 {
    margin: -60rem !important;
  }

  .xs\:-mx-60 {
    margin-left: -60rem !important;
    margin-right: -60rem !important;
  }

  .xs\:-mt-60 {
    margin-top: -60rem !important;
  }

  .xs\:-mb-60 {
    margin-bottom: -60rem !important;
  }

  .xs\:-ml-60 {
    margin-left: -60rem !important;
  }

  .xs\:-mr-60 {
    margin-right: -60rem !important;
  }

  .xs\:m-70 {
    margin: 70rem !important;
  }

  .xs\:my-70 {
    margin-top: 70rem !important;
    margin-bottom: 70rem !important;
  }

  .xs\:mx-70 {
    margin-left: 70rem !important;
    margin-right: 70rem !important;
  }

  .xs\:mt-70 {
    margin-top: 70rem !important;
  }

  .xs\:mb-70 {
    margin-bottom: 70rem !important;
  }

  .xs\:mr-70 {
    margin-right: 70rem !important;
  }

  .xs\:ml-70 {
    margin-left: 70rem !important;
  }

  .xs\:-m-70 {
    margin: -70rem !important;
  }

  .xs\:-mx-70 {
    margin-left: -70rem !important;
    margin-right: -70rem !important;
  }

  .xs\:-mt-70 {
    margin-top: -70rem !important;
  }

  .xs\:-mb-70 {
    margin-bottom: -70rem !important;
  }

  .xs\:-ml-70 {
    margin-left: -70rem !important;
  }

  .xs\:-mr-70 {
    margin-right: -70rem !important;
  }

  .xs\:m-80 {
    margin: 80rem !important;
  }

  .xs\:my-80 {
    margin-top: 80rem !important;
    margin-bottom: 80rem !important;
  }

  .xs\:mx-80 {
    margin-left: 80rem !important;
    margin-right: 80rem !important;
  }

  .xs\:mt-80 {
    margin-top: 80rem !important;
  }

  .xs\:mb-80 {
    margin-bottom: 80rem !important;
  }

  .xs\:mr-80 {
    margin-right: 80rem !important;
  }

  .xs\:ml-80 {
    margin-left: 80rem !important;
  }

  .xs\:-m-80 {
    margin: -80rem !important;
  }

  .xs\:-mx-80 {
    margin-left: -80rem !important;
    margin-right: -80rem !important;
  }

  .xs\:-mt-80 {
    margin-top: -80rem !important;
  }

  .xs\:-mb-80 {
    margin-bottom: -80rem !important;
  }

  .xs\:-ml-80 {
    margin-left: -80rem !important;
  }

  .xs\:-mr-80 {
    margin-right: -80rem !important;
  }

  .xs\:m-90 {
    margin: 90rem !important;
  }

  .xs\:my-90 {
    margin-top: 90rem !important;
    margin-bottom: 90rem !important;
  }

  .xs\:mx-90 {
    margin-left: 90rem !important;
    margin-right: 90rem !important;
  }

  .xs\:mt-90 {
    margin-top: 90rem !important;
  }

  .xs\:mb-90 {
    margin-bottom: 90rem !important;
  }

  .xs\:mr-90 {
    margin-right: 90rem !important;
  }

  .xs\:ml-90 {
    margin-left: 90rem !important;
  }

  .xs\:-m-90 {
    margin: -90rem !important;
  }

  .xs\:-mx-90 {
    margin-left: -90rem !important;
    margin-right: -90rem !important;
  }

  .xs\:-mt-90 {
    margin-top: -90rem !important;
  }

  .xs\:-mb-90 {
    margin-bottom: -90rem !important;
  }

  .xs\:-ml-90 {
    margin-left: -90rem !important;
  }

  .xs\:-mr-90 {
    margin-right: -90rem !important;
  }

  .xs\:m-100 {
    margin: 100rem !important;
  }

  .xs\:my-100 {
    margin-top: 100rem !important;
    margin-bottom: 100rem !important;
  }

  .xs\:mx-100 {
    margin-left: 100rem !important;
    margin-right: 100rem !important;
  }

  .xs\:mt-100 {
    margin-top: 100rem !important;
  }

  .xs\:mb-100 {
    margin-bottom: 100rem !important;
  }

  .xs\:mr-100 {
    margin-right: 100rem !important;
  }

  .xs\:ml-100 {
    margin-left: 100rem !important;
  }

  .xs\:-m-100 {
    margin: -100rem !important;
  }

  .xs\:-mx-100 {
    margin-left: -100rem !important;
    margin-right: -100rem !important;
  }

  .xs\:-mt-100 {
    margin-top: -100rem !important;
  }

  .xs\:-mb-100 {
    margin-bottom: -100rem !important;
  }

  .xs\:-ml-100 {
    margin-left: -100rem !important;
  }

  .xs\:-mr-100 {
    margin-right: -100rem !important;
  }

  .xs\:m-10p {
    margin: 10% !important;
  }

  .xs\:my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

  .xs\:mx-10p {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .xs\:mt-10p {
    margin-top: 10% !important;
  }

  .xs\:mb-10p {
    margin-bottom: 10% !important;
  }

  .xs\:mr-10p {
    margin-right: 10% !important;
  }

  .xs\:ml-10p {
    margin-left: 10% !important;
  }

  .xs\:-m-10p {
    margin: -10% !important;
  }

  .xs\:-mx-10p {
    margin-left: -10% !important;
    margin-right: -10% !important;
  }

  .xs\:-mt-10p {
    margin-top: -10% !important;
  }

  .xs\:-mb-10p {
    margin-bottom: -10% !important;
  }

  .xs\:-ml-10p {
    margin-left: -10% !important;
  }

  .xs\:-mr-10p {
    margin-right: -10% !important;
  }

  .xs\:m-20p {
    margin: 20% !important;
  }

  .xs\:my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }

  .xs\:mx-20p {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .xs\:mt-20p {
    margin-top: 20% !important;
  }

  .xs\:mb-20p {
    margin-bottom: 20% !important;
  }

  .xs\:mr-20p {
    margin-right: 20% !important;
  }

  .xs\:ml-20p {
    margin-left: 20% !important;
  }

  .xs\:-m-20p {
    margin: -20% !important;
  }

  .xs\:-mx-20p {
    margin-left: -20% !important;
    margin-right: -20% !important;
  }

  .xs\:-mt-20p {
    margin-top: -20% !important;
  }

  .xs\:-mb-20p {
    margin-bottom: -20% !important;
  }

  .xs\:-ml-20p {
    margin-left: -20% !important;
  }

  .xs\:-mr-20p {
    margin-right: -20% !important;
  }

  .xs\:m-30p {
    margin: 30% !important;
  }

  .xs\:my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }

  .xs\:mx-30p {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }

  .xs\:mt-30p {
    margin-top: 30% !important;
  }

  .xs\:mb-30p {
    margin-bottom: 30% !important;
  }

  .xs\:mr-30p {
    margin-right: 30% !important;
  }

  .xs\:ml-30p {
    margin-left: 30% !important;
  }

  .xs\:-m-30p {
    margin: -30% !important;
  }

  .xs\:-mx-30p {
    margin-left: -30% !important;
    margin-right: -30% !important;
  }

  .xs\:-mt-30p {
    margin-top: -30% !important;
  }

  .xs\:-mb-30p {
    margin-bottom: -30% !important;
  }

  .xs\:-ml-30p {
    margin-left: -30% !important;
  }

  .xs\:-mr-30p {
    margin-right: -30% !important;
  }

  .xs\:m-40p {
    margin: 40% !important;
  }

  .xs\:my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }

  .xs\:mx-40p {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }

  .xs\:mt-40p {
    margin-top: 40% !important;
  }

  .xs\:mb-40p {
    margin-bottom: 40% !important;
  }

  .xs\:mr-40p {
    margin-right: 40% !important;
  }

  .xs\:ml-40p {
    margin-left: 40% !important;
  }

  .xs\:-m-40p {
    margin: -40% !important;
  }

  .xs\:-mx-40p {
    margin-left: -40% !important;
    margin-right: -40% !important;
  }

  .xs\:-mt-40p {
    margin-top: -40% !important;
  }

  .xs\:-mb-40p {
    margin-bottom: -40% !important;
  }

  .xs\:-ml-40p {
    margin-left: -40% !important;
  }

  .xs\:-mr-40p {
    margin-right: -40% !important;
  }

  .xs\:m-50p {
    margin: 50% !important;
  }

  .xs\:my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }

  .xs\:mx-50p {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }

  .xs\:mt-50p {
    margin-top: 50% !important;
  }

  .xs\:mb-50p {
    margin-bottom: 50% !important;
  }

  .xs\:mr-50p {
    margin-right: 50% !important;
  }

  .xs\:ml-50p {
    margin-left: 50% !important;
  }

  .xs\:-m-50p {
    margin: -50% !important;
  }

  .xs\:-mx-50p {
    margin-left: -50% !important;
    margin-right: -50% !important;
  }

  .xs\:-mt-50p {
    margin-top: -50% !important;
  }

  .xs\:-mb-50p {
    margin-bottom: -50% !important;
  }

  .xs\:-ml-50p {
    margin-left: -50% !important;
  }

  .xs\:-mr-50p {
    margin-right: -50% !important;
  }

  .xs\:m-60p {
    margin: 60% !important;
  }

  .xs\:my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }

  .xs\:mx-60p {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }

  .xs\:mt-60p {
    margin-top: 60% !important;
  }

  .xs\:mb-60p {
    margin-bottom: 60% !important;
  }

  .xs\:mr-60p {
    margin-right: 60% !important;
  }

  .xs\:ml-60p {
    margin-left: 60% !important;
  }

  .xs\:-m-60p {
    margin: -60% !important;
  }

  .xs\:-mx-60p {
    margin-left: -60% !important;
    margin-right: -60% !important;
  }

  .xs\:-mt-60p {
    margin-top: -60% !important;
  }

  .xs\:-mb-60p {
    margin-bottom: -60% !important;
  }

  .xs\:-ml-60p {
    margin-left: -60% !important;
  }

  .xs\:-mr-60p {
    margin-right: -60% !important;
  }

  .xs\:m-70p {
    margin: 70% !important;
  }

  .xs\:my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }

  .xs\:mx-70p {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }

  .xs\:mt-70p {
    margin-top: 70% !important;
  }

  .xs\:mb-70p {
    margin-bottom: 70% !important;
  }

  .xs\:mr-70p {
    margin-right: 70% !important;
  }

  .xs\:ml-70p {
    margin-left: 70% !important;
  }

  .xs\:-m-70p {
    margin: -70% !important;
  }

  .xs\:-mx-70p {
    margin-left: -70% !important;
    margin-right: -70% !important;
  }

  .xs\:-mt-70p {
    margin-top: -70% !important;
  }

  .xs\:-mb-70p {
    margin-bottom: -70% !important;
  }

  .xs\:-ml-70p {
    margin-left: -70% !important;
  }

  .xs\:-mr-70p {
    margin-right: -70% !important;
  }

  .xs\:m-80p {
    margin: 80% !important;
  }

  .xs\:my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }

  .xs\:mx-80p {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }

  .xs\:mt-80p {
    margin-top: 80% !important;
  }

  .xs\:mb-80p {
    margin-bottom: 80% !important;
  }

  .xs\:mr-80p {
    margin-right: 80% !important;
  }

  .xs\:ml-80p {
    margin-left: 80% !important;
  }

  .xs\:-m-80p {
    margin: -80% !important;
  }

  .xs\:-mx-80p {
    margin-left: -80% !important;
    margin-right: -80% !important;
  }

  .xs\:-mt-80p {
    margin-top: -80% !important;
  }

  .xs\:-mb-80p {
    margin-bottom: -80% !important;
  }

  .xs\:-ml-80p {
    margin-left: -80% !important;
  }

  .xs\:-mr-80p {
    margin-right: -80% !important;
  }

  .xs\:m-90p {
    margin: 90% !important;
  }

  .xs\:my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }

  .xs\:mx-90p {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }

  .xs\:mt-90p {
    margin-top: 90% !important;
  }

  .xs\:mb-90p {
    margin-bottom: 90% !important;
  }

  .xs\:mr-90p {
    margin-right: 90% !important;
  }

  .xs\:ml-90p {
    margin-left: 90% !important;
  }

  .xs\:-m-90p {
    margin: -90% !important;
  }

  .xs\:-mx-90p {
    margin-left: -90% !important;
    margin-right: -90% !important;
  }

  .xs\:-mt-90p {
    margin-top: -90% !important;
  }

  .xs\:-mb-90p {
    margin-bottom: -90% !important;
  }

  .xs\:-ml-90p {
    margin-left: -90% !important;
  }

  .xs\:-mr-90p {
    margin-right: -90% !important;
  }

  .xs\:m-100p {
    margin: 100% !important;
  }

  .xs\:my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }

  .xs\:mx-100p {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }

  .xs\:mt-100p {
    margin-top: 100% !important;
  }

  .xs\:mb-100p {
    margin-bottom: 100% !important;
  }

  .xs\:mr-100p {
    margin-right: 100% !important;
  }

  .xs\:ml-100p {
    margin-left: 100% !important;
  }

  .xs\:-m-100p {
    margin: -100% !important;
  }

  .xs\:-mx-100p {
    margin-left: -100% !important;
    margin-right: -100% !important;
  }

  .xs\:-mt-100p {
    margin-top: -100% !important;
  }

  .xs\:-mb-100p {
    margin-bottom: -100% !important;
  }

  .xs\:-ml-100p {
    margin-left: -100% !important;
  }

  .xs\:-mr-100p {
    margin-right: -100% !important;
  }

  .xs\:fixed {
    position: fixed !important;
  }

  .xs\:absolute {
    position: absolute !important;
  }

  .xs\:relative {
    position: relative !important;
  }

  .xs\:sticky {
    position: sticky !important;
  }

  .xs\:top-0 {
    top: 0 !important;
  }

  .xs\:right-0 {
    right: 0 !important;
  }

  .xs\:bottom-0 {
    bottom: 0 !important;
  }

  .xs\:left-0 {
    left: 0 !important;
  }

  .xs\:trbl-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xs\:z-auto {
    z-index: auto !important;
  }

  .xs\:z-0 {
    z-index: 0 !important;
  }

  .xs\:z-10 {
    z-index: 10 !important;
  }

  .xs\:z-20 {
    z-index: 20 !important;
  }

  .xs\:z-100 {
    z-index: 100 !important;
  }

  .-xs\:z-1 {
    z-index: -1 !important;
  }

  .xs\:border-0 {
    border: 0 !important;
  }

  .xs\:round {
    border-radius: 100vw !important;
  }

  .xs\:rounded-0 {
    border-radius: 0 !important;
  }

  .xs\:rounded-xxl {
    border-radius: 2rem !important;
  }

  .xs\:rounded-xl {
    border-radius: 1rem !important;
  }

  .xs\:rounded-lg {
    border-radius: 0.75rem !important;
  }

  .xs\:rounded-md {
    border-radius: 0.5rem !important;
  }

  .xs\:rounded-sm {
    border-radius: 0.25rem !important;
  }

  .xs\:rounded-xs {
    border-radius: 0.125rem !important;
  }
}
@media (min-width: 782px) {
  .sm\:flex {
    display: flex !important;
  }

  .sm\:inline-flex {
    display: inline-flex  !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:inline-block {
    display: inline-block !important;
  }

  .sm\:hidden,
.sm\:dnone {
    display: none !important;
  }

  .sm\:flex-column {
    flex-direction: column;
  }

  .sm\:flex-column.reverse,
.sm\:column-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-1 {
    flex: 1 1 0%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:row-reverse,
.sm\:reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:items-center,
.sm\:y-center,
.sm\:vertical-center {
    align-items: center;
  }

  .sm\:flex-start,
.sm\:items-start {
    align-items: flex-start;
  }

  .sm\:flex-end,
.sm\:items-end {
    align-items: flex-end;
  }

  .sm\:center {
    align-items: center;
    text-align: center;
  }

  .sm\:content-start {
    align-content: flex-start;
  }

  .sm\:content-center {
    align-content: center;
  }

  .sm\:content-end {
    align-content: flex-end;
  }

  .sm\:content-between {
    align-content: space-between;
  }

  .sm\:content-around {
    align-content: space-around;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-center,
.sm\:x-center,
.sm\:horizontal-center {
    justify-content: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:space-between,
.sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:space-around,
.sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }

  .sm\:text-uppercase {
    text-transform: uppercase !important;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }

  .sm\:font-size-xxl {
    font-size: 5rem !important;
  }

  .sm\:font-size-xl {
    font-size: 2rem !important;
  }

  .sm\:font-size-lg {
    font-size: 1.5rem !important;
  }

  .sm\:font-size-md {
    font-size: 1.25rem !important;
  }

  .sm\:font-size-default {
    font-size: 1rem !important;
  }

  .sm\:font-size-sm {
    font-size: 0.75rem !important;
  }

  .sm\:font-size-xs {
    font-size: 0.5rem !important;
  }

  .sm\:w-1\/4,
.sm\:w-25 {
    width: 25% !important;
  }

  .sm\:w-1\/3,
.sm\:w-33 {
    width: 33.33% !important;
  }

  .sm\:w-1\/2,
.sm\:w-2\/4,
.sm\:w-half,
.sm\:w-50 {
    width: 50% !important;
  }

  .sm\:w-2\/3,
.sm\:w-66 {
    width: 66.66% !important;
  }

  .sm\:w-3\/4,
.sm\:w-75 {
    width: 75% !important;
  }

  .sm\:w-100,
.sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:mxw-xs {
    max-width: 600px !important;
  }

  .sm\:mxw-sm {
    max-width: 782px !important;
  }

  .sm\:mxw-md {
    max-width: 1024px !important;
  }

  .sm\:mxw-lg {
    max-width: 1220px !important;
  }

  .sm\:mxw-xl {
    max-width: 1460px !important;
  }

  .sm\:h-100 {
    height: 100% !important;
  }

  .sm\:h-100vh {
    height: 100vh !important;
  }

  .sm\:h-50 {
    height: 50% !important;
  }

  .sm\:h-50vh {
    height: 50vh !important;
  }

  .sm\:h-inherit {
    height: inherit !important;
  }

  .sm\:color-primary {
    color: #25373e !important;
  }

  .sm\:bg-primary {
    background-color: #25373e !important;
  }

  .sm\:color-secondary {
    color: #e9a844 !important;
  }

  .sm\:bg-secondary {
    background-color: #e9a844 !important;
  }

  .sm\:color-tertiary {
    color: #3d6574 !important;
  }

  .sm\:bg-tertiary {
    background-color: #3d6574 !important;
  }

  .sm\:color-white {
    color: #fff !important;
  }

  .sm\:bg-white {
    background-color: #fff !important;
  }

  .sm\:color-light-primary {
    color: #25373eed !important;
  }

  .sm\:bg-light-primary {
    background-color: #25373eed !important;
  }

  .sm\:color-extra-light-primary {
    color: #25383d0f !important;
  }

  .sm\:bg-extra-light-primary {
    background-color: #25383d0f !important;
  }

  .sm\:color-light-secondary {
    color: #e9a8449c !important;
  }

  .sm\:bg-light-secondary {
    background-color: #e9a8449c !important;
  }

  .sm\:p-none {
    padding: 0 !important;
  }

  .sm\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .sm\:p-0 {
    padding: 0 !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sm\:pr-0 {
    padding-right: 0 !important;
  }

  .sm\:pl-0 {
    padding-left: 0 !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .sm\:p-xs {
    padding: 0.25rem !important;
  }

  .sm\:px-xs {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:py-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:pr-xs {
    padding-right: 0.25rem !important;
  }

  .sm\:pl-xs {
    padding-left: 0.25rem !important;
  }

  .sm\:pt-xs {
    padding-top: 0.25rem !important;
  }

  .sm\:pb-xs {
    padding-bottom: 0.25rem !important;
  }

  .sm\:p-sm {
    padding: 0.5rem !important;
  }

  .sm\:px-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:py-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:pr-sm {
    padding-right: 0.5rem !important;
  }

  .sm\:pl-sm {
    padding-left: 0.5rem !important;
  }

  .sm\:pt-sm {
    padding-top: 0.5rem !important;
  }

  .sm\:pb-sm {
    padding-bottom: 0.5rem !important;
  }

  .sm\:p-1 {
    padding: 1rem !important;
  }

  .sm\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:pr-1 {
    padding-right: 1rem !important;
  }

  .sm\:pl-1 {
    padding-left: 1rem !important;
  }

  .sm\:pt-1 {
    padding-top: 1rem !important;
  }

  .sm\:pb-1 {
    padding-bottom: 1rem !important;
  }

  .sm\:p-2 {
    padding: 2rem !important;
  }

  .sm\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:pr-2 {
    padding-right: 2rem !important;
  }

  .sm\:pl-2 {
    padding-left: 2rem !important;
  }

  .sm\:pt-2 {
    padding-top: 2rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: 2rem !important;
  }

  .sm\:p-3 {
    padding: 3rem !important;
  }

  .sm\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:pr-3 {
    padding-right: 3rem !important;
  }

  .sm\:pl-3 {
    padding-left: 3rem !important;
  }

  .sm\:pt-3 {
    padding-top: 3rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 3rem !important;
  }

  .sm\:p-4 {
    padding: 4rem !important;
  }

  .sm\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:pr-4 {
    padding-right: 4rem !important;
  }

  .sm\:pl-4 {
    padding-left: 4rem !important;
  }

  .sm\:pt-4 {
    padding-top: 4rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 4rem !important;
  }

  .sm\:p-5 {
    padding: 5rem !important;
  }

  .sm\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sm\:pr-5 {
    padding-right: 5rem !important;
  }

  .sm\:pl-5 {
    padding-left: 5rem !important;
  }

  .sm\:pt-5 {
    padding-top: 5rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 5rem !important;
  }

  .sm\:p-6 {
    padding: 6rem !important;
  }

  .sm\:px-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sm\:py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .sm\:pr-6 {
    padding-right: 6rem !important;
  }

  .sm\:pl-6 {
    padding-left: 6rem !important;
  }

  .sm\:pt-6 {
    padding-top: 6rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 6rem !important;
  }

  .sm\:p-7 {
    padding: 7rem !important;
  }

  .sm\:px-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .sm\:py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .sm\:pr-7 {
    padding-right: 7rem !important;
  }

  .sm\:pl-7 {
    padding-left: 7rem !important;
  }

  .sm\:pt-7 {
    padding-top: 7rem !important;
  }

  .sm\:pb-7 {
    padding-bottom: 7rem !important;
  }

  .sm\:p-8 {
    padding: 8rem !important;
  }

  .sm\:px-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .sm\:py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .sm\:pr-8 {
    padding-right: 8rem !important;
  }

  .sm\:pl-8 {
    padding-left: 8rem !important;
  }

  .sm\:pt-8 {
    padding-top: 8rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 8rem !important;
  }

  .sm\:p-9 {
    padding: 9rem !important;
  }

  .sm\:px-9 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .sm\:py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .sm\:pr-9 {
    padding-right: 9rem !important;
  }

  .sm\:pl-9 {
    padding-left: 9rem !important;
  }

  .sm\:pt-9 {
    padding-top: 9rem !important;
  }

  .sm\:pb-9 {
    padding-bottom: 9rem !important;
  }

  .sm\:p-10 {
    padding: 10rem !important;
  }

  .sm\:px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .sm\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .sm\:pr-10 {
    padding-right: 10rem !important;
  }

  .sm\:pl-10 {
    padding-left: 10rem !important;
  }

  .sm\:pt-10 {
    padding-top: 10rem !important;
  }

  .sm\:pb-10 {
    padding-bottom: 10rem !important;
  }

  .sm\:p-15 {
    padding: 15rem !important;
  }

  .sm\:px-15 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .sm\:py-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .sm\:pr-15 {
    padding-right: 15rem !important;
  }

  .sm\:pl-15 {
    padding-left: 15rem !important;
  }

  .sm\:pt-15 {
    padding-top: 15rem !important;
  }

  .sm\:pb-15 {
    padding-bottom: 15rem !important;
  }

  .sm\:p-20 {
    padding: 20rem !important;
  }

  .sm\:px-20 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .sm\:py-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .sm\:pr-20 {
    padding-right: 20rem !important;
  }

  .sm\:pl-20 {
    padding-left: 20rem !important;
  }

  .sm\:pt-20 {
    padding-top: 20rem !important;
  }

  .sm\:pb-20 {
    padding-bottom: 20rem !important;
  }

  .sm\:p-30 {
    padding: 30rem !important;
  }

  .sm\:px-30 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
  }

  .sm\:py-30 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .sm\:pr-30 {
    padding-right: 30rem !important;
  }

  .sm\:pl-30 {
    padding-left: 30rem !important;
  }

  .sm\:pt-30 {
    padding-top: 30rem !important;
  }

  .sm\:pb-30 {
    padding-bottom: 30rem !important;
  }

  .sm\:p-40 {
    padding: 40rem !important;
  }

  .sm\:px-40 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
  }

  .sm\:py-40 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }

  .sm\:pr-40 {
    padding-right: 40rem !important;
  }

  .sm\:pl-40 {
    padding-left: 40rem !important;
  }

  .sm\:pt-40 {
    padding-top: 40rem !important;
  }

  .sm\:pb-40 {
    padding-bottom: 40rem !important;
  }

  .sm\:p-50 {
    padding: 50rem !important;
  }

  .sm\:px-50 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
  }

  .sm\:py-50 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
  }

  .sm\:pr-50 {
    padding-right: 50rem !important;
  }

  .sm\:pl-50 {
    padding-left: 50rem !important;
  }

  .sm\:pt-50 {
    padding-top: 50rem !important;
  }

  .sm\:pb-50 {
    padding-bottom: 50rem !important;
  }

  .sm\:p-60 {
    padding: 60rem !important;
  }

  .sm\:px-60 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
  }

  .sm\:py-60 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
  }

  .sm\:pr-60 {
    padding-right: 60rem !important;
  }

  .sm\:pl-60 {
    padding-left: 60rem !important;
  }

  .sm\:pt-60 {
    padding-top: 60rem !important;
  }

  .sm\:pb-60 {
    padding-bottom: 60rem !important;
  }

  .sm\:p-70 {
    padding: 70rem !important;
  }

  .sm\:px-70 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
  }

  .sm\:py-70 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
  }

  .sm\:pr-70 {
    padding-right: 70rem !important;
  }

  .sm\:pl-70 {
    padding-left: 70rem !important;
  }

  .sm\:pt-70 {
    padding-top: 70rem !important;
  }

  .sm\:pb-70 {
    padding-bottom: 70rem !important;
  }

  .sm\:p-80 {
    padding: 80rem !important;
  }

  .sm\:px-80 {
    padding-left: 80rem !important;
    padding-right: 80rem !important;
  }

  .sm\:py-80 {
    padding-top: 80rem !important;
    padding-bottom: 80rem !important;
  }

  .sm\:pr-80 {
    padding-right: 80rem !important;
  }

  .sm\:pl-80 {
    padding-left: 80rem !important;
  }

  .sm\:pt-80 {
    padding-top: 80rem !important;
  }

  .sm\:pb-80 {
    padding-bottom: 80rem !important;
  }

  .sm\:p-90 {
    padding: 90rem !important;
  }

  .sm\:px-90 {
    padding-left: 90rem !important;
    padding-right: 90rem !important;
  }

  .sm\:py-90 {
    padding-top: 90rem !important;
    padding-bottom: 90rem !important;
  }

  .sm\:pr-90 {
    padding-right: 90rem !important;
  }

  .sm\:pl-90 {
    padding-left: 90rem !important;
  }

  .sm\:pt-90 {
    padding-top: 90rem !important;
  }

  .sm\:pb-90 {
    padding-bottom: 90rem !important;
  }

  .sm\:p-100 {
    padding: 100rem !important;
  }

  .sm\:px-100 {
    padding-left: 100rem !important;
    padding-right: 100rem !important;
  }

  .sm\:py-100 {
    padding-top: 100rem !important;
    padding-bottom: 100rem !important;
  }

  .sm\:pr-100 {
    padding-right: 100rem !important;
  }

  .sm\:pl-100 {
    padding-left: 100rem !important;
  }

  .sm\:pt-100 {
    padding-top: 100rem !important;
  }

  .sm\:pb-100 {
    padding-bottom: 100rem !important;
  }

  .sm\:p-10p {
    padding: 10% !important;
  }

  .sm\:px-10p {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .sm\:py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }

  .sm\:pr-10p {
    padding-right: 10% !important;
  }

  .sm\:pl-10p {
    padding-left: 10% !important;
  }

  .sm\:pt-10p {
    padding-top: 10% !important;
  }

  .sm\:pb-10p {
    padding-bottom: 10% !important;
  }

  .sm\:p-20p {
    padding: 20% !important;
  }

  .sm\:px-20p {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .sm\:py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }

  .sm\:pr-20p {
    padding-right: 20% !important;
  }

  .sm\:pl-20p {
    padding-left: 20% !important;
  }

  .sm\:pt-20p {
    padding-top: 20% !important;
  }

  .sm\:pb-20p {
    padding-bottom: 20% !important;
  }

  .sm\:p-30p {
    padding: 30% !important;
  }

  .sm\:px-30p {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }

  .sm\:py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }

  .sm\:pr-30p {
    padding-right: 30% !important;
  }

  .sm\:pl-30p {
    padding-left: 30% !important;
  }

  .sm\:pt-30p {
    padding-top: 30% !important;
  }

  .sm\:pb-30p {
    padding-bottom: 30% !important;
  }

  .sm\:p-40p {
    padding: 40% !important;
  }

  .sm\:px-40p {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }

  .sm\:py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }

  .sm\:pr-40p {
    padding-right: 40% !important;
  }

  .sm\:pl-40p {
    padding-left: 40% !important;
  }

  .sm\:pt-40p {
    padding-top: 40% !important;
  }

  .sm\:pb-40p {
    padding-bottom: 40% !important;
  }

  .sm\:p-50p {
    padding: 50% !important;
  }

  .sm\:px-50p {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }

  .sm\:py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }

  .sm\:pr-50p {
    padding-right: 50% !important;
  }

  .sm\:pl-50p {
    padding-left: 50% !important;
  }

  .sm\:pt-50p {
    padding-top: 50% !important;
  }

  .sm\:pb-50p {
    padding-bottom: 50% !important;
  }

  .sm\:p-60p {
    padding: 60% !important;
  }

  .sm\:px-60p {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }

  .sm\:py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }

  .sm\:pr-60p {
    padding-right: 60% !important;
  }

  .sm\:pl-60p {
    padding-left: 60% !important;
  }

  .sm\:pt-60p {
    padding-top: 60% !important;
  }

  .sm\:pb-60p {
    padding-bottom: 60% !important;
  }

  .sm\:p-70p {
    padding: 70% !important;
  }

  .sm\:px-70p {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }

  .sm\:py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }

  .sm\:pr-70p {
    padding-right: 70% !important;
  }

  .sm\:pl-70p {
    padding-left: 70% !important;
  }

  .sm\:pt-70p {
    padding-top: 70% !important;
  }

  .sm\:pb-70p {
    padding-bottom: 70% !important;
  }

  .sm\:p-80p {
    padding: 80% !important;
  }

  .sm\:px-80p {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }

  .sm\:py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }

  .sm\:pr-80p {
    padding-right: 80% !important;
  }

  .sm\:pl-80p {
    padding-left: 80% !important;
  }

  .sm\:pt-80p {
    padding-top: 80% !important;
  }

  .sm\:pb-80p {
    padding-bottom: 80% !important;
  }

  .sm\:p-90p {
    padding: 90% !important;
  }

  .sm\:px-90p {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }

  .sm\:py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }

  .sm\:pr-90p {
    padding-right: 90% !important;
  }

  .sm\:pl-90p {
    padding-left: 90% !important;
  }

  .sm\:pt-90p {
    padding-top: 90% !important;
  }

  .sm\:pb-90p {
    padding-bottom: 90% !important;
  }

  .sm\:p-100p {
    padding: 100% !important;
  }

  .sm\:px-100p {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }

  .sm\:py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }

  .sm\:pr-100p {
    padding-right: 100% !important;
  }

  .sm\:pl-100p {
    padding-left: 100% !important;
  }

  .sm\:pt-100p {
    padding-top: 100% !important;
  }

  .sm\:pb-100p {
    padding-bottom: 100% !important;
  }

  .sm\:m-none {
    margin: 0 !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:mr-0 {
    margin-right: 0 !important;
  }

  .sm\:ml-0 {
    margin-left: 0 !important;
  }

  .sm\:m-xs {
    margin: 0.25rem !important;
  }

  .sm\:my-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:mx-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:mt-xs {
    margin-top: 0.25rem !important;
  }

  .sm\:mb-xs {
    margin-bottom: 0.25rem !important;
  }

  .sm\:mr-xs {
    margin-right: 0.25rem !important;
  }

  .sm\:ml-xs {
    margin-left: 0.25rem !important;
  }

  .sm\:-m-xs {
    margin: -0.25rem !important;
  }

  .sm\:-mx-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:-mt-xs {
    margin-top: -0.25rem !important;
  }

  .sm\:-mb-xs {
    margin-bottom: -0.25rem !important;
  }

  .sm\:-ml-xs {
    margin-left: -0.25rem !important;
  }

  .sm\:-mr-xs {
    margin-right: -0.25rem !important;
  }

  .sm\:m-sm {
    margin: 0.5rem !important;
  }

  .sm\:my-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:mx-sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:mt-sm {
    margin-top: 0.5rem !important;
  }

  .sm\:mb-sm {
    margin-bottom: 0.5rem !important;
  }

  .sm\:mr-sm {
    margin-right: 0.5rem !important;
  }

  .sm\:ml-sm {
    margin-left: 0.5rem !important;
  }

  .sm\:-m-sm {
    margin: -0.5rem !important;
  }

  .sm\:-mx-sm {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:-mt-sm {
    margin-top: -0.5rem !important;
  }

  .sm\:-mb-sm {
    margin-bottom: -0.5rem !important;
  }

  .sm\:-ml-sm {
    margin-left: -0.5rem !important;
  }

  .sm\:-mr-sm {
    margin-right: -0.5rem !important;
  }

  .sm\:m-1 {
    margin: 1rem !important;
  }

  .sm\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:mt-1 {
    margin-top: 1rem !important;
  }

  .sm\:mb-1 {
    margin-bottom: 1rem !important;
  }

  .sm\:mr-1 {
    margin-right: 1rem !important;
  }

  .sm\:ml-1 {
    margin-left: 1rem !important;
  }

  .sm\:-m-1 {
    margin: -1rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-mt-1 {
    margin-top: -1rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -1rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -1rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -1rem !important;
  }

  .sm\:m-2 {
    margin: 2rem !important;
  }

  .sm\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:mt-2 {
    margin-top: 2rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: 2rem !important;
  }

  .sm\:mr-2 {
    margin-right: 2rem !important;
  }

  .sm\:ml-2 {
    margin-left: 2rem !important;
  }

  .sm\:-m-2 {
    margin: -2rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -2rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -2rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -2rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -2rem !important;
  }

  .sm\:m-3 {
    margin: 3rem !important;
  }

  .sm\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:mt-3 {
    margin-top: 3rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 3rem !important;
  }

  .sm\:mr-3 {
    margin-right: 3rem !important;
  }

  .sm\:ml-3 {
    margin-left: 3rem !important;
  }

  .sm\:-m-3 {
    margin: -3rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -3rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -3rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -3rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -3rem !important;
  }

  .sm\:m-4 {
    margin: 4rem !important;
  }

  .sm\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:mt-4 {
    margin-top: 4rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 4rem !important;
  }

  .sm\:mr-4 {
    margin-right: 4rem !important;
  }

  .sm\:ml-4 {
    margin-left: 4rem !important;
  }

  .sm\:-m-4 {
    margin: -4rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -4rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -4rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -4rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -4rem !important;
  }

  .sm\:m-5 {
    margin: 5rem !important;
  }

  .sm\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:mt-5 {
    margin-top: 5rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 5rem !important;
  }

  .sm\:mr-5 {
    margin-right: 5rem !important;
  }

  .sm\:ml-5 {
    margin-left: 5rem !important;
  }

  .sm\:-m-5 {
    margin: -5rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -5rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -5rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -5rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -5rem !important;
  }

  .sm\:m-6 {
    margin: 6rem !important;
  }

  .sm\:my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .sm\:mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .sm\:mt-6 {
    margin-top: 6rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 6rem !important;
  }

  .sm\:mr-6 {
    margin-right: 6rem !important;
  }

  .sm\:ml-6 {
    margin-left: 6rem !important;
  }

  .sm\:-m-6 {
    margin: -6rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -6rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -6rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -6rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -6rem !important;
  }

  .sm\:m-7 {
    margin: 7rem !important;
  }

  .sm\:my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .sm\:mx-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .sm\:mt-7 {
    margin-top: 7rem !important;
  }

  .sm\:mb-7 {
    margin-bottom: 7rem !important;
  }

  .sm\:mr-7 {
    margin-right: 7rem !important;
  }

  .sm\:ml-7 {
    margin-left: 7rem !important;
  }

  .sm\:-m-7 {
    margin: -7rem !important;
  }

  .sm\:-mx-7 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .sm\:-mt-7 {
    margin-top: -7rem !important;
  }

  .sm\:-mb-7 {
    margin-bottom: -7rem !important;
  }

  .sm\:-ml-7 {
    margin-left: -7rem !important;
  }

  .sm\:-mr-7 {
    margin-right: -7rem !important;
  }

  .sm\:m-8 {
    margin: 8rem !important;
  }

  .sm\:my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .sm\:mx-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .sm\:mt-8 {
    margin-top: 8rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 8rem !important;
  }

  .sm\:mr-8 {
    margin-right: 8rem !important;
  }

  .sm\:ml-8 {
    margin-left: 8rem !important;
  }

  .sm\:-m-8 {
    margin: -8rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -8rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -8rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -8rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -8rem !important;
  }

  .sm\:m-9 {
    margin: 9rem !important;
  }

  .sm\:my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .sm\:mx-9 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .sm\:mt-9 {
    margin-top: 9rem !important;
  }

  .sm\:mb-9 {
    margin-bottom: 9rem !important;
  }

  .sm\:mr-9 {
    margin-right: 9rem !important;
  }

  .sm\:ml-9 {
    margin-left: 9rem !important;
  }

  .sm\:-m-9 {
    margin: -9rem !important;
  }

  .sm\:-mx-9 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .sm\:-mt-9 {
    margin-top: -9rem !important;
  }

  .sm\:-mb-9 {
    margin-bottom: -9rem !important;
  }

  .sm\:-ml-9 {
    margin-left: -9rem !important;
  }

  .sm\:-mr-9 {
    margin-right: -9rem !important;
  }

  .sm\:m-10 {
    margin: 10rem !important;
  }

  .sm\:my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .sm\:mx-10 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .sm\:mt-10 {
    margin-top: 10rem !important;
  }

  .sm\:mb-10 {
    margin-bottom: 10rem !important;
  }

  .sm\:mr-10 {
    margin-right: 10rem !important;
  }

  .sm\:ml-10 {
    margin-left: 10rem !important;
  }

  .sm\:-m-10 {
    margin: -10rem !important;
  }

  .sm\:-mx-10 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .sm\:-mt-10 {
    margin-top: -10rem !important;
  }

  .sm\:-mb-10 {
    margin-bottom: -10rem !important;
  }

  .sm\:-ml-10 {
    margin-left: -10rem !important;
  }

  .sm\:-mr-10 {
    margin-right: -10rem !important;
  }

  .sm\:m-15 {
    margin: 15rem !important;
  }

  .sm\:my-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .sm\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .sm\:mt-15 {
    margin-top: 15rem !important;
  }

  .sm\:mb-15 {
    margin-bottom: 15rem !important;
  }

  .sm\:mr-15 {
    margin-right: 15rem !important;
  }

  .sm\:ml-15 {
    margin-left: 15rem !important;
  }

  .sm\:-m-15 {
    margin: -15rem !important;
  }

  .sm\:-mx-15 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .sm\:-mt-15 {
    margin-top: -15rem !important;
  }

  .sm\:-mb-15 {
    margin-bottom: -15rem !important;
  }

  .sm\:-ml-15 {
    margin-left: -15rem !important;
  }

  .sm\:-mr-15 {
    margin-right: -15rem !important;
  }

  .sm\:m-20 {
    margin: 20rem !important;
  }

  .sm\:my-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .sm\:mx-20 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .sm\:mt-20 {
    margin-top: 20rem !important;
  }

  .sm\:mb-20 {
    margin-bottom: 20rem !important;
  }

  .sm\:mr-20 {
    margin-right: 20rem !important;
  }

  .sm\:ml-20 {
    margin-left: 20rem !important;
  }

  .sm\:-m-20 {
    margin: -20rem !important;
  }

  .sm\:-mx-20 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .sm\:-mt-20 {
    margin-top: -20rem !important;
  }

  .sm\:-mb-20 {
    margin-bottom: -20rem !important;
  }

  .sm\:-ml-20 {
    margin-left: -20rem !important;
  }

  .sm\:-mr-20 {
    margin-right: -20rem !important;
  }

  .sm\:m-30 {
    margin: 30rem !important;
  }

  .sm\:my-30 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .sm\:mx-30 {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }

  .sm\:mt-30 {
    margin-top: 30rem !important;
  }

  .sm\:mb-30 {
    margin-bottom: 30rem !important;
  }

  .sm\:mr-30 {
    margin-right: 30rem !important;
  }

  .sm\:ml-30 {
    margin-left: 30rem !important;
  }

  .sm\:-m-30 {
    margin: -30rem !important;
  }

  .sm\:-mx-30 {
    margin-left: -30rem !important;
    margin-right: -30rem !important;
  }

  .sm\:-mt-30 {
    margin-top: -30rem !important;
  }

  .sm\:-mb-30 {
    margin-bottom: -30rem !important;
  }

  .sm\:-ml-30 {
    margin-left: -30rem !important;
  }

  .sm\:-mr-30 {
    margin-right: -30rem !important;
  }

  .sm\:m-40 {
    margin: 40rem !important;
  }

  .sm\:my-40 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important;
  }

  .sm\:mx-40 {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }

  .sm\:mt-40 {
    margin-top: 40rem !important;
  }

  .sm\:mb-40 {
    margin-bottom: 40rem !important;
  }

  .sm\:mr-40 {
    margin-right: 40rem !important;
  }

  .sm\:ml-40 {
    margin-left: 40rem !important;
  }

  .sm\:-m-40 {
    margin: -40rem !important;
  }

  .sm\:-mx-40 {
    margin-left: -40rem !important;
    margin-right: -40rem !important;
  }

  .sm\:-mt-40 {
    margin-top: -40rem !important;
  }

  .sm\:-mb-40 {
    margin-bottom: -40rem !important;
  }

  .sm\:-ml-40 {
    margin-left: -40rem !important;
  }

  .sm\:-mr-40 {
    margin-right: -40rem !important;
  }

  .sm\:m-50 {
    margin: 50rem !important;
  }

  .sm\:my-50 {
    margin-top: 50rem !important;
    margin-bottom: 50rem !important;
  }

  .sm\:mx-50 {
    margin-left: 50rem !important;
    margin-right: 50rem !important;
  }

  .sm\:mt-50 {
    margin-top: 50rem !important;
  }

  .sm\:mb-50 {
    margin-bottom: 50rem !important;
  }

  .sm\:mr-50 {
    margin-right: 50rem !important;
  }

  .sm\:ml-50 {
    margin-left: 50rem !important;
  }

  .sm\:-m-50 {
    margin: -50rem !important;
  }

  .sm\:-mx-50 {
    margin-left: -50rem !important;
    margin-right: -50rem !important;
  }

  .sm\:-mt-50 {
    margin-top: -50rem !important;
  }

  .sm\:-mb-50 {
    margin-bottom: -50rem !important;
  }

  .sm\:-ml-50 {
    margin-left: -50rem !important;
  }

  .sm\:-mr-50 {
    margin-right: -50rem !important;
  }

  .sm\:m-60 {
    margin: 60rem !important;
  }

  .sm\:my-60 {
    margin-top: 60rem !important;
    margin-bottom: 60rem !important;
  }

  .sm\:mx-60 {
    margin-left: 60rem !important;
    margin-right: 60rem !important;
  }

  .sm\:mt-60 {
    margin-top: 60rem !important;
  }

  .sm\:mb-60 {
    margin-bottom: 60rem !important;
  }

  .sm\:mr-60 {
    margin-right: 60rem !important;
  }

  .sm\:ml-60 {
    margin-left: 60rem !important;
  }

  .sm\:-m-60 {
    margin: -60rem !important;
  }

  .sm\:-mx-60 {
    margin-left: -60rem !important;
    margin-right: -60rem !important;
  }

  .sm\:-mt-60 {
    margin-top: -60rem !important;
  }

  .sm\:-mb-60 {
    margin-bottom: -60rem !important;
  }

  .sm\:-ml-60 {
    margin-left: -60rem !important;
  }

  .sm\:-mr-60 {
    margin-right: -60rem !important;
  }

  .sm\:m-70 {
    margin: 70rem !important;
  }

  .sm\:my-70 {
    margin-top: 70rem !important;
    margin-bottom: 70rem !important;
  }

  .sm\:mx-70 {
    margin-left: 70rem !important;
    margin-right: 70rem !important;
  }

  .sm\:mt-70 {
    margin-top: 70rem !important;
  }

  .sm\:mb-70 {
    margin-bottom: 70rem !important;
  }

  .sm\:mr-70 {
    margin-right: 70rem !important;
  }

  .sm\:ml-70 {
    margin-left: 70rem !important;
  }

  .sm\:-m-70 {
    margin: -70rem !important;
  }

  .sm\:-mx-70 {
    margin-left: -70rem !important;
    margin-right: -70rem !important;
  }

  .sm\:-mt-70 {
    margin-top: -70rem !important;
  }

  .sm\:-mb-70 {
    margin-bottom: -70rem !important;
  }

  .sm\:-ml-70 {
    margin-left: -70rem !important;
  }

  .sm\:-mr-70 {
    margin-right: -70rem !important;
  }

  .sm\:m-80 {
    margin: 80rem !important;
  }

  .sm\:my-80 {
    margin-top: 80rem !important;
    margin-bottom: 80rem !important;
  }

  .sm\:mx-80 {
    margin-left: 80rem !important;
    margin-right: 80rem !important;
  }

  .sm\:mt-80 {
    margin-top: 80rem !important;
  }

  .sm\:mb-80 {
    margin-bottom: 80rem !important;
  }

  .sm\:mr-80 {
    margin-right: 80rem !important;
  }

  .sm\:ml-80 {
    margin-left: 80rem !important;
  }

  .sm\:-m-80 {
    margin: -80rem !important;
  }

  .sm\:-mx-80 {
    margin-left: -80rem !important;
    margin-right: -80rem !important;
  }

  .sm\:-mt-80 {
    margin-top: -80rem !important;
  }

  .sm\:-mb-80 {
    margin-bottom: -80rem !important;
  }

  .sm\:-ml-80 {
    margin-left: -80rem !important;
  }

  .sm\:-mr-80 {
    margin-right: -80rem !important;
  }

  .sm\:m-90 {
    margin: 90rem !important;
  }

  .sm\:my-90 {
    margin-top: 90rem !important;
    margin-bottom: 90rem !important;
  }

  .sm\:mx-90 {
    margin-left: 90rem !important;
    margin-right: 90rem !important;
  }

  .sm\:mt-90 {
    margin-top: 90rem !important;
  }

  .sm\:mb-90 {
    margin-bottom: 90rem !important;
  }

  .sm\:mr-90 {
    margin-right: 90rem !important;
  }

  .sm\:ml-90 {
    margin-left: 90rem !important;
  }

  .sm\:-m-90 {
    margin: -90rem !important;
  }

  .sm\:-mx-90 {
    margin-left: -90rem !important;
    margin-right: -90rem !important;
  }

  .sm\:-mt-90 {
    margin-top: -90rem !important;
  }

  .sm\:-mb-90 {
    margin-bottom: -90rem !important;
  }

  .sm\:-ml-90 {
    margin-left: -90rem !important;
  }

  .sm\:-mr-90 {
    margin-right: -90rem !important;
  }

  .sm\:m-100 {
    margin: 100rem !important;
  }

  .sm\:my-100 {
    margin-top: 100rem !important;
    margin-bottom: 100rem !important;
  }

  .sm\:mx-100 {
    margin-left: 100rem !important;
    margin-right: 100rem !important;
  }

  .sm\:mt-100 {
    margin-top: 100rem !important;
  }

  .sm\:mb-100 {
    margin-bottom: 100rem !important;
  }

  .sm\:mr-100 {
    margin-right: 100rem !important;
  }

  .sm\:ml-100 {
    margin-left: 100rem !important;
  }

  .sm\:-m-100 {
    margin: -100rem !important;
  }

  .sm\:-mx-100 {
    margin-left: -100rem !important;
    margin-right: -100rem !important;
  }

  .sm\:-mt-100 {
    margin-top: -100rem !important;
  }

  .sm\:-mb-100 {
    margin-bottom: -100rem !important;
  }

  .sm\:-ml-100 {
    margin-left: -100rem !important;
  }

  .sm\:-mr-100 {
    margin-right: -100rem !important;
  }

  .sm\:m-10p {
    margin: 10% !important;
  }

  .sm\:my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

  .sm\:mx-10p {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .sm\:mt-10p {
    margin-top: 10% !important;
  }

  .sm\:mb-10p {
    margin-bottom: 10% !important;
  }

  .sm\:mr-10p {
    margin-right: 10% !important;
  }

  .sm\:ml-10p {
    margin-left: 10% !important;
  }

  .sm\:-m-10p {
    margin: -10% !important;
  }

  .sm\:-mx-10p {
    margin-left: -10% !important;
    margin-right: -10% !important;
  }

  .sm\:-mt-10p {
    margin-top: -10% !important;
  }

  .sm\:-mb-10p {
    margin-bottom: -10% !important;
  }

  .sm\:-ml-10p {
    margin-left: -10% !important;
  }

  .sm\:-mr-10p {
    margin-right: -10% !important;
  }

  .sm\:m-20p {
    margin: 20% !important;
  }

  .sm\:my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }

  .sm\:mx-20p {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .sm\:mt-20p {
    margin-top: 20% !important;
  }

  .sm\:mb-20p {
    margin-bottom: 20% !important;
  }

  .sm\:mr-20p {
    margin-right: 20% !important;
  }

  .sm\:ml-20p {
    margin-left: 20% !important;
  }

  .sm\:-m-20p {
    margin: -20% !important;
  }

  .sm\:-mx-20p {
    margin-left: -20% !important;
    margin-right: -20% !important;
  }

  .sm\:-mt-20p {
    margin-top: -20% !important;
  }

  .sm\:-mb-20p {
    margin-bottom: -20% !important;
  }

  .sm\:-ml-20p {
    margin-left: -20% !important;
  }

  .sm\:-mr-20p {
    margin-right: -20% !important;
  }

  .sm\:m-30p {
    margin: 30% !important;
  }

  .sm\:my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }

  .sm\:mx-30p {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }

  .sm\:mt-30p {
    margin-top: 30% !important;
  }

  .sm\:mb-30p {
    margin-bottom: 30% !important;
  }

  .sm\:mr-30p {
    margin-right: 30% !important;
  }

  .sm\:ml-30p {
    margin-left: 30% !important;
  }

  .sm\:-m-30p {
    margin: -30% !important;
  }

  .sm\:-mx-30p {
    margin-left: -30% !important;
    margin-right: -30% !important;
  }

  .sm\:-mt-30p {
    margin-top: -30% !important;
  }

  .sm\:-mb-30p {
    margin-bottom: -30% !important;
  }

  .sm\:-ml-30p {
    margin-left: -30% !important;
  }

  .sm\:-mr-30p {
    margin-right: -30% !important;
  }

  .sm\:m-40p {
    margin: 40% !important;
  }

  .sm\:my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }

  .sm\:mx-40p {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }

  .sm\:mt-40p {
    margin-top: 40% !important;
  }

  .sm\:mb-40p {
    margin-bottom: 40% !important;
  }

  .sm\:mr-40p {
    margin-right: 40% !important;
  }

  .sm\:ml-40p {
    margin-left: 40% !important;
  }

  .sm\:-m-40p {
    margin: -40% !important;
  }

  .sm\:-mx-40p {
    margin-left: -40% !important;
    margin-right: -40% !important;
  }

  .sm\:-mt-40p {
    margin-top: -40% !important;
  }

  .sm\:-mb-40p {
    margin-bottom: -40% !important;
  }

  .sm\:-ml-40p {
    margin-left: -40% !important;
  }

  .sm\:-mr-40p {
    margin-right: -40% !important;
  }

  .sm\:m-50p {
    margin: 50% !important;
  }

  .sm\:my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }

  .sm\:mx-50p {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }

  .sm\:mt-50p {
    margin-top: 50% !important;
  }

  .sm\:mb-50p {
    margin-bottom: 50% !important;
  }

  .sm\:mr-50p {
    margin-right: 50% !important;
  }

  .sm\:ml-50p {
    margin-left: 50% !important;
  }

  .sm\:-m-50p {
    margin: -50% !important;
  }

  .sm\:-mx-50p {
    margin-left: -50% !important;
    margin-right: -50% !important;
  }

  .sm\:-mt-50p {
    margin-top: -50% !important;
  }

  .sm\:-mb-50p {
    margin-bottom: -50% !important;
  }

  .sm\:-ml-50p {
    margin-left: -50% !important;
  }

  .sm\:-mr-50p {
    margin-right: -50% !important;
  }

  .sm\:m-60p {
    margin: 60% !important;
  }

  .sm\:my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }

  .sm\:mx-60p {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }

  .sm\:mt-60p {
    margin-top: 60% !important;
  }

  .sm\:mb-60p {
    margin-bottom: 60% !important;
  }

  .sm\:mr-60p {
    margin-right: 60% !important;
  }

  .sm\:ml-60p {
    margin-left: 60% !important;
  }

  .sm\:-m-60p {
    margin: -60% !important;
  }

  .sm\:-mx-60p {
    margin-left: -60% !important;
    margin-right: -60% !important;
  }

  .sm\:-mt-60p {
    margin-top: -60% !important;
  }

  .sm\:-mb-60p {
    margin-bottom: -60% !important;
  }

  .sm\:-ml-60p {
    margin-left: -60% !important;
  }

  .sm\:-mr-60p {
    margin-right: -60% !important;
  }

  .sm\:m-70p {
    margin: 70% !important;
  }

  .sm\:my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }

  .sm\:mx-70p {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }

  .sm\:mt-70p {
    margin-top: 70% !important;
  }

  .sm\:mb-70p {
    margin-bottom: 70% !important;
  }

  .sm\:mr-70p {
    margin-right: 70% !important;
  }

  .sm\:ml-70p {
    margin-left: 70% !important;
  }

  .sm\:-m-70p {
    margin: -70% !important;
  }

  .sm\:-mx-70p {
    margin-left: -70% !important;
    margin-right: -70% !important;
  }

  .sm\:-mt-70p {
    margin-top: -70% !important;
  }

  .sm\:-mb-70p {
    margin-bottom: -70% !important;
  }

  .sm\:-ml-70p {
    margin-left: -70% !important;
  }

  .sm\:-mr-70p {
    margin-right: -70% !important;
  }

  .sm\:m-80p {
    margin: 80% !important;
  }

  .sm\:my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }

  .sm\:mx-80p {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }

  .sm\:mt-80p {
    margin-top: 80% !important;
  }

  .sm\:mb-80p {
    margin-bottom: 80% !important;
  }

  .sm\:mr-80p {
    margin-right: 80% !important;
  }

  .sm\:ml-80p {
    margin-left: 80% !important;
  }

  .sm\:-m-80p {
    margin: -80% !important;
  }

  .sm\:-mx-80p {
    margin-left: -80% !important;
    margin-right: -80% !important;
  }

  .sm\:-mt-80p {
    margin-top: -80% !important;
  }

  .sm\:-mb-80p {
    margin-bottom: -80% !important;
  }

  .sm\:-ml-80p {
    margin-left: -80% !important;
  }

  .sm\:-mr-80p {
    margin-right: -80% !important;
  }

  .sm\:m-90p {
    margin: 90% !important;
  }

  .sm\:my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }

  .sm\:mx-90p {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }

  .sm\:mt-90p {
    margin-top: 90% !important;
  }

  .sm\:mb-90p {
    margin-bottom: 90% !important;
  }

  .sm\:mr-90p {
    margin-right: 90% !important;
  }

  .sm\:ml-90p {
    margin-left: 90% !important;
  }

  .sm\:-m-90p {
    margin: -90% !important;
  }

  .sm\:-mx-90p {
    margin-left: -90% !important;
    margin-right: -90% !important;
  }

  .sm\:-mt-90p {
    margin-top: -90% !important;
  }

  .sm\:-mb-90p {
    margin-bottom: -90% !important;
  }

  .sm\:-ml-90p {
    margin-left: -90% !important;
  }

  .sm\:-mr-90p {
    margin-right: -90% !important;
  }

  .sm\:m-100p {
    margin: 100% !important;
  }

  .sm\:my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }

  .sm\:mx-100p {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }

  .sm\:mt-100p {
    margin-top: 100% !important;
  }

  .sm\:mb-100p {
    margin-bottom: 100% !important;
  }

  .sm\:mr-100p {
    margin-right: 100% !important;
  }

  .sm\:ml-100p {
    margin-left: 100% !important;
  }

  .sm\:-m-100p {
    margin: -100% !important;
  }

  .sm\:-mx-100p {
    margin-left: -100% !important;
    margin-right: -100% !important;
  }

  .sm\:-mt-100p {
    margin-top: -100% !important;
  }

  .sm\:-mb-100p {
    margin-bottom: -100% !important;
  }

  .sm\:-ml-100p {
    margin-left: -100% !important;
  }

  .sm\:-mr-100p {
    margin-right: -100% !important;
  }

  .sm\:fixed {
    position: fixed !important;
  }

  .sm\:absolute {
    position: absolute !important;
  }

  .sm\:relative {
    position: relative !important;
  }

  .sm\:sticky {
    position: sticky !important;
  }

  .sm\:top-0 {
    top: 0 !important;
  }

  .sm\:right-0 {
    right: 0 !important;
  }

  .sm\:bottom-0 {
    bottom: 0 !important;
  }

  .sm\:left-0 {
    left: 0 !important;
  }

  .sm\:trbl-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-10 {
    z-index: 10 !important;
  }

  .sm\:z-20 {
    z-index: 20 !important;
  }

  .sm\:z-100 {
    z-index: 100 !important;
  }

  .-sm\:z-1 {
    z-index: -1 !important;
  }

  .sm\:border-0 {
    border: 0 !important;
  }

  .sm\:round {
    border-radius: 100vw !important;
  }

  .sm\:rounded-0 {
    border-radius: 0 !important;
  }

  .sm\:rounded-xxl {
    border-radius: 2rem !important;
  }

  .sm\:rounded-xl {
    border-radius: 1rem !important;
  }

  .sm\:rounded-lg {
    border-radius: 0.75rem !important;
  }

  .sm\:rounded-md {
    border-radius: 0.5rem !important;
  }

  .sm\:rounded-sm {
    border-radius: 0.25rem !important;
  }

  .sm\:rounded-xs {
    border-radius: 0.125rem !important;
  }
}
@media (min-width: 1024px) {
  .md\:flex {
    display: flex !important;
  }

  .md\:inline-flex {
    display: inline-flex  !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:hidden,
.md\:dnone {
    display: none !important;
  }

  .md\:flex-column {
    flex-direction: column;
  }

  .md\:flex-column.reverse,
.md\:column-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-1 {
    flex: 1 1 0%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:row-reverse,
.md\:reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:items-center,
.md\:y-center,
.md\:vertical-center {
    align-items: center;
  }

  .md\:flex-start,
.md\:items-start {
    align-items: flex-start;
  }

  .md\:flex-end,
.md\:items-end {
    align-items: flex-end;
  }

  .md\:center {
    align-items: center;
    text-align: center;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-center,
.md\:x-center,
.md\:horizontal-center {
    justify-content: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:space-between,
.md\:justify-between {
    justify-content: space-between;
  }

  .md\:space-around,
.md\:justify-around {
    justify-content: space-around;
  }

  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:text-right {
    text-align: right !important;
  }

  .md\:text-uppercase {
    text-transform: uppercase !important;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }

  .md\:font-size-xxl {
    font-size: 5rem !important;
  }

  .md\:font-size-xl {
    font-size: 2rem !important;
  }

  .md\:font-size-lg {
    font-size: 1.5rem !important;
  }

  .md\:font-size-md {
    font-size: 1.25rem !important;
  }

  .md\:font-size-default {
    font-size: 1rem !important;
  }

  .md\:font-size-sm {
    font-size: 0.75rem !important;
  }

  .md\:font-size-xs {
    font-size: 0.5rem !important;
  }

  .md\:w-1\/4,
.md\:w-25 {
    width: 25% !important;
  }

  .md\:w-1\/3,
.md\:w-33 {
    width: 33.33% !important;
  }

  .md\:w-1\/2,
.md\:w-2\/4,
.md\:w-half,
.md\:w-50 {
    width: 50% !important;
  }

  .md\:w-2\/3,
.md\:w-66 {
    width: 66.66% !important;
  }

  .md\:w-3\/4,
.md\:w-75 {
    width: 75% !important;
  }

  .md\:w-100,
.md\:w-full {
    width: 100% !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:mxw-xs {
    max-width: 600px !important;
  }

  .md\:mxw-sm {
    max-width: 782px !important;
  }

  .md\:mxw-md {
    max-width: 1024px !important;
  }

  .md\:mxw-lg {
    max-width: 1220px !important;
  }

  .md\:mxw-xl {
    max-width: 1460px !important;
  }

  .md\:h-100 {
    height: 100% !important;
  }

  .md\:h-100vh {
    height: 100vh !important;
  }

  .md\:h-50 {
    height: 50% !important;
  }

  .md\:h-50vh {
    height: 50vh !important;
  }

  .md\:h-inherit {
    height: inherit !important;
  }

  .md\:color-primary {
    color: #25373e !important;
  }

  .md\:bg-primary {
    background-color: #25373e !important;
  }

  .md\:color-secondary {
    color: #e9a844 !important;
  }

  .md\:bg-secondary {
    background-color: #e9a844 !important;
  }

  .md\:color-tertiary {
    color: #3d6574 !important;
  }

  .md\:bg-tertiary {
    background-color: #3d6574 !important;
  }

  .md\:color-white {
    color: #fff !important;
  }

  .md\:bg-white {
    background-color: #fff !important;
  }

  .md\:color-light-primary {
    color: #25373eed !important;
  }

  .md\:bg-light-primary {
    background-color: #25373eed !important;
  }

  .md\:color-extra-light-primary {
    color: #25383d0f !important;
  }

  .md\:bg-extra-light-primary {
    background-color: #25383d0f !important;
  }

  .md\:color-light-secondary {
    color: #e9a8449c !important;
  }

  .md\:bg-light-secondary {
    background-color: #e9a8449c !important;
  }

  .md\:p-none {
    padding: 0 !important;
  }

  .md\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .md\:pr-0 {
    padding-right: 0 !important;
  }

  .md\:pl-0 {
    padding-left: 0 !important;
  }

  .md\:pt-0 {
    padding-top: 0 !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:p-xs {
    padding: 0.25rem !important;
  }

  .md\:px-xs {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:py-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:pr-xs {
    padding-right: 0.25rem !important;
  }

  .md\:pl-xs {
    padding-left: 0.25rem !important;
  }

  .md\:pt-xs {
    padding-top: 0.25rem !important;
  }

  .md\:pb-xs {
    padding-bottom: 0.25rem !important;
  }

  .md\:p-sm {
    padding: 0.5rem !important;
  }

  .md\:px-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:py-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:pr-sm {
    padding-right: 0.5rem !important;
  }

  .md\:pl-sm {
    padding-left: 0.5rem !important;
  }

  .md\:pt-sm {
    padding-top: 0.5rem !important;
  }

  .md\:pb-sm {
    padding-bottom: 0.5rem !important;
  }

  .md\:p-1 {
    padding: 1rem !important;
  }

  .md\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:pr-1 {
    padding-right: 1rem !important;
  }

  .md\:pl-1 {
    padding-left: 1rem !important;
  }

  .md\:pt-1 {
    padding-top: 1rem !important;
  }

  .md\:pb-1 {
    padding-bottom: 1rem !important;
  }

  .md\:p-2 {
    padding: 2rem !important;
  }

  .md\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:pr-2 {
    padding-right: 2rem !important;
  }

  .md\:pl-2 {
    padding-left: 2rem !important;
  }

  .md\:pt-2 {
    padding-top: 2rem !important;
  }

  .md\:pb-2 {
    padding-bottom: 2rem !important;
  }

  .md\:p-3 {
    padding: 3rem !important;
  }

  .md\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:pr-3 {
    padding-right: 3rem !important;
  }

  .md\:pl-3 {
    padding-left: 3rem !important;
  }

  .md\:pt-3 {
    padding-top: 3rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 3rem !important;
  }

  .md\:p-4 {
    padding: 4rem !important;
  }

  .md\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:pr-4 {
    padding-right: 4rem !important;
  }

  .md\:pl-4 {
    padding-left: 4rem !important;
  }

  .md\:pt-4 {
    padding-top: 4rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 4rem !important;
  }

  .md\:p-5 {
    padding: 5rem !important;
  }

  .md\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .md\:pr-5 {
    padding-right: 5rem !important;
  }

  .md\:pl-5 {
    padding-left: 5rem !important;
  }

  .md\:pt-5 {
    padding-top: 5rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 5rem !important;
  }

  .md\:p-6 {
    padding: 6rem !important;
  }

  .md\:px-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .md\:py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .md\:pr-6 {
    padding-right: 6rem !important;
  }

  .md\:pl-6 {
    padding-left: 6rem !important;
  }

  .md\:pt-6 {
    padding-top: 6rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 6rem !important;
  }

  .md\:p-7 {
    padding: 7rem !important;
  }

  .md\:px-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .md\:py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .md\:pr-7 {
    padding-right: 7rem !important;
  }

  .md\:pl-7 {
    padding-left: 7rem !important;
  }

  .md\:pt-7 {
    padding-top: 7rem !important;
  }

  .md\:pb-7 {
    padding-bottom: 7rem !important;
  }

  .md\:p-8 {
    padding: 8rem !important;
  }

  .md\:px-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .md\:py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .md\:pr-8 {
    padding-right: 8rem !important;
  }

  .md\:pl-8 {
    padding-left: 8rem !important;
  }

  .md\:pt-8 {
    padding-top: 8rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 8rem !important;
  }

  .md\:p-9 {
    padding: 9rem !important;
  }

  .md\:px-9 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .md\:py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .md\:pr-9 {
    padding-right: 9rem !important;
  }

  .md\:pl-9 {
    padding-left: 9rem !important;
  }

  .md\:pt-9 {
    padding-top: 9rem !important;
  }

  .md\:pb-9 {
    padding-bottom: 9rem !important;
  }

  .md\:p-10 {
    padding: 10rem !important;
  }

  .md\:px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .md\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .md\:pr-10 {
    padding-right: 10rem !important;
  }

  .md\:pl-10 {
    padding-left: 10rem !important;
  }

  .md\:pt-10 {
    padding-top: 10rem !important;
  }

  .md\:pb-10 {
    padding-bottom: 10rem !important;
  }

  .md\:p-15 {
    padding: 15rem !important;
  }

  .md\:px-15 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .md\:py-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .md\:pr-15 {
    padding-right: 15rem !important;
  }

  .md\:pl-15 {
    padding-left: 15rem !important;
  }

  .md\:pt-15 {
    padding-top: 15rem !important;
  }

  .md\:pb-15 {
    padding-bottom: 15rem !important;
  }

  .md\:p-20 {
    padding: 20rem !important;
  }

  .md\:px-20 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .md\:py-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .md\:pr-20 {
    padding-right: 20rem !important;
  }

  .md\:pl-20 {
    padding-left: 20rem !important;
  }

  .md\:pt-20 {
    padding-top: 20rem !important;
  }

  .md\:pb-20 {
    padding-bottom: 20rem !important;
  }

  .md\:p-30 {
    padding: 30rem !important;
  }

  .md\:px-30 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
  }

  .md\:py-30 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .md\:pr-30 {
    padding-right: 30rem !important;
  }

  .md\:pl-30 {
    padding-left: 30rem !important;
  }

  .md\:pt-30 {
    padding-top: 30rem !important;
  }

  .md\:pb-30 {
    padding-bottom: 30rem !important;
  }

  .md\:p-40 {
    padding: 40rem !important;
  }

  .md\:px-40 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
  }

  .md\:py-40 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }

  .md\:pr-40 {
    padding-right: 40rem !important;
  }

  .md\:pl-40 {
    padding-left: 40rem !important;
  }

  .md\:pt-40 {
    padding-top: 40rem !important;
  }

  .md\:pb-40 {
    padding-bottom: 40rem !important;
  }

  .md\:p-50 {
    padding: 50rem !important;
  }

  .md\:px-50 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
  }

  .md\:py-50 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
  }

  .md\:pr-50 {
    padding-right: 50rem !important;
  }

  .md\:pl-50 {
    padding-left: 50rem !important;
  }

  .md\:pt-50 {
    padding-top: 50rem !important;
  }

  .md\:pb-50 {
    padding-bottom: 50rem !important;
  }

  .md\:p-60 {
    padding: 60rem !important;
  }

  .md\:px-60 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
  }

  .md\:py-60 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
  }

  .md\:pr-60 {
    padding-right: 60rem !important;
  }

  .md\:pl-60 {
    padding-left: 60rem !important;
  }

  .md\:pt-60 {
    padding-top: 60rem !important;
  }

  .md\:pb-60 {
    padding-bottom: 60rem !important;
  }

  .md\:p-70 {
    padding: 70rem !important;
  }

  .md\:px-70 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
  }

  .md\:py-70 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
  }

  .md\:pr-70 {
    padding-right: 70rem !important;
  }

  .md\:pl-70 {
    padding-left: 70rem !important;
  }

  .md\:pt-70 {
    padding-top: 70rem !important;
  }

  .md\:pb-70 {
    padding-bottom: 70rem !important;
  }

  .md\:p-80 {
    padding: 80rem !important;
  }

  .md\:px-80 {
    padding-left: 80rem !important;
    padding-right: 80rem !important;
  }

  .md\:py-80 {
    padding-top: 80rem !important;
    padding-bottom: 80rem !important;
  }

  .md\:pr-80 {
    padding-right: 80rem !important;
  }

  .md\:pl-80 {
    padding-left: 80rem !important;
  }

  .md\:pt-80 {
    padding-top: 80rem !important;
  }

  .md\:pb-80 {
    padding-bottom: 80rem !important;
  }

  .md\:p-90 {
    padding: 90rem !important;
  }

  .md\:px-90 {
    padding-left: 90rem !important;
    padding-right: 90rem !important;
  }

  .md\:py-90 {
    padding-top: 90rem !important;
    padding-bottom: 90rem !important;
  }

  .md\:pr-90 {
    padding-right: 90rem !important;
  }

  .md\:pl-90 {
    padding-left: 90rem !important;
  }

  .md\:pt-90 {
    padding-top: 90rem !important;
  }

  .md\:pb-90 {
    padding-bottom: 90rem !important;
  }

  .md\:p-100 {
    padding: 100rem !important;
  }

  .md\:px-100 {
    padding-left: 100rem !important;
    padding-right: 100rem !important;
  }

  .md\:py-100 {
    padding-top: 100rem !important;
    padding-bottom: 100rem !important;
  }

  .md\:pr-100 {
    padding-right: 100rem !important;
  }

  .md\:pl-100 {
    padding-left: 100rem !important;
  }

  .md\:pt-100 {
    padding-top: 100rem !important;
  }

  .md\:pb-100 {
    padding-bottom: 100rem !important;
  }

  .md\:p-10p {
    padding: 10% !important;
  }

  .md\:px-10p {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .md\:py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }

  .md\:pr-10p {
    padding-right: 10% !important;
  }

  .md\:pl-10p {
    padding-left: 10% !important;
  }

  .md\:pt-10p {
    padding-top: 10% !important;
  }

  .md\:pb-10p {
    padding-bottom: 10% !important;
  }

  .md\:p-20p {
    padding: 20% !important;
  }

  .md\:px-20p {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .md\:py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }

  .md\:pr-20p {
    padding-right: 20% !important;
  }

  .md\:pl-20p {
    padding-left: 20% !important;
  }

  .md\:pt-20p {
    padding-top: 20% !important;
  }

  .md\:pb-20p {
    padding-bottom: 20% !important;
  }

  .md\:p-30p {
    padding: 30% !important;
  }

  .md\:px-30p {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }

  .md\:py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }

  .md\:pr-30p {
    padding-right: 30% !important;
  }

  .md\:pl-30p {
    padding-left: 30% !important;
  }

  .md\:pt-30p {
    padding-top: 30% !important;
  }

  .md\:pb-30p {
    padding-bottom: 30% !important;
  }

  .md\:p-40p {
    padding: 40% !important;
  }

  .md\:px-40p {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }

  .md\:py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }

  .md\:pr-40p {
    padding-right: 40% !important;
  }

  .md\:pl-40p {
    padding-left: 40% !important;
  }

  .md\:pt-40p {
    padding-top: 40% !important;
  }

  .md\:pb-40p {
    padding-bottom: 40% !important;
  }

  .md\:p-50p {
    padding: 50% !important;
  }

  .md\:px-50p {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }

  .md\:py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }

  .md\:pr-50p {
    padding-right: 50% !important;
  }

  .md\:pl-50p {
    padding-left: 50% !important;
  }

  .md\:pt-50p {
    padding-top: 50% !important;
  }

  .md\:pb-50p {
    padding-bottom: 50% !important;
  }

  .md\:p-60p {
    padding: 60% !important;
  }

  .md\:px-60p {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }

  .md\:py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }

  .md\:pr-60p {
    padding-right: 60% !important;
  }

  .md\:pl-60p {
    padding-left: 60% !important;
  }

  .md\:pt-60p {
    padding-top: 60% !important;
  }

  .md\:pb-60p {
    padding-bottom: 60% !important;
  }

  .md\:p-70p {
    padding: 70% !important;
  }

  .md\:px-70p {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }

  .md\:py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }

  .md\:pr-70p {
    padding-right: 70% !important;
  }

  .md\:pl-70p {
    padding-left: 70% !important;
  }

  .md\:pt-70p {
    padding-top: 70% !important;
  }

  .md\:pb-70p {
    padding-bottom: 70% !important;
  }

  .md\:p-80p {
    padding: 80% !important;
  }

  .md\:px-80p {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }

  .md\:py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }

  .md\:pr-80p {
    padding-right: 80% !important;
  }

  .md\:pl-80p {
    padding-left: 80% !important;
  }

  .md\:pt-80p {
    padding-top: 80% !important;
  }

  .md\:pb-80p {
    padding-bottom: 80% !important;
  }

  .md\:p-90p {
    padding: 90% !important;
  }

  .md\:px-90p {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }

  .md\:py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }

  .md\:pr-90p {
    padding-right: 90% !important;
  }

  .md\:pl-90p {
    padding-left: 90% !important;
  }

  .md\:pt-90p {
    padding-top: 90% !important;
  }

  .md\:pb-90p {
    padding-bottom: 90% !important;
  }

  .md\:p-100p {
    padding: 100% !important;
  }

  .md\:px-100p {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }

  .md\:py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }

  .md\:pr-100p {
    padding-right: 100% !important;
  }

  .md\:pl-100p {
    padding-left: 100% !important;
  }

  .md\:pt-100p {
    padding-top: 100% !important;
  }

  .md\:pb-100p {
    padding-bottom: 100% !important;
  }

  .md\:m-none {
    margin: 0 !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:m-0 {
    margin: 0 !important;
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:m-xs {
    margin: 0.25rem !important;
  }

  .md\:my-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:mx-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:mt-xs {
    margin-top: 0.25rem !important;
  }

  .md\:mb-xs {
    margin-bottom: 0.25rem !important;
  }

  .md\:mr-xs {
    margin-right: 0.25rem !important;
  }

  .md\:ml-xs {
    margin-left: 0.25rem !important;
  }

  .md\:-m-xs {
    margin: -0.25rem !important;
  }

  .md\:-mx-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:-mt-xs {
    margin-top: -0.25rem !important;
  }

  .md\:-mb-xs {
    margin-bottom: -0.25rem !important;
  }

  .md\:-ml-xs {
    margin-left: -0.25rem !important;
  }

  .md\:-mr-xs {
    margin-right: -0.25rem !important;
  }

  .md\:m-sm {
    margin: 0.5rem !important;
  }

  .md\:my-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:mx-sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:mt-sm {
    margin-top: 0.5rem !important;
  }

  .md\:mb-sm {
    margin-bottom: 0.5rem !important;
  }

  .md\:mr-sm {
    margin-right: 0.5rem !important;
  }

  .md\:ml-sm {
    margin-left: 0.5rem !important;
  }

  .md\:-m-sm {
    margin: -0.5rem !important;
  }

  .md\:-mx-sm {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:-mt-sm {
    margin-top: -0.5rem !important;
  }

  .md\:-mb-sm {
    margin-bottom: -0.5rem !important;
  }

  .md\:-ml-sm {
    margin-left: -0.5rem !important;
  }

  .md\:-mr-sm {
    margin-right: -0.5rem !important;
  }

  .md\:m-1 {
    margin: 1rem !important;
  }

  .md\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:mt-1 {
    margin-top: 1rem !important;
  }

  .md\:mb-1 {
    margin-bottom: 1rem !important;
  }

  .md\:mr-1 {
    margin-right: 1rem !important;
  }

  .md\:ml-1 {
    margin-left: 1rem !important;
  }

  .md\:-m-1 {
    margin: -1rem !important;
  }

  .md\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-mt-1 {
    margin-top: -1rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -1rem !important;
  }

  .md\:-ml-1 {
    margin-left: -1rem !important;
  }

  .md\:-mr-1 {
    margin-right: -1rem !important;
  }

  .md\:m-2 {
    margin: 2rem !important;
  }

  .md\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:mt-2 {
    margin-top: 2rem !important;
  }

  .md\:mb-2 {
    margin-bottom: 2rem !important;
  }

  .md\:mr-2 {
    margin-right: 2rem !important;
  }

  .md\:ml-2 {
    margin-left: 2rem !important;
  }

  .md\:-m-2 {
    margin: -2rem !important;
  }

  .md\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-mt-2 {
    margin-top: -2rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -2rem !important;
  }

  .md\:-ml-2 {
    margin-left: -2rem !important;
  }

  .md\:-mr-2 {
    margin-right: -2rem !important;
  }

  .md\:m-3 {
    margin: 3rem !important;
  }

  .md\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:mt-3 {
    margin-top: 3rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 3rem !important;
  }

  .md\:mr-3 {
    margin-right: 3rem !important;
  }

  .md\:ml-3 {
    margin-left: 3rem !important;
  }

  .md\:-m-3 {
    margin: -3rem !important;
  }

  .md\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-mt-3 {
    margin-top: -3rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -3rem !important;
  }

  .md\:-ml-3 {
    margin-left: -3rem !important;
  }

  .md\:-mr-3 {
    margin-right: -3rem !important;
  }

  .md\:m-4 {
    margin: 4rem !important;
  }

  .md\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:mt-4 {
    margin-top: 4rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 4rem !important;
  }

  .md\:mr-4 {
    margin-right: 4rem !important;
  }

  .md\:ml-4 {
    margin-left: 4rem !important;
  }

  .md\:-m-4 {
    margin: -4rem !important;
  }

  .md\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-mt-4 {
    margin-top: -4rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -4rem !important;
  }

  .md\:-ml-4 {
    margin-left: -4rem !important;
  }

  .md\:-mr-4 {
    margin-right: -4rem !important;
  }

  .md\:m-5 {
    margin: 5rem !important;
  }

  .md\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:mt-5 {
    margin-top: 5rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 5rem !important;
  }

  .md\:mr-5 {
    margin-right: 5rem !important;
  }

  .md\:ml-5 {
    margin-left: 5rem !important;
  }

  .md\:-m-5 {
    margin: -5rem !important;
  }

  .md\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:-mt-5 {
    margin-top: -5rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -5rem !important;
  }

  .md\:-ml-5 {
    margin-left: -5rem !important;
  }

  .md\:-mr-5 {
    margin-right: -5rem !important;
  }

  .md\:m-6 {
    margin: 6rem !important;
  }

  .md\:my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .md\:mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .md\:mt-6 {
    margin-top: 6rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 6rem !important;
  }

  .md\:mr-6 {
    margin-right: 6rem !important;
  }

  .md\:ml-6 {
    margin-left: 6rem !important;
  }

  .md\:-m-6 {
    margin: -6rem !important;
  }

  .md\:-mx-6 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .md\:-mt-6 {
    margin-top: -6rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -6rem !important;
  }

  .md\:-ml-6 {
    margin-left: -6rem !important;
  }

  .md\:-mr-6 {
    margin-right: -6rem !important;
  }

  .md\:m-7 {
    margin: 7rem !important;
  }

  .md\:my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .md\:mx-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .md\:mt-7 {
    margin-top: 7rem !important;
  }

  .md\:mb-7 {
    margin-bottom: 7rem !important;
  }

  .md\:mr-7 {
    margin-right: 7rem !important;
  }

  .md\:ml-7 {
    margin-left: 7rem !important;
  }

  .md\:-m-7 {
    margin: -7rem !important;
  }

  .md\:-mx-7 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .md\:-mt-7 {
    margin-top: -7rem !important;
  }

  .md\:-mb-7 {
    margin-bottom: -7rem !important;
  }

  .md\:-ml-7 {
    margin-left: -7rem !important;
  }

  .md\:-mr-7 {
    margin-right: -7rem !important;
  }

  .md\:m-8 {
    margin: 8rem !important;
  }

  .md\:my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .md\:mx-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .md\:mt-8 {
    margin-top: 8rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 8rem !important;
  }

  .md\:mr-8 {
    margin-right: 8rem !important;
  }

  .md\:ml-8 {
    margin-left: 8rem !important;
  }

  .md\:-m-8 {
    margin: -8rem !important;
  }

  .md\:-mx-8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .md\:-mt-8 {
    margin-top: -8rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -8rem !important;
  }

  .md\:-ml-8 {
    margin-left: -8rem !important;
  }

  .md\:-mr-8 {
    margin-right: -8rem !important;
  }

  .md\:m-9 {
    margin: 9rem !important;
  }

  .md\:my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .md\:mx-9 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .md\:mt-9 {
    margin-top: 9rem !important;
  }

  .md\:mb-9 {
    margin-bottom: 9rem !important;
  }

  .md\:mr-9 {
    margin-right: 9rem !important;
  }

  .md\:ml-9 {
    margin-left: 9rem !important;
  }

  .md\:-m-9 {
    margin: -9rem !important;
  }

  .md\:-mx-9 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .md\:-mt-9 {
    margin-top: -9rem !important;
  }

  .md\:-mb-9 {
    margin-bottom: -9rem !important;
  }

  .md\:-ml-9 {
    margin-left: -9rem !important;
  }

  .md\:-mr-9 {
    margin-right: -9rem !important;
  }

  .md\:m-10 {
    margin: 10rem !important;
  }

  .md\:my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .md\:mx-10 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .md\:mt-10 {
    margin-top: 10rem !important;
  }

  .md\:mb-10 {
    margin-bottom: 10rem !important;
  }

  .md\:mr-10 {
    margin-right: 10rem !important;
  }

  .md\:ml-10 {
    margin-left: 10rem !important;
  }

  .md\:-m-10 {
    margin: -10rem !important;
  }

  .md\:-mx-10 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .md\:-mt-10 {
    margin-top: -10rem !important;
  }

  .md\:-mb-10 {
    margin-bottom: -10rem !important;
  }

  .md\:-ml-10 {
    margin-left: -10rem !important;
  }

  .md\:-mr-10 {
    margin-right: -10rem !important;
  }

  .md\:m-15 {
    margin: 15rem !important;
  }

  .md\:my-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .md\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .md\:mt-15 {
    margin-top: 15rem !important;
  }

  .md\:mb-15 {
    margin-bottom: 15rem !important;
  }

  .md\:mr-15 {
    margin-right: 15rem !important;
  }

  .md\:ml-15 {
    margin-left: 15rem !important;
  }

  .md\:-m-15 {
    margin: -15rem !important;
  }

  .md\:-mx-15 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .md\:-mt-15 {
    margin-top: -15rem !important;
  }

  .md\:-mb-15 {
    margin-bottom: -15rem !important;
  }

  .md\:-ml-15 {
    margin-left: -15rem !important;
  }

  .md\:-mr-15 {
    margin-right: -15rem !important;
  }

  .md\:m-20 {
    margin: 20rem !important;
  }

  .md\:my-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .md\:mx-20 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .md\:mt-20 {
    margin-top: 20rem !important;
  }

  .md\:mb-20 {
    margin-bottom: 20rem !important;
  }

  .md\:mr-20 {
    margin-right: 20rem !important;
  }

  .md\:ml-20 {
    margin-left: 20rem !important;
  }

  .md\:-m-20 {
    margin: -20rem !important;
  }

  .md\:-mx-20 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .md\:-mt-20 {
    margin-top: -20rem !important;
  }

  .md\:-mb-20 {
    margin-bottom: -20rem !important;
  }

  .md\:-ml-20 {
    margin-left: -20rem !important;
  }

  .md\:-mr-20 {
    margin-right: -20rem !important;
  }

  .md\:m-30 {
    margin: 30rem !important;
  }

  .md\:my-30 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .md\:mx-30 {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }

  .md\:mt-30 {
    margin-top: 30rem !important;
  }

  .md\:mb-30 {
    margin-bottom: 30rem !important;
  }

  .md\:mr-30 {
    margin-right: 30rem !important;
  }

  .md\:ml-30 {
    margin-left: 30rem !important;
  }

  .md\:-m-30 {
    margin: -30rem !important;
  }

  .md\:-mx-30 {
    margin-left: -30rem !important;
    margin-right: -30rem !important;
  }

  .md\:-mt-30 {
    margin-top: -30rem !important;
  }

  .md\:-mb-30 {
    margin-bottom: -30rem !important;
  }

  .md\:-ml-30 {
    margin-left: -30rem !important;
  }

  .md\:-mr-30 {
    margin-right: -30rem !important;
  }

  .md\:m-40 {
    margin: 40rem !important;
  }

  .md\:my-40 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important;
  }

  .md\:mx-40 {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }

  .md\:mt-40 {
    margin-top: 40rem !important;
  }

  .md\:mb-40 {
    margin-bottom: 40rem !important;
  }

  .md\:mr-40 {
    margin-right: 40rem !important;
  }

  .md\:ml-40 {
    margin-left: 40rem !important;
  }

  .md\:-m-40 {
    margin: -40rem !important;
  }

  .md\:-mx-40 {
    margin-left: -40rem !important;
    margin-right: -40rem !important;
  }

  .md\:-mt-40 {
    margin-top: -40rem !important;
  }

  .md\:-mb-40 {
    margin-bottom: -40rem !important;
  }

  .md\:-ml-40 {
    margin-left: -40rem !important;
  }

  .md\:-mr-40 {
    margin-right: -40rem !important;
  }

  .md\:m-50 {
    margin: 50rem !important;
  }

  .md\:my-50 {
    margin-top: 50rem !important;
    margin-bottom: 50rem !important;
  }

  .md\:mx-50 {
    margin-left: 50rem !important;
    margin-right: 50rem !important;
  }

  .md\:mt-50 {
    margin-top: 50rem !important;
  }

  .md\:mb-50 {
    margin-bottom: 50rem !important;
  }

  .md\:mr-50 {
    margin-right: 50rem !important;
  }

  .md\:ml-50 {
    margin-left: 50rem !important;
  }

  .md\:-m-50 {
    margin: -50rem !important;
  }

  .md\:-mx-50 {
    margin-left: -50rem !important;
    margin-right: -50rem !important;
  }

  .md\:-mt-50 {
    margin-top: -50rem !important;
  }

  .md\:-mb-50 {
    margin-bottom: -50rem !important;
  }

  .md\:-ml-50 {
    margin-left: -50rem !important;
  }

  .md\:-mr-50 {
    margin-right: -50rem !important;
  }

  .md\:m-60 {
    margin: 60rem !important;
  }

  .md\:my-60 {
    margin-top: 60rem !important;
    margin-bottom: 60rem !important;
  }

  .md\:mx-60 {
    margin-left: 60rem !important;
    margin-right: 60rem !important;
  }

  .md\:mt-60 {
    margin-top: 60rem !important;
  }

  .md\:mb-60 {
    margin-bottom: 60rem !important;
  }

  .md\:mr-60 {
    margin-right: 60rem !important;
  }

  .md\:ml-60 {
    margin-left: 60rem !important;
  }

  .md\:-m-60 {
    margin: -60rem !important;
  }

  .md\:-mx-60 {
    margin-left: -60rem !important;
    margin-right: -60rem !important;
  }

  .md\:-mt-60 {
    margin-top: -60rem !important;
  }

  .md\:-mb-60 {
    margin-bottom: -60rem !important;
  }

  .md\:-ml-60 {
    margin-left: -60rem !important;
  }

  .md\:-mr-60 {
    margin-right: -60rem !important;
  }

  .md\:m-70 {
    margin: 70rem !important;
  }

  .md\:my-70 {
    margin-top: 70rem !important;
    margin-bottom: 70rem !important;
  }

  .md\:mx-70 {
    margin-left: 70rem !important;
    margin-right: 70rem !important;
  }

  .md\:mt-70 {
    margin-top: 70rem !important;
  }

  .md\:mb-70 {
    margin-bottom: 70rem !important;
  }

  .md\:mr-70 {
    margin-right: 70rem !important;
  }

  .md\:ml-70 {
    margin-left: 70rem !important;
  }

  .md\:-m-70 {
    margin: -70rem !important;
  }

  .md\:-mx-70 {
    margin-left: -70rem !important;
    margin-right: -70rem !important;
  }

  .md\:-mt-70 {
    margin-top: -70rem !important;
  }

  .md\:-mb-70 {
    margin-bottom: -70rem !important;
  }

  .md\:-ml-70 {
    margin-left: -70rem !important;
  }

  .md\:-mr-70 {
    margin-right: -70rem !important;
  }

  .md\:m-80 {
    margin: 80rem !important;
  }

  .md\:my-80 {
    margin-top: 80rem !important;
    margin-bottom: 80rem !important;
  }

  .md\:mx-80 {
    margin-left: 80rem !important;
    margin-right: 80rem !important;
  }

  .md\:mt-80 {
    margin-top: 80rem !important;
  }

  .md\:mb-80 {
    margin-bottom: 80rem !important;
  }

  .md\:mr-80 {
    margin-right: 80rem !important;
  }

  .md\:ml-80 {
    margin-left: 80rem !important;
  }

  .md\:-m-80 {
    margin: -80rem !important;
  }

  .md\:-mx-80 {
    margin-left: -80rem !important;
    margin-right: -80rem !important;
  }

  .md\:-mt-80 {
    margin-top: -80rem !important;
  }

  .md\:-mb-80 {
    margin-bottom: -80rem !important;
  }

  .md\:-ml-80 {
    margin-left: -80rem !important;
  }

  .md\:-mr-80 {
    margin-right: -80rem !important;
  }

  .md\:m-90 {
    margin: 90rem !important;
  }

  .md\:my-90 {
    margin-top: 90rem !important;
    margin-bottom: 90rem !important;
  }

  .md\:mx-90 {
    margin-left: 90rem !important;
    margin-right: 90rem !important;
  }

  .md\:mt-90 {
    margin-top: 90rem !important;
  }

  .md\:mb-90 {
    margin-bottom: 90rem !important;
  }

  .md\:mr-90 {
    margin-right: 90rem !important;
  }

  .md\:ml-90 {
    margin-left: 90rem !important;
  }

  .md\:-m-90 {
    margin: -90rem !important;
  }

  .md\:-mx-90 {
    margin-left: -90rem !important;
    margin-right: -90rem !important;
  }

  .md\:-mt-90 {
    margin-top: -90rem !important;
  }

  .md\:-mb-90 {
    margin-bottom: -90rem !important;
  }

  .md\:-ml-90 {
    margin-left: -90rem !important;
  }

  .md\:-mr-90 {
    margin-right: -90rem !important;
  }

  .md\:m-100 {
    margin: 100rem !important;
  }

  .md\:my-100 {
    margin-top: 100rem !important;
    margin-bottom: 100rem !important;
  }

  .md\:mx-100 {
    margin-left: 100rem !important;
    margin-right: 100rem !important;
  }

  .md\:mt-100 {
    margin-top: 100rem !important;
  }

  .md\:mb-100 {
    margin-bottom: 100rem !important;
  }

  .md\:mr-100 {
    margin-right: 100rem !important;
  }

  .md\:ml-100 {
    margin-left: 100rem !important;
  }

  .md\:-m-100 {
    margin: -100rem !important;
  }

  .md\:-mx-100 {
    margin-left: -100rem !important;
    margin-right: -100rem !important;
  }

  .md\:-mt-100 {
    margin-top: -100rem !important;
  }

  .md\:-mb-100 {
    margin-bottom: -100rem !important;
  }

  .md\:-ml-100 {
    margin-left: -100rem !important;
  }

  .md\:-mr-100 {
    margin-right: -100rem !important;
  }

  .md\:m-10p {
    margin: 10% !important;
  }

  .md\:my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

  .md\:mx-10p {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .md\:mt-10p {
    margin-top: 10% !important;
  }

  .md\:mb-10p {
    margin-bottom: 10% !important;
  }

  .md\:mr-10p {
    margin-right: 10% !important;
  }

  .md\:ml-10p {
    margin-left: 10% !important;
  }

  .md\:-m-10p {
    margin: -10% !important;
  }

  .md\:-mx-10p {
    margin-left: -10% !important;
    margin-right: -10% !important;
  }

  .md\:-mt-10p {
    margin-top: -10% !important;
  }

  .md\:-mb-10p {
    margin-bottom: -10% !important;
  }

  .md\:-ml-10p {
    margin-left: -10% !important;
  }

  .md\:-mr-10p {
    margin-right: -10% !important;
  }

  .md\:m-20p {
    margin: 20% !important;
  }

  .md\:my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }

  .md\:mx-20p {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .md\:mt-20p {
    margin-top: 20% !important;
  }

  .md\:mb-20p {
    margin-bottom: 20% !important;
  }

  .md\:mr-20p {
    margin-right: 20% !important;
  }

  .md\:ml-20p {
    margin-left: 20% !important;
  }

  .md\:-m-20p {
    margin: -20% !important;
  }

  .md\:-mx-20p {
    margin-left: -20% !important;
    margin-right: -20% !important;
  }

  .md\:-mt-20p {
    margin-top: -20% !important;
  }

  .md\:-mb-20p {
    margin-bottom: -20% !important;
  }

  .md\:-ml-20p {
    margin-left: -20% !important;
  }

  .md\:-mr-20p {
    margin-right: -20% !important;
  }

  .md\:m-30p {
    margin: 30% !important;
  }

  .md\:my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }

  .md\:mx-30p {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }

  .md\:mt-30p {
    margin-top: 30% !important;
  }

  .md\:mb-30p {
    margin-bottom: 30% !important;
  }

  .md\:mr-30p {
    margin-right: 30% !important;
  }

  .md\:ml-30p {
    margin-left: 30% !important;
  }

  .md\:-m-30p {
    margin: -30% !important;
  }

  .md\:-mx-30p {
    margin-left: -30% !important;
    margin-right: -30% !important;
  }

  .md\:-mt-30p {
    margin-top: -30% !important;
  }

  .md\:-mb-30p {
    margin-bottom: -30% !important;
  }

  .md\:-ml-30p {
    margin-left: -30% !important;
  }

  .md\:-mr-30p {
    margin-right: -30% !important;
  }

  .md\:m-40p {
    margin: 40% !important;
  }

  .md\:my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }

  .md\:mx-40p {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }

  .md\:mt-40p {
    margin-top: 40% !important;
  }

  .md\:mb-40p {
    margin-bottom: 40% !important;
  }

  .md\:mr-40p {
    margin-right: 40% !important;
  }

  .md\:ml-40p {
    margin-left: 40% !important;
  }

  .md\:-m-40p {
    margin: -40% !important;
  }

  .md\:-mx-40p {
    margin-left: -40% !important;
    margin-right: -40% !important;
  }

  .md\:-mt-40p {
    margin-top: -40% !important;
  }

  .md\:-mb-40p {
    margin-bottom: -40% !important;
  }

  .md\:-ml-40p {
    margin-left: -40% !important;
  }

  .md\:-mr-40p {
    margin-right: -40% !important;
  }

  .md\:m-50p {
    margin: 50% !important;
  }

  .md\:my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }

  .md\:mx-50p {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }

  .md\:mt-50p {
    margin-top: 50% !important;
  }

  .md\:mb-50p {
    margin-bottom: 50% !important;
  }

  .md\:mr-50p {
    margin-right: 50% !important;
  }

  .md\:ml-50p {
    margin-left: 50% !important;
  }

  .md\:-m-50p {
    margin: -50% !important;
  }

  .md\:-mx-50p {
    margin-left: -50% !important;
    margin-right: -50% !important;
  }

  .md\:-mt-50p {
    margin-top: -50% !important;
  }

  .md\:-mb-50p {
    margin-bottom: -50% !important;
  }

  .md\:-ml-50p {
    margin-left: -50% !important;
  }

  .md\:-mr-50p {
    margin-right: -50% !important;
  }

  .md\:m-60p {
    margin: 60% !important;
  }

  .md\:my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }

  .md\:mx-60p {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }

  .md\:mt-60p {
    margin-top: 60% !important;
  }

  .md\:mb-60p {
    margin-bottom: 60% !important;
  }

  .md\:mr-60p {
    margin-right: 60% !important;
  }

  .md\:ml-60p {
    margin-left: 60% !important;
  }

  .md\:-m-60p {
    margin: -60% !important;
  }

  .md\:-mx-60p {
    margin-left: -60% !important;
    margin-right: -60% !important;
  }

  .md\:-mt-60p {
    margin-top: -60% !important;
  }

  .md\:-mb-60p {
    margin-bottom: -60% !important;
  }

  .md\:-ml-60p {
    margin-left: -60% !important;
  }

  .md\:-mr-60p {
    margin-right: -60% !important;
  }

  .md\:m-70p {
    margin: 70% !important;
  }

  .md\:my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }

  .md\:mx-70p {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }

  .md\:mt-70p {
    margin-top: 70% !important;
  }

  .md\:mb-70p {
    margin-bottom: 70% !important;
  }

  .md\:mr-70p {
    margin-right: 70% !important;
  }

  .md\:ml-70p {
    margin-left: 70% !important;
  }

  .md\:-m-70p {
    margin: -70% !important;
  }

  .md\:-mx-70p {
    margin-left: -70% !important;
    margin-right: -70% !important;
  }

  .md\:-mt-70p {
    margin-top: -70% !important;
  }

  .md\:-mb-70p {
    margin-bottom: -70% !important;
  }

  .md\:-ml-70p {
    margin-left: -70% !important;
  }

  .md\:-mr-70p {
    margin-right: -70% !important;
  }

  .md\:m-80p {
    margin: 80% !important;
  }

  .md\:my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }

  .md\:mx-80p {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }

  .md\:mt-80p {
    margin-top: 80% !important;
  }

  .md\:mb-80p {
    margin-bottom: 80% !important;
  }

  .md\:mr-80p {
    margin-right: 80% !important;
  }

  .md\:ml-80p {
    margin-left: 80% !important;
  }

  .md\:-m-80p {
    margin: -80% !important;
  }

  .md\:-mx-80p {
    margin-left: -80% !important;
    margin-right: -80% !important;
  }

  .md\:-mt-80p {
    margin-top: -80% !important;
  }

  .md\:-mb-80p {
    margin-bottom: -80% !important;
  }

  .md\:-ml-80p {
    margin-left: -80% !important;
  }

  .md\:-mr-80p {
    margin-right: -80% !important;
  }

  .md\:m-90p {
    margin: 90% !important;
  }

  .md\:my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }

  .md\:mx-90p {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }

  .md\:mt-90p {
    margin-top: 90% !important;
  }

  .md\:mb-90p {
    margin-bottom: 90% !important;
  }

  .md\:mr-90p {
    margin-right: 90% !important;
  }

  .md\:ml-90p {
    margin-left: 90% !important;
  }

  .md\:-m-90p {
    margin: -90% !important;
  }

  .md\:-mx-90p {
    margin-left: -90% !important;
    margin-right: -90% !important;
  }

  .md\:-mt-90p {
    margin-top: -90% !important;
  }

  .md\:-mb-90p {
    margin-bottom: -90% !important;
  }

  .md\:-ml-90p {
    margin-left: -90% !important;
  }

  .md\:-mr-90p {
    margin-right: -90% !important;
  }

  .md\:m-100p {
    margin: 100% !important;
  }

  .md\:my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }

  .md\:mx-100p {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }

  .md\:mt-100p {
    margin-top: 100% !important;
  }

  .md\:mb-100p {
    margin-bottom: 100% !important;
  }

  .md\:mr-100p {
    margin-right: 100% !important;
  }

  .md\:ml-100p {
    margin-left: 100% !important;
  }

  .md\:-m-100p {
    margin: -100% !important;
  }

  .md\:-mx-100p {
    margin-left: -100% !important;
    margin-right: -100% !important;
  }

  .md\:-mt-100p {
    margin-top: -100% !important;
  }

  .md\:-mb-100p {
    margin-bottom: -100% !important;
  }

  .md\:-ml-100p {
    margin-left: -100% !important;
  }

  .md\:-mr-100p {
    margin-right: -100% !important;
  }

  .md\:fixed {
    position: fixed !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:relative {
    position: relative !important;
  }

  .md\:sticky {
    position: sticky !important;
  }

  .md\:top-0 {
    top: 0 !important;
  }

  .md\:right-0 {
    right: 0 !important;
  }

  .md\:bottom-0 {
    bottom: 0 !important;
  }

  .md\:left-0 {
    left: 0 !important;
  }

  .md\:trbl-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-10 {
    z-index: 10 !important;
  }

  .md\:z-20 {
    z-index: 20 !important;
  }

  .md\:z-100 {
    z-index: 100 !important;
  }

  .-md\:z-1 {
    z-index: -1 !important;
  }

  .md\:border-0 {
    border: 0 !important;
  }

  .md\:round {
    border-radius: 100vw !important;
  }

  .md\:rounded-0 {
    border-radius: 0 !important;
  }

  .md\:rounded-xxl {
    border-radius: 2rem !important;
  }

  .md\:rounded-xl {
    border-radius: 1rem !important;
  }

  .md\:rounded-lg {
    border-radius: 0.75rem !important;
  }

  .md\:rounded-md {
    border-radius: 0.5rem !important;
  }

  .md\:rounded-sm {
    border-radius: 0.25rem !important;
  }

  .md\:rounded-xs {
    border-radius: 0.125rem !important;
  }
}
@media (min-width: 1220px) {
  .lg\:flex {
    display: flex !important;
  }

  .lg\:inline-flex {
    display: inline-flex  !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:inline-block {
    display: inline-block !important;
  }

  .lg\:hidden,
.lg\:dnone {
    display: none !important;
  }

  .lg\:flex-column {
    flex-direction: column;
  }

  .lg\:flex-column.reverse,
.lg\:column-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:row-reverse,
.lg\:reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:items-center,
.lg\:y-center,
.lg\:vertical-center {
    align-items: center;
  }

  .lg\:flex-start,
.lg\:items-start {
    align-items: flex-start;
  }

  .lg\:flex-end,
.lg\:items-end {
    align-items: flex-end;
  }

  .lg\:center {
    align-items: center;
    text-align: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center,
.lg\:x-center,
.lg\:horizontal-center {
    justify-content: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:space-between,
.lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:space-around,
.lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }

  .lg\:text-uppercase {
    text-transform: uppercase !important;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }

  .lg\:font-size-xxl {
    font-size: 5rem !important;
  }

  .lg\:font-size-xl {
    font-size: 2rem !important;
  }

  .lg\:font-size-lg {
    font-size: 1.5rem !important;
  }

  .lg\:font-size-md {
    font-size: 1.25rem !important;
  }

  .lg\:font-size-default {
    font-size: 1rem !important;
  }

  .lg\:font-size-sm {
    font-size: 0.75rem !important;
  }

  .lg\:font-size-xs {
    font-size: 0.5rem !important;
  }

  .lg\:w-1\/4,
.lg\:w-25 {
    width: 25% !important;
  }

  .lg\:w-1\/3,
.lg\:w-33 {
    width: 33.33% !important;
  }

  .lg\:w-1\/2,
.lg\:w-2\/4,
.lg\:w-half,
.lg\:w-50 {
    width: 50% !important;
  }

  .lg\:w-2\/3,
.lg\:w-66 {
    width: 66.66% !important;
  }

  .lg\:w-3\/4,
.lg\:w-75 {
    width: 75% !important;
  }

  .lg\:w-100,
.lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:mxw-xs {
    max-width: 600px !important;
  }

  .lg\:mxw-sm {
    max-width: 782px !important;
  }

  .lg\:mxw-md {
    max-width: 1024px !important;
  }

  .lg\:mxw-lg {
    max-width: 1220px !important;
  }

  .lg\:mxw-xl {
    max-width: 1460px !important;
  }

  .lg\:h-100 {
    height: 100% !important;
  }

  .lg\:h-100vh {
    height: 100vh !important;
  }

  .lg\:h-50 {
    height: 50% !important;
  }

  .lg\:h-50vh {
    height: 50vh !important;
  }

  .lg\:h-inherit {
    height: inherit !important;
  }

  .lg\:color-primary {
    color: #25373e !important;
  }

  .lg\:bg-primary {
    background-color: #25373e !important;
  }

  .lg\:color-secondary {
    color: #e9a844 !important;
  }

  .lg\:bg-secondary {
    background-color: #e9a844 !important;
  }

  .lg\:color-tertiary {
    color: #3d6574 !important;
  }

  .lg\:bg-tertiary {
    background-color: #3d6574 !important;
  }

  .lg\:color-white {
    color: #fff !important;
  }

  .lg\:bg-white {
    background-color: #fff !important;
  }

  .lg\:color-light-primary {
    color: #25373eed !important;
  }

  .lg\:bg-light-primary {
    background-color: #25373eed !important;
  }

  .lg\:color-extra-light-primary {
    color: #25383d0f !important;
  }

  .lg\:bg-extra-light-primary {
    background-color: #25383d0f !important;
  }

  .lg\:color-light-secondary {
    color: #e9a8449c !important;
  }

  .lg\:bg-light-secondary {
    background-color: #e9a8449c !important;
  }

  .lg\:p-none {
    padding: 0 !important;
  }

  .lg\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .lg\:p-0 {
    padding: 0 !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:p-xs {
    padding: 0.25rem !important;
  }

  .lg\:px-xs {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:py-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:pr-xs {
    padding-right: 0.25rem !important;
  }

  .lg\:pl-xs {
    padding-left: 0.25rem !important;
  }

  .lg\:pt-xs {
    padding-top: 0.25rem !important;
  }

  .lg\:pb-xs {
    padding-bottom: 0.25rem !important;
  }

  .lg\:p-sm {
    padding: 0.5rem !important;
  }

  .lg\:px-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:py-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:pr-sm {
    padding-right: 0.5rem !important;
  }

  .lg\:pl-sm {
    padding-left: 0.5rem !important;
  }

  .lg\:pt-sm {
    padding-top: 0.5rem !important;
  }

  .lg\:pb-sm {
    padding-bottom: 0.5rem !important;
  }

  .lg\:p-1 {
    padding: 1rem !important;
  }

  .lg\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:pr-1 {
    padding-right: 1rem !important;
  }

  .lg\:pl-1 {
    padding-left: 1rem !important;
  }

  .lg\:pt-1 {
    padding-top: 1rem !important;
  }

  .lg\:pb-1 {
    padding-bottom: 1rem !important;
  }

  .lg\:p-2 {
    padding: 2rem !important;
  }

  .lg\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:pr-2 {
    padding-right: 2rem !important;
  }

  .lg\:pl-2 {
    padding-left: 2rem !important;
  }

  .lg\:pt-2 {
    padding-top: 2rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: 2rem !important;
  }

  .lg\:p-3 {
    padding: 3rem !important;
  }

  .lg\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:pr-3 {
    padding-right: 3rem !important;
  }

  .lg\:pl-3 {
    padding-left: 3rem !important;
  }

  .lg\:pt-3 {
    padding-top: 3rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 3rem !important;
  }

  .lg\:p-4 {
    padding: 4rem !important;
  }

  .lg\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:pr-4 {
    padding-right: 4rem !important;
  }

  .lg\:pl-4 {
    padding-left: 4rem !important;
  }

  .lg\:pt-4 {
    padding-top: 4rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 4rem !important;
  }

  .lg\:p-5 {
    padding: 5rem !important;
  }

  .lg\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lg\:pr-5 {
    padding-right: 5rem !important;
  }

  .lg\:pl-5 {
    padding-left: 5rem !important;
  }

  .lg\:pt-5 {
    padding-top: 5rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 5rem !important;
  }

  .lg\:p-6 {
    padding: 6rem !important;
  }

  .lg\:px-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lg\:py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:pr-6 {
    padding-right: 6rem !important;
  }

  .lg\:pl-6 {
    padding-left: 6rem !important;
  }

  .lg\:pt-6 {
    padding-top: 6rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 6rem !important;
  }

  .lg\:p-7 {
    padding: 7rem !important;
  }

  .lg\:px-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lg\:py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:pr-7 {
    padding-right: 7rem !important;
  }

  .lg\:pl-7 {
    padding-left: 7rem !important;
  }

  .lg\:pt-7 {
    padding-top: 7rem !important;
  }

  .lg\:pb-7 {
    padding-bottom: 7rem !important;
  }

  .lg\:p-8 {
    padding: 8rem !important;
  }

  .lg\:px-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:pr-8 {
    padding-right: 8rem !important;
  }

  .lg\:pl-8 {
    padding-left: 8rem !important;
  }

  .lg\:pt-8 {
    padding-top: 8rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 8rem !important;
  }

  .lg\:p-9 {
    padding: 9rem !important;
  }

  .lg\:px-9 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lg\:py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lg\:pr-9 {
    padding-right: 9rem !important;
  }

  .lg\:pl-9 {
    padding-left: 9rem !important;
  }

  .lg\:pt-9 {
    padding-top: 9rem !important;
  }

  .lg\:pb-9 {
    padding-bottom: 9rem !important;
  }

  .lg\:p-10 {
    padding: 10rem !important;
  }

  .lg\:px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lg\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lg\:pr-10 {
    padding-right: 10rem !important;
  }

  .lg\:pl-10 {
    padding-left: 10rem !important;
  }

  .lg\:pt-10 {
    padding-top: 10rem !important;
  }

  .lg\:pb-10 {
    padding-bottom: 10rem !important;
  }

  .lg\:p-15 {
    padding: 15rem !important;
  }

  .lg\:px-15 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .lg\:py-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .lg\:pr-15 {
    padding-right: 15rem !important;
  }

  .lg\:pl-15 {
    padding-left: 15rem !important;
  }

  .lg\:pt-15 {
    padding-top: 15rem !important;
  }

  .lg\:pb-15 {
    padding-bottom: 15rem !important;
  }

  .lg\:p-20 {
    padding: 20rem !important;
  }

  .lg\:px-20 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .lg\:py-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .lg\:pr-20 {
    padding-right: 20rem !important;
  }

  .lg\:pl-20 {
    padding-left: 20rem !important;
  }

  .lg\:pt-20 {
    padding-top: 20rem !important;
  }

  .lg\:pb-20 {
    padding-bottom: 20rem !important;
  }

  .lg\:p-30 {
    padding: 30rem !important;
  }

  .lg\:px-30 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
  }

  .lg\:py-30 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .lg\:pr-30 {
    padding-right: 30rem !important;
  }

  .lg\:pl-30 {
    padding-left: 30rem !important;
  }

  .lg\:pt-30 {
    padding-top: 30rem !important;
  }

  .lg\:pb-30 {
    padding-bottom: 30rem !important;
  }

  .lg\:p-40 {
    padding: 40rem !important;
  }

  .lg\:px-40 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
  }

  .lg\:py-40 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }

  .lg\:pr-40 {
    padding-right: 40rem !important;
  }

  .lg\:pl-40 {
    padding-left: 40rem !important;
  }

  .lg\:pt-40 {
    padding-top: 40rem !important;
  }

  .lg\:pb-40 {
    padding-bottom: 40rem !important;
  }

  .lg\:p-50 {
    padding: 50rem !important;
  }

  .lg\:px-50 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
  }

  .lg\:py-50 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
  }

  .lg\:pr-50 {
    padding-right: 50rem !important;
  }

  .lg\:pl-50 {
    padding-left: 50rem !important;
  }

  .lg\:pt-50 {
    padding-top: 50rem !important;
  }

  .lg\:pb-50 {
    padding-bottom: 50rem !important;
  }

  .lg\:p-60 {
    padding: 60rem !important;
  }

  .lg\:px-60 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
  }

  .lg\:py-60 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
  }

  .lg\:pr-60 {
    padding-right: 60rem !important;
  }

  .lg\:pl-60 {
    padding-left: 60rem !important;
  }

  .lg\:pt-60 {
    padding-top: 60rem !important;
  }

  .lg\:pb-60 {
    padding-bottom: 60rem !important;
  }

  .lg\:p-70 {
    padding: 70rem !important;
  }

  .lg\:px-70 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
  }

  .lg\:py-70 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
  }

  .lg\:pr-70 {
    padding-right: 70rem !important;
  }

  .lg\:pl-70 {
    padding-left: 70rem !important;
  }

  .lg\:pt-70 {
    padding-top: 70rem !important;
  }

  .lg\:pb-70 {
    padding-bottom: 70rem !important;
  }

  .lg\:p-80 {
    padding: 80rem !important;
  }

  .lg\:px-80 {
    padding-left: 80rem !important;
    padding-right: 80rem !important;
  }

  .lg\:py-80 {
    padding-top: 80rem !important;
    padding-bottom: 80rem !important;
  }

  .lg\:pr-80 {
    padding-right: 80rem !important;
  }

  .lg\:pl-80 {
    padding-left: 80rem !important;
  }

  .lg\:pt-80 {
    padding-top: 80rem !important;
  }

  .lg\:pb-80 {
    padding-bottom: 80rem !important;
  }

  .lg\:p-90 {
    padding: 90rem !important;
  }

  .lg\:px-90 {
    padding-left: 90rem !important;
    padding-right: 90rem !important;
  }

  .lg\:py-90 {
    padding-top: 90rem !important;
    padding-bottom: 90rem !important;
  }

  .lg\:pr-90 {
    padding-right: 90rem !important;
  }

  .lg\:pl-90 {
    padding-left: 90rem !important;
  }

  .lg\:pt-90 {
    padding-top: 90rem !important;
  }

  .lg\:pb-90 {
    padding-bottom: 90rem !important;
  }

  .lg\:p-100 {
    padding: 100rem !important;
  }

  .lg\:px-100 {
    padding-left: 100rem !important;
    padding-right: 100rem !important;
  }

  .lg\:py-100 {
    padding-top: 100rem !important;
    padding-bottom: 100rem !important;
  }

  .lg\:pr-100 {
    padding-right: 100rem !important;
  }

  .lg\:pl-100 {
    padding-left: 100rem !important;
  }

  .lg\:pt-100 {
    padding-top: 100rem !important;
  }

  .lg\:pb-100 {
    padding-bottom: 100rem !important;
  }

  .lg\:p-10p {
    padding: 10% !important;
  }

  .lg\:px-10p {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .lg\:py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }

  .lg\:pr-10p {
    padding-right: 10% !important;
  }

  .lg\:pl-10p {
    padding-left: 10% !important;
  }

  .lg\:pt-10p {
    padding-top: 10% !important;
  }

  .lg\:pb-10p {
    padding-bottom: 10% !important;
  }

  .lg\:p-20p {
    padding: 20% !important;
  }

  .lg\:px-20p {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .lg\:py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }

  .lg\:pr-20p {
    padding-right: 20% !important;
  }

  .lg\:pl-20p {
    padding-left: 20% !important;
  }

  .lg\:pt-20p {
    padding-top: 20% !important;
  }

  .lg\:pb-20p {
    padding-bottom: 20% !important;
  }

  .lg\:p-30p {
    padding: 30% !important;
  }

  .lg\:px-30p {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }

  .lg\:py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }

  .lg\:pr-30p {
    padding-right: 30% !important;
  }

  .lg\:pl-30p {
    padding-left: 30% !important;
  }

  .lg\:pt-30p {
    padding-top: 30% !important;
  }

  .lg\:pb-30p {
    padding-bottom: 30% !important;
  }

  .lg\:p-40p {
    padding: 40% !important;
  }

  .lg\:px-40p {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }

  .lg\:py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }

  .lg\:pr-40p {
    padding-right: 40% !important;
  }

  .lg\:pl-40p {
    padding-left: 40% !important;
  }

  .lg\:pt-40p {
    padding-top: 40% !important;
  }

  .lg\:pb-40p {
    padding-bottom: 40% !important;
  }

  .lg\:p-50p {
    padding: 50% !important;
  }

  .lg\:px-50p {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }

  .lg\:py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }

  .lg\:pr-50p {
    padding-right: 50% !important;
  }

  .lg\:pl-50p {
    padding-left: 50% !important;
  }

  .lg\:pt-50p {
    padding-top: 50% !important;
  }

  .lg\:pb-50p {
    padding-bottom: 50% !important;
  }

  .lg\:p-60p {
    padding: 60% !important;
  }

  .lg\:px-60p {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }

  .lg\:py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }

  .lg\:pr-60p {
    padding-right: 60% !important;
  }

  .lg\:pl-60p {
    padding-left: 60% !important;
  }

  .lg\:pt-60p {
    padding-top: 60% !important;
  }

  .lg\:pb-60p {
    padding-bottom: 60% !important;
  }

  .lg\:p-70p {
    padding: 70% !important;
  }

  .lg\:px-70p {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }

  .lg\:py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }

  .lg\:pr-70p {
    padding-right: 70% !important;
  }

  .lg\:pl-70p {
    padding-left: 70% !important;
  }

  .lg\:pt-70p {
    padding-top: 70% !important;
  }

  .lg\:pb-70p {
    padding-bottom: 70% !important;
  }

  .lg\:p-80p {
    padding: 80% !important;
  }

  .lg\:px-80p {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }

  .lg\:py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }

  .lg\:pr-80p {
    padding-right: 80% !important;
  }

  .lg\:pl-80p {
    padding-left: 80% !important;
  }

  .lg\:pt-80p {
    padding-top: 80% !important;
  }

  .lg\:pb-80p {
    padding-bottom: 80% !important;
  }

  .lg\:p-90p {
    padding: 90% !important;
  }

  .lg\:px-90p {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }

  .lg\:py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }

  .lg\:pr-90p {
    padding-right: 90% !important;
  }

  .lg\:pl-90p {
    padding-left: 90% !important;
  }

  .lg\:pt-90p {
    padding-top: 90% !important;
  }

  .lg\:pb-90p {
    padding-bottom: 90% !important;
  }

  .lg\:p-100p {
    padding: 100% !important;
  }

  .lg\:px-100p {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }

  .lg\:py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }

  .lg\:pr-100p {
    padding-right: 100% !important;
  }

  .lg\:pl-100p {
    padding-left: 100% !important;
  }

  .lg\:pt-100p {
    padding-top: 100% !important;
  }

  .lg\:pb-100p {
    padding-bottom: 100% !important;
  }

  .lg\:m-none {
    margin: 0 !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:m-0 {
    margin: 0 !important;
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lg\:m-xs {
    margin: 0.25rem !important;
  }

  .lg\:my-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:mx-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:mt-xs {
    margin-top: 0.25rem !important;
  }

  .lg\:mb-xs {
    margin-bottom: 0.25rem !important;
  }

  .lg\:mr-xs {
    margin-right: 0.25rem !important;
  }

  .lg\:ml-xs {
    margin-left: 0.25rem !important;
  }

  .lg\:-m-xs {
    margin: -0.25rem !important;
  }

  .lg\:-mx-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:-mt-xs {
    margin-top: -0.25rem !important;
  }

  .lg\:-mb-xs {
    margin-bottom: -0.25rem !important;
  }

  .lg\:-ml-xs {
    margin-left: -0.25rem !important;
  }

  .lg\:-mr-xs {
    margin-right: -0.25rem !important;
  }

  .lg\:m-sm {
    margin: 0.5rem !important;
  }

  .lg\:my-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:mx-sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:mt-sm {
    margin-top: 0.5rem !important;
  }

  .lg\:mb-sm {
    margin-bottom: 0.5rem !important;
  }

  .lg\:mr-sm {
    margin-right: 0.5rem !important;
  }

  .lg\:ml-sm {
    margin-left: 0.5rem !important;
  }

  .lg\:-m-sm {
    margin: -0.5rem !important;
  }

  .lg\:-mx-sm {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:-mt-sm {
    margin-top: -0.5rem !important;
  }

  .lg\:-mb-sm {
    margin-bottom: -0.5rem !important;
  }

  .lg\:-ml-sm {
    margin-left: -0.5rem !important;
  }

  .lg\:-mr-sm {
    margin-right: -0.5rem !important;
  }

  .lg\:m-1 {
    margin: 1rem !important;
  }

  .lg\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:mt-1 {
    margin-top: 1rem !important;
  }

  .lg\:mb-1 {
    margin-bottom: 1rem !important;
  }

  .lg\:mr-1 {
    margin-right: 1rem !important;
  }

  .lg\:ml-1 {
    margin-left: 1rem !important;
  }

  .lg\:-m-1 {
    margin: -1rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-mt-1 {
    margin-top: -1rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -1rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -1rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -1rem !important;
  }

  .lg\:m-2 {
    margin: 2rem !important;
  }

  .lg\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:mt-2 {
    margin-top: 2rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: 2rem !important;
  }

  .lg\:mr-2 {
    margin-right: 2rem !important;
  }

  .lg\:ml-2 {
    margin-left: 2rem !important;
  }

  .lg\:-m-2 {
    margin: -2rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -2rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -2rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -2rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -2rem !important;
  }

  .lg\:m-3 {
    margin: 3rem !important;
  }

  .lg\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:mt-3 {
    margin-top: 3rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 3rem !important;
  }

  .lg\:mr-3 {
    margin-right: 3rem !important;
  }

  .lg\:ml-3 {
    margin-left: 3rem !important;
  }

  .lg\:-m-3 {
    margin: -3rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -3rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -3rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -3rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -3rem !important;
  }

  .lg\:m-4 {
    margin: 4rem !important;
  }

  .lg\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:mt-4 {
    margin-top: 4rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 4rem !important;
  }

  .lg\:mr-4 {
    margin-right: 4rem !important;
  }

  .lg\:ml-4 {
    margin-left: 4rem !important;
  }

  .lg\:-m-4 {
    margin: -4rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -4rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -4rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -4rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -4rem !important;
  }

  .lg\:m-5 {
    margin: 5rem !important;
  }

  .lg\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:mt-5 {
    margin-top: 5rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 5rem !important;
  }

  .lg\:mr-5 {
    margin-right: 5rem !important;
  }

  .lg\:ml-5 {
    margin-left: 5rem !important;
  }

  .lg\:-m-5 {
    margin: -5rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -5rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -5rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -5rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -5rem !important;
  }

  .lg\:m-6 {
    margin: 6rem !important;
  }

  .lg\:my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lg\:mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lg\:mt-6 {
    margin-top: 6rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 6rem !important;
  }

  .lg\:mr-6 {
    margin-right: 6rem !important;
  }

  .lg\:ml-6 {
    margin-left: 6rem !important;
  }

  .lg\:-m-6 {
    margin: -6rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -6rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -6rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -6rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -6rem !important;
  }

  .lg\:m-7 {
    margin: 7rem !important;
  }

  .lg\:my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lg\:mx-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lg\:mt-7 {
    margin-top: 7rem !important;
  }

  .lg\:mb-7 {
    margin-bottom: 7rem !important;
  }

  .lg\:mr-7 {
    margin-right: 7rem !important;
  }

  .lg\:ml-7 {
    margin-left: 7rem !important;
  }

  .lg\:-m-7 {
    margin: -7rem !important;
  }

  .lg\:-mx-7 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lg\:-mt-7 {
    margin-top: -7rem !important;
  }

  .lg\:-mb-7 {
    margin-bottom: -7rem !important;
  }

  .lg\:-ml-7 {
    margin-left: -7rem !important;
  }

  .lg\:-mr-7 {
    margin-right: -7rem !important;
  }

  .lg\:m-8 {
    margin: 8rem !important;
  }

  .lg\:my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lg\:mx-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lg\:mt-8 {
    margin-top: 8rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 8rem !important;
  }

  .lg\:mr-8 {
    margin-right: 8rem !important;
  }

  .lg\:ml-8 {
    margin-left: 8rem !important;
  }

  .lg\:-m-8 {
    margin: -8rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -8rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -8rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -8rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -8rem !important;
  }

  .lg\:m-9 {
    margin: 9rem !important;
  }

  .lg\:my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lg\:mx-9 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lg\:mt-9 {
    margin-top: 9rem !important;
  }

  .lg\:mb-9 {
    margin-bottom: 9rem !important;
  }

  .lg\:mr-9 {
    margin-right: 9rem !important;
  }

  .lg\:ml-9 {
    margin-left: 9rem !important;
  }

  .lg\:-m-9 {
    margin: -9rem !important;
  }

  .lg\:-mx-9 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lg\:-mt-9 {
    margin-top: -9rem !important;
  }

  .lg\:-mb-9 {
    margin-bottom: -9rem !important;
  }

  .lg\:-ml-9 {
    margin-left: -9rem !important;
  }

  .lg\:-mr-9 {
    margin-right: -9rem !important;
  }

  .lg\:m-10 {
    margin: 10rem !important;
  }

  .lg\:my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lg\:mx-10 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lg\:mt-10 {
    margin-top: 10rem !important;
  }

  .lg\:mb-10 {
    margin-bottom: 10rem !important;
  }

  .lg\:mr-10 {
    margin-right: 10rem !important;
  }

  .lg\:ml-10 {
    margin-left: 10rem !important;
  }

  .lg\:-m-10 {
    margin: -10rem !important;
  }

  .lg\:-mx-10 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lg\:-mt-10 {
    margin-top: -10rem !important;
  }

  .lg\:-mb-10 {
    margin-bottom: -10rem !important;
  }

  .lg\:-ml-10 {
    margin-left: -10rem !important;
  }

  .lg\:-mr-10 {
    margin-right: -10rem !important;
  }

  .lg\:m-15 {
    margin: 15rem !important;
  }

  .lg\:my-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .lg\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .lg\:mt-15 {
    margin-top: 15rem !important;
  }

  .lg\:mb-15 {
    margin-bottom: 15rem !important;
  }

  .lg\:mr-15 {
    margin-right: 15rem !important;
  }

  .lg\:ml-15 {
    margin-left: 15rem !important;
  }

  .lg\:-m-15 {
    margin: -15rem !important;
  }

  .lg\:-mx-15 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .lg\:-mt-15 {
    margin-top: -15rem !important;
  }

  .lg\:-mb-15 {
    margin-bottom: -15rem !important;
  }

  .lg\:-ml-15 {
    margin-left: -15rem !important;
  }

  .lg\:-mr-15 {
    margin-right: -15rem !important;
  }

  .lg\:m-20 {
    margin: 20rem !important;
  }

  .lg\:my-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .lg\:mx-20 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .lg\:mt-20 {
    margin-top: 20rem !important;
  }

  .lg\:mb-20 {
    margin-bottom: 20rem !important;
  }

  .lg\:mr-20 {
    margin-right: 20rem !important;
  }

  .lg\:ml-20 {
    margin-left: 20rem !important;
  }

  .lg\:-m-20 {
    margin: -20rem !important;
  }

  .lg\:-mx-20 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .lg\:-mt-20 {
    margin-top: -20rem !important;
  }

  .lg\:-mb-20 {
    margin-bottom: -20rem !important;
  }

  .lg\:-ml-20 {
    margin-left: -20rem !important;
  }

  .lg\:-mr-20 {
    margin-right: -20rem !important;
  }

  .lg\:m-30 {
    margin: 30rem !important;
  }

  .lg\:my-30 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .lg\:mx-30 {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }

  .lg\:mt-30 {
    margin-top: 30rem !important;
  }

  .lg\:mb-30 {
    margin-bottom: 30rem !important;
  }

  .lg\:mr-30 {
    margin-right: 30rem !important;
  }

  .lg\:ml-30 {
    margin-left: 30rem !important;
  }

  .lg\:-m-30 {
    margin: -30rem !important;
  }

  .lg\:-mx-30 {
    margin-left: -30rem !important;
    margin-right: -30rem !important;
  }

  .lg\:-mt-30 {
    margin-top: -30rem !important;
  }

  .lg\:-mb-30 {
    margin-bottom: -30rem !important;
  }

  .lg\:-ml-30 {
    margin-left: -30rem !important;
  }

  .lg\:-mr-30 {
    margin-right: -30rem !important;
  }

  .lg\:m-40 {
    margin: 40rem !important;
  }

  .lg\:my-40 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important;
  }

  .lg\:mx-40 {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }

  .lg\:mt-40 {
    margin-top: 40rem !important;
  }

  .lg\:mb-40 {
    margin-bottom: 40rem !important;
  }

  .lg\:mr-40 {
    margin-right: 40rem !important;
  }

  .lg\:ml-40 {
    margin-left: 40rem !important;
  }

  .lg\:-m-40 {
    margin: -40rem !important;
  }

  .lg\:-mx-40 {
    margin-left: -40rem !important;
    margin-right: -40rem !important;
  }

  .lg\:-mt-40 {
    margin-top: -40rem !important;
  }

  .lg\:-mb-40 {
    margin-bottom: -40rem !important;
  }

  .lg\:-ml-40 {
    margin-left: -40rem !important;
  }

  .lg\:-mr-40 {
    margin-right: -40rem !important;
  }

  .lg\:m-50 {
    margin: 50rem !important;
  }

  .lg\:my-50 {
    margin-top: 50rem !important;
    margin-bottom: 50rem !important;
  }

  .lg\:mx-50 {
    margin-left: 50rem !important;
    margin-right: 50rem !important;
  }

  .lg\:mt-50 {
    margin-top: 50rem !important;
  }

  .lg\:mb-50 {
    margin-bottom: 50rem !important;
  }

  .lg\:mr-50 {
    margin-right: 50rem !important;
  }

  .lg\:ml-50 {
    margin-left: 50rem !important;
  }

  .lg\:-m-50 {
    margin: -50rem !important;
  }

  .lg\:-mx-50 {
    margin-left: -50rem !important;
    margin-right: -50rem !important;
  }

  .lg\:-mt-50 {
    margin-top: -50rem !important;
  }

  .lg\:-mb-50 {
    margin-bottom: -50rem !important;
  }

  .lg\:-ml-50 {
    margin-left: -50rem !important;
  }

  .lg\:-mr-50 {
    margin-right: -50rem !important;
  }

  .lg\:m-60 {
    margin: 60rem !important;
  }

  .lg\:my-60 {
    margin-top: 60rem !important;
    margin-bottom: 60rem !important;
  }

  .lg\:mx-60 {
    margin-left: 60rem !important;
    margin-right: 60rem !important;
  }

  .lg\:mt-60 {
    margin-top: 60rem !important;
  }

  .lg\:mb-60 {
    margin-bottom: 60rem !important;
  }

  .lg\:mr-60 {
    margin-right: 60rem !important;
  }

  .lg\:ml-60 {
    margin-left: 60rem !important;
  }

  .lg\:-m-60 {
    margin: -60rem !important;
  }

  .lg\:-mx-60 {
    margin-left: -60rem !important;
    margin-right: -60rem !important;
  }

  .lg\:-mt-60 {
    margin-top: -60rem !important;
  }

  .lg\:-mb-60 {
    margin-bottom: -60rem !important;
  }

  .lg\:-ml-60 {
    margin-left: -60rem !important;
  }

  .lg\:-mr-60 {
    margin-right: -60rem !important;
  }

  .lg\:m-70 {
    margin: 70rem !important;
  }

  .lg\:my-70 {
    margin-top: 70rem !important;
    margin-bottom: 70rem !important;
  }

  .lg\:mx-70 {
    margin-left: 70rem !important;
    margin-right: 70rem !important;
  }

  .lg\:mt-70 {
    margin-top: 70rem !important;
  }

  .lg\:mb-70 {
    margin-bottom: 70rem !important;
  }

  .lg\:mr-70 {
    margin-right: 70rem !important;
  }

  .lg\:ml-70 {
    margin-left: 70rem !important;
  }

  .lg\:-m-70 {
    margin: -70rem !important;
  }

  .lg\:-mx-70 {
    margin-left: -70rem !important;
    margin-right: -70rem !important;
  }

  .lg\:-mt-70 {
    margin-top: -70rem !important;
  }

  .lg\:-mb-70 {
    margin-bottom: -70rem !important;
  }

  .lg\:-ml-70 {
    margin-left: -70rem !important;
  }

  .lg\:-mr-70 {
    margin-right: -70rem !important;
  }

  .lg\:m-80 {
    margin: 80rem !important;
  }

  .lg\:my-80 {
    margin-top: 80rem !important;
    margin-bottom: 80rem !important;
  }

  .lg\:mx-80 {
    margin-left: 80rem !important;
    margin-right: 80rem !important;
  }

  .lg\:mt-80 {
    margin-top: 80rem !important;
  }

  .lg\:mb-80 {
    margin-bottom: 80rem !important;
  }

  .lg\:mr-80 {
    margin-right: 80rem !important;
  }

  .lg\:ml-80 {
    margin-left: 80rem !important;
  }

  .lg\:-m-80 {
    margin: -80rem !important;
  }

  .lg\:-mx-80 {
    margin-left: -80rem !important;
    margin-right: -80rem !important;
  }

  .lg\:-mt-80 {
    margin-top: -80rem !important;
  }

  .lg\:-mb-80 {
    margin-bottom: -80rem !important;
  }

  .lg\:-ml-80 {
    margin-left: -80rem !important;
  }

  .lg\:-mr-80 {
    margin-right: -80rem !important;
  }

  .lg\:m-90 {
    margin: 90rem !important;
  }

  .lg\:my-90 {
    margin-top: 90rem !important;
    margin-bottom: 90rem !important;
  }

  .lg\:mx-90 {
    margin-left: 90rem !important;
    margin-right: 90rem !important;
  }

  .lg\:mt-90 {
    margin-top: 90rem !important;
  }

  .lg\:mb-90 {
    margin-bottom: 90rem !important;
  }

  .lg\:mr-90 {
    margin-right: 90rem !important;
  }

  .lg\:ml-90 {
    margin-left: 90rem !important;
  }

  .lg\:-m-90 {
    margin: -90rem !important;
  }

  .lg\:-mx-90 {
    margin-left: -90rem !important;
    margin-right: -90rem !important;
  }

  .lg\:-mt-90 {
    margin-top: -90rem !important;
  }

  .lg\:-mb-90 {
    margin-bottom: -90rem !important;
  }

  .lg\:-ml-90 {
    margin-left: -90rem !important;
  }

  .lg\:-mr-90 {
    margin-right: -90rem !important;
  }

  .lg\:m-100 {
    margin: 100rem !important;
  }

  .lg\:my-100 {
    margin-top: 100rem !important;
    margin-bottom: 100rem !important;
  }

  .lg\:mx-100 {
    margin-left: 100rem !important;
    margin-right: 100rem !important;
  }

  .lg\:mt-100 {
    margin-top: 100rem !important;
  }

  .lg\:mb-100 {
    margin-bottom: 100rem !important;
  }

  .lg\:mr-100 {
    margin-right: 100rem !important;
  }

  .lg\:ml-100 {
    margin-left: 100rem !important;
  }

  .lg\:-m-100 {
    margin: -100rem !important;
  }

  .lg\:-mx-100 {
    margin-left: -100rem !important;
    margin-right: -100rem !important;
  }

  .lg\:-mt-100 {
    margin-top: -100rem !important;
  }

  .lg\:-mb-100 {
    margin-bottom: -100rem !important;
  }

  .lg\:-ml-100 {
    margin-left: -100rem !important;
  }

  .lg\:-mr-100 {
    margin-right: -100rem !important;
  }

  .lg\:m-10p {
    margin: 10% !important;
  }

  .lg\:my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

  .lg\:mx-10p {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .lg\:mt-10p {
    margin-top: 10% !important;
  }

  .lg\:mb-10p {
    margin-bottom: 10% !important;
  }

  .lg\:mr-10p {
    margin-right: 10% !important;
  }

  .lg\:ml-10p {
    margin-left: 10% !important;
  }

  .lg\:-m-10p {
    margin: -10% !important;
  }

  .lg\:-mx-10p {
    margin-left: -10% !important;
    margin-right: -10% !important;
  }

  .lg\:-mt-10p {
    margin-top: -10% !important;
  }

  .lg\:-mb-10p {
    margin-bottom: -10% !important;
  }

  .lg\:-ml-10p {
    margin-left: -10% !important;
  }

  .lg\:-mr-10p {
    margin-right: -10% !important;
  }

  .lg\:m-20p {
    margin: 20% !important;
  }

  .lg\:my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }

  .lg\:mx-20p {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .lg\:mt-20p {
    margin-top: 20% !important;
  }

  .lg\:mb-20p {
    margin-bottom: 20% !important;
  }

  .lg\:mr-20p {
    margin-right: 20% !important;
  }

  .lg\:ml-20p {
    margin-left: 20% !important;
  }

  .lg\:-m-20p {
    margin: -20% !important;
  }

  .lg\:-mx-20p {
    margin-left: -20% !important;
    margin-right: -20% !important;
  }

  .lg\:-mt-20p {
    margin-top: -20% !important;
  }

  .lg\:-mb-20p {
    margin-bottom: -20% !important;
  }

  .lg\:-ml-20p {
    margin-left: -20% !important;
  }

  .lg\:-mr-20p {
    margin-right: -20% !important;
  }

  .lg\:m-30p {
    margin: 30% !important;
  }

  .lg\:my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }

  .lg\:mx-30p {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }

  .lg\:mt-30p {
    margin-top: 30% !important;
  }

  .lg\:mb-30p {
    margin-bottom: 30% !important;
  }

  .lg\:mr-30p {
    margin-right: 30% !important;
  }

  .lg\:ml-30p {
    margin-left: 30% !important;
  }

  .lg\:-m-30p {
    margin: -30% !important;
  }

  .lg\:-mx-30p {
    margin-left: -30% !important;
    margin-right: -30% !important;
  }

  .lg\:-mt-30p {
    margin-top: -30% !important;
  }

  .lg\:-mb-30p {
    margin-bottom: -30% !important;
  }

  .lg\:-ml-30p {
    margin-left: -30% !important;
  }

  .lg\:-mr-30p {
    margin-right: -30% !important;
  }

  .lg\:m-40p {
    margin: 40% !important;
  }

  .lg\:my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }

  .lg\:mx-40p {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }

  .lg\:mt-40p {
    margin-top: 40% !important;
  }

  .lg\:mb-40p {
    margin-bottom: 40% !important;
  }

  .lg\:mr-40p {
    margin-right: 40% !important;
  }

  .lg\:ml-40p {
    margin-left: 40% !important;
  }

  .lg\:-m-40p {
    margin: -40% !important;
  }

  .lg\:-mx-40p {
    margin-left: -40% !important;
    margin-right: -40% !important;
  }

  .lg\:-mt-40p {
    margin-top: -40% !important;
  }

  .lg\:-mb-40p {
    margin-bottom: -40% !important;
  }

  .lg\:-ml-40p {
    margin-left: -40% !important;
  }

  .lg\:-mr-40p {
    margin-right: -40% !important;
  }

  .lg\:m-50p {
    margin: 50% !important;
  }

  .lg\:my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }

  .lg\:mx-50p {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }

  .lg\:mt-50p {
    margin-top: 50% !important;
  }

  .lg\:mb-50p {
    margin-bottom: 50% !important;
  }

  .lg\:mr-50p {
    margin-right: 50% !important;
  }

  .lg\:ml-50p {
    margin-left: 50% !important;
  }

  .lg\:-m-50p {
    margin: -50% !important;
  }

  .lg\:-mx-50p {
    margin-left: -50% !important;
    margin-right: -50% !important;
  }

  .lg\:-mt-50p {
    margin-top: -50% !important;
  }

  .lg\:-mb-50p {
    margin-bottom: -50% !important;
  }

  .lg\:-ml-50p {
    margin-left: -50% !important;
  }

  .lg\:-mr-50p {
    margin-right: -50% !important;
  }

  .lg\:m-60p {
    margin: 60% !important;
  }

  .lg\:my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }

  .lg\:mx-60p {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }

  .lg\:mt-60p {
    margin-top: 60% !important;
  }

  .lg\:mb-60p {
    margin-bottom: 60% !important;
  }

  .lg\:mr-60p {
    margin-right: 60% !important;
  }

  .lg\:ml-60p {
    margin-left: 60% !important;
  }

  .lg\:-m-60p {
    margin: -60% !important;
  }

  .lg\:-mx-60p {
    margin-left: -60% !important;
    margin-right: -60% !important;
  }

  .lg\:-mt-60p {
    margin-top: -60% !important;
  }

  .lg\:-mb-60p {
    margin-bottom: -60% !important;
  }

  .lg\:-ml-60p {
    margin-left: -60% !important;
  }

  .lg\:-mr-60p {
    margin-right: -60% !important;
  }

  .lg\:m-70p {
    margin: 70% !important;
  }

  .lg\:my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }

  .lg\:mx-70p {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }

  .lg\:mt-70p {
    margin-top: 70% !important;
  }

  .lg\:mb-70p {
    margin-bottom: 70% !important;
  }

  .lg\:mr-70p {
    margin-right: 70% !important;
  }

  .lg\:ml-70p {
    margin-left: 70% !important;
  }

  .lg\:-m-70p {
    margin: -70% !important;
  }

  .lg\:-mx-70p {
    margin-left: -70% !important;
    margin-right: -70% !important;
  }

  .lg\:-mt-70p {
    margin-top: -70% !important;
  }

  .lg\:-mb-70p {
    margin-bottom: -70% !important;
  }

  .lg\:-ml-70p {
    margin-left: -70% !important;
  }

  .lg\:-mr-70p {
    margin-right: -70% !important;
  }

  .lg\:m-80p {
    margin: 80% !important;
  }

  .lg\:my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }

  .lg\:mx-80p {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }

  .lg\:mt-80p {
    margin-top: 80% !important;
  }

  .lg\:mb-80p {
    margin-bottom: 80% !important;
  }

  .lg\:mr-80p {
    margin-right: 80% !important;
  }

  .lg\:ml-80p {
    margin-left: 80% !important;
  }

  .lg\:-m-80p {
    margin: -80% !important;
  }

  .lg\:-mx-80p {
    margin-left: -80% !important;
    margin-right: -80% !important;
  }

  .lg\:-mt-80p {
    margin-top: -80% !important;
  }

  .lg\:-mb-80p {
    margin-bottom: -80% !important;
  }

  .lg\:-ml-80p {
    margin-left: -80% !important;
  }

  .lg\:-mr-80p {
    margin-right: -80% !important;
  }

  .lg\:m-90p {
    margin: 90% !important;
  }

  .lg\:my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }

  .lg\:mx-90p {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }

  .lg\:mt-90p {
    margin-top: 90% !important;
  }

  .lg\:mb-90p {
    margin-bottom: 90% !important;
  }

  .lg\:mr-90p {
    margin-right: 90% !important;
  }

  .lg\:ml-90p {
    margin-left: 90% !important;
  }

  .lg\:-m-90p {
    margin: -90% !important;
  }

  .lg\:-mx-90p {
    margin-left: -90% !important;
    margin-right: -90% !important;
  }

  .lg\:-mt-90p {
    margin-top: -90% !important;
  }

  .lg\:-mb-90p {
    margin-bottom: -90% !important;
  }

  .lg\:-ml-90p {
    margin-left: -90% !important;
  }

  .lg\:-mr-90p {
    margin-right: -90% !important;
  }

  .lg\:m-100p {
    margin: 100% !important;
  }

  .lg\:my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }

  .lg\:mx-100p {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }

  .lg\:mt-100p {
    margin-top: 100% !important;
  }

  .lg\:mb-100p {
    margin-bottom: 100% !important;
  }

  .lg\:mr-100p {
    margin-right: 100% !important;
  }

  .lg\:ml-100p {
    margin-left: 100% !important;
  }

  .lg\:-m-100p {
    margin: -100% !important;
  }

  .lg\:-mx-100p {
    margin-left: -100% !important;
    margin-right: -100% !important;
  }

  .lg\:-mt-100p {
    margin-top: -100% !important;
  }

  .lg\:-mb-100p {
    margin-bottom: -100% !important;
  }

  .lg\:-ml-100p {
    margin-left: -100% !important;
  }

  .lg\:-mr-100p {
    margin-right: -100% !important;
  }

  .lg\:fixed {
    position: fixed !important;
  }

  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:relative {
    position: relative !important;
  }

  .lg\:sticky {
    position: sticky !important;
  }

  .lg\:top-0 {
    top: 0 !important;
  }

  .lg\:right-0 {
    right: 0 !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:trbl-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-10 {
    z-index: 10 !important;
  }

  .lg\:z-20 {
    z-index: 20 !important;
  }

  .lg\:z-100 {
    z-index: 100 !important;
  }

  .-lg\:z-1 {
    z-index: -1 !important;
  }

  .lg\:border-0 {
    border: 0 !important;
  }

  .lg\:round {
    border-radius: 100vw !important;
  }

  .lg\:rounded-0 {
    border-radius: 0 !important;
  }

  .lg\:rounded-xxl {
    border-radius: 2rem !important;
  }

  .lg\:rounded-xl {
    border-radius: 1rem !important;
  }

  .lg\:rounded-lg {
    border-radius: 0.75rem !important;
  }

  .lg\:rounded-md {
    border-radius: 0.5rem !important;
  }

  .lg\:rounded-sm {
    border-radius: 0.25rem !important;
  }

  .lg\:rounded-xs {
    border-radius: 0.125rem !important;
  }
}
@media (min-width: 1460px) {
  .xl\:flex {
    display: flex !important;
  }

  .xl\:inline-flex {
    display: inline-flex  !important;
  }

  .xl\:block {
    display: block !important;
  }

  .xl\:inline-block {
    display: inline-block !important;
  }

  .xl\:hidden,
.xl\:dnone {
    display: none !important;
  }

  .xl\:flex-column {
    flex-direction: column;
  }

  .xl\:flex-column.reverse,
.xl\:column-reverse {
    flex-direction: column-reverse;
  }

  .xl\:flex-1 {
    flex: 1 1 0%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:row-reverse,
.xl\:reverse {
    flex-direction: row-reverse;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:items-center,
.xl\:y-center,
.xl\:vertical-center {
    align-items: center;
  }

  .xl\:flex-start,
.xl\:items-start {
    align-items: flex-start;
  }

  .xl\:flex-end,
.xl\:items-end {
    align-items: flex-end;
  }

  .xl\:center {
    align-items: center;
    text-align: center;
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:content-center {
    align-content: center;
  }

  .xl\:content-end {
    align-content: flex-end;
  }

  .xl\:content-between {
    align-content: space-between;
  }

  .xl\:content-around {
    align-content: space-around;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-center,
.xl\:x-center,
.xl\:horizontal-center {
    justify-content: center;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:space-between,
.xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:space-around,
.xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }

  .xl\:text-uppercase {
    text-transform: uppercase !important;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }

  .xl\:font-size-xxl {
    font-size: 5rem !important;
  }

  .xl\:font-size-xl {
    font-size: 2rem !important;
  }

  .xl\:font-size-lg {
    font-size: 1.5rem !important;
  }

  .xl\:font-size-md {
    font-size: 1.25rem !important;
  }

  .xl\:font-size-default {
    font-size: 1rem !important;
  }

  .xl\:font-size-sm {
    font-size: 0.75rem !important;
  }

  .xl\:font-size-xs {
    font-size: 0.5rem !important;
  }

  .xl\:w-1\/4,
.xl\:w-25 {
    width: 25% !important;
  }

  .xl\:w-1\/3,
.xl\:w-33 {
    width: 33.33% !important;
  }

  .xl\:w-1\/2,
.xl\:w-2\/4,
.xl\:w-half,
.xl\:w-50 {
    width: 50% !important;
  }

  .xl\:w-2\/3,
.xl\:w-66 {
    width: 66.66% !important;
  }

  .xl\:w-3\/4,
.xl\:w-75 {
    width: 75% !important;
  }

  .xl\:w-100,
.xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:mxw-xs {
    max-width: 600px !important;
  }

  .xl\:mxw-sm {
    max-width: 782px !important;
  }

  .xl\:mxw-md {
    max-width: 1024px !important;
  }

  .xl\:mxw-lg {
    max-width: 1220px !important;
  }

  .xl\:mxw-xl {
    max-width: 1460px !important;
  }

  .xl\:h-100 {
    height: 100% !important;
  }

  .xl\:h-100vh {
    height: 100vh !important;
  }

  .xl\:h-50 {
    height: 50% !important;
  }

  .xl\:h-50vh {
    height: 50vh !important;
  }

  .xl\:h-inherit {
    height: inherit !important;
  }

  .xl\:color-primary {
    color: #25373e !important;
  }

  .xl\:bg-primary {
    background-color: #25373e !important;
  }

  .xl\:color-secondary {
    color: #e9a844 !important;
  }

  .xl\:bg-secondary {
    background-color: #e9a844 !important;
  }

  .xl\:color-tertiary {
    color: #3d6574 !important;
  }

  .xl\:bg-tertiary {
    background-color: #3d6574 !important;
  }

  .xl\:color-white {
    color: #fff !important;
  }

  .xl\:bg-white {
    background-color: #fff !important;
  }

  .xl\:color-light-primary {
    color: #25373eed !important;
  }

  .xl\:bg-light-primary {
    background-color: #25373eed !important;
  }

  .xl\:color-extra-light-primary {
    color: #25383d0f !important;
  }

  .xl\:bg-extra-light-primary {
    background-color: #25383d0f !important;
  }

  .xl\:color-light-secondary {
    color: #e9a8449c !important;
  }

  .xl\:bg-light-secondary {
    background-color: #e9a8449c !important;
  }

  .xl\:p-none {
    padding: 0 !important;
  }

  .xl\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:p-xs {
    padding: 0.25rem !important;
  }

  .xl\:px-xs {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:py-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:pr-xs {
    padding-right: 0.25rem !important;
  }

  .xl\:pl-xs {
    padding-left: 0.25rem !important;
  }

  .xl\:pt-xs {
    padding-top: 0.25rem !important;
  }

  .xl\:pb-xs {
    padding-bottom: 0.25rem !important;
  }

  .xl\:p-sm {
    padding: 0.5rem !important;
  }

  .xl\:px-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:py-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:pr-sm {
    padding-right: 0.5rem !important;
  }

  .xl\:pl-sm {
    padding-left: 0.5rem !important;
  }

  .xl\:pt-sm {
    padding-top: 0.5rem !important;
  }

  .xl\:pb-sm {
    padding-bottom: 0.5rem !important;
  }

  .xl\:p-1 {
    padding: 1rem !important;
  }

  .xl\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:pr-1 {
    padding-right: 1rem !important;
  }

  .xl\:pl-1 {
    padding-left: 1rem !important;
  }

  .xl\:pt-1 {
    padding-top: 1rem !important;
  }

  .xl\:pb-1 {
    padding-bottom: 1rem !important;
  }

  .xl\:p-2 {
    padding: 2rem !important;
  }

  .xl\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:pr-2 {
    padding-right: 2rem !important;
  }

  .xl\:pl-2 {
    padding-left: 2rem !important;
  }

  .xl\:pt-2 {
    padding-top: 2rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: 2rem !important;
  }

  .xl\:p-3 {
    padding: 3rem !important;
  }

  .xl\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:pr-3 {
    padding-right: 3rem !important;
  }

  .xl\:pl-3 {
    padding-left: 3rem !important;
  }

  .xl\:pt-3 {
    padding-top: 3rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 3rem !important;
  }

  .xl\:p-4 {
    padding: 4rem !important;
  }

  .xl\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:pr-4 {
    padding-right: 4rem !important;
  }

  .xl\:pl-4 {
    padding-left: 4rem !important;
  }

  .xl\:pt-4 {
    padding-top: 4rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 4rem !important;
  }

  .xl\:p-5 {
    padding: 5rem !important;
  }

  .xl\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xl\:pr-5 {
    padding-right: 5rem !important;
  }

  .xl\:pl-5 {
    padding-left: 5rem !important;
  }

  .xl\:pt-5 {
    padding-top: 5rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 5rem !important;
  }

  .xl\:p-6 {
    padding: 6rem !important;
  }

  .xl\:px-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xl\:py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xl\:pr-6 {
    padding-right: 6rem !important;
  }

  .xl\:pl-6 {
    padding-left: 6rem !important;
  }

  .xl\:pt-6 {
    padding-top: 6rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 6rem !important;
  }

  .xl\:p-7 {
    padding: 7rem !important;
  }

  .xl\:px-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xl\:py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xl\:pr-7 {
    padding-right: 7rem !important;
  }

  .xl\:pl-7 {
    padding-left: 7rem !important;
  }

  .xl\:pt-7 {
    padding-top: 7rem !important;
  }

  .xl\:pb-7 {
    padding-bottom: 7rem !important;
  }

  .xl\:p-8 {
    padding: 8rem !important;
  }

  .xl\:px-8 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xl\:py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xl\:pr-8 {
    padding-right: 8rem !important;
  }

  .xl\:pl-8 {
    padding-left: 8rem !important;
  }

  .xl\:pt-8 {
    padding-top: 8rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 8rem !important;
  }

  .xl\:p-9 {
    padding: 9rem !important;
  }

  .xl\:px-9 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xl\:py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xl\:pr-9 {
    padding-right: 9rem !important;
  }

  .xl\:pl-9 {
    padding-left: 9rem !important;
  }

  .xl\:pt-9 {
    padding-top: 9rem !important;
  }

  .xl\:pb-9 {
    padding-bottom: 9rem !important;
  }

  .xl\:p-10 {
    padding: 10rem !important;
  }

  .xl\:px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xl\:py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xl\:pr-10 {
    padding-right: 10rem !important;
  }

  .xl\:pl-10 {
    padding-left: 10rem !important;
  }

  .xl\:pt-10 {
    padding-top: 10rem !important;
  }

  .xl\:pb-10 {
    padding-bottom: 10rem !important;
  }

  .xl\:p-15 {
    padding: 15rem !important;
  }

  .xl\:px-15 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .xl\:py-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .xl\:pr-15 {
    padding-right: 15rem !important;
  }

  .xl\:pl-15 {
    padding-left: 15rem !important;
  }

  .xl\:pt-15 {
    padding-top: 15rem !important;
  }

  .xl\:pb-15 {
    padding-bottom: 15rem !important;
  }

  .xl\:p-20 {
    padding: 20rem !important;
  }

  .xl\:px-20 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .xl\:py-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .xl\:pr-20 {
    padding-right: 20rem !important;
  }

  .xl\:pl-20 {
    padding-left: 20rem !important;
  }

  .xl\:pt-20 {
    padding-top: 20rem !important;
  }

  .xl\:pb-20 {
    padding-bottom: 20rem !important;
  }

  .xl\:p-30 {
    padding: 30rem !important;
  }

  .xl\:px-30 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
  }

  .xl\:py-30 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .xl\:pr-30 {
    padding-right: 30rem !important;
  }

  .xl\:pl-30 {
    padding-left: 30rem !important;
  }

  .xl\:pt-30 {
    padding-top: 30rem !important;
  }

  .xl\:pb-30 {
    padding-bottom: 30rem !important;
  }

  .xl\:p-40 {
    padding: 40rem !important;
  }

  .xl\:px-40 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
  }

  .xl\:py-40 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
  }

  .xl\:pr-40 {
    padding-right: 40rem !important;
  }

  .xl\:pl-40 {
    padding-left: 40rem !important;
  }

  .xl\:pt-40 {
    padding-top: 40rem !important;
  }

  .xl\:pb-40 {
    padding-bottom: 40rem !important;
  }

  .xl\:p-50 {
    padding: 50rem !important;
  }

  .xl\:px-50 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
  }

  .xl\:py-50 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
  }

  .xl\:pr-50 {
    padding-right: 50rem !important;
  }

  .xl\:pl-50 {
    padding-left: 50rem !important;
  }

  .xl\:pt-50 {
    padding-top: 50rem !important;
  }

  .xl\:pb-50 {
    padding-bottom: 50rem !important;
  }

  .xl\:p-60 {
    padding: 60rem !important;
  }

  .xl\:px-60 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
  }

  .xl\:py-60 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
  }

  .xl\:pr-60 {
    padding-right: 60rem !important;
  }

  .xl\:pl-60 {
    padding-left: 60rem !important;
  }

  .xl\:pt-60 {
    padding-top: 60rem !important;
  }

  .xl\:pb-60 {
    padding-bottom: 60rem !important;
  }

  .xl\:p-70 {
    padding: 70rem !important;
  }

  .xl\:px-70 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
  }

  .xl\:py-70 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
  }

  .xl\:pr-70 {
    padding-right: 70rem !important;
  }

  .xl\:pl-70 {
    padding-left: 70rem !important;
  }

  .xl\:pt-70 {
    padding-top: 70rem !important;
  }

  .xl\:pb-70 {
    padding-bottom: 70rem !important;
  }

  .xl\:p-80 {
    padding: 80rem !important;
  }

  .xl\:px-80 {
    padding-left: 80rem !important;
    padding-right: 80rem !important;
  }

  .xl\:py-80 {
    padding-top: 80rem !important;
    padding-bottom: 80rem !important;
  }

  .xl\:pr-80 {
    padding-right: 80rem !important;
  }

  .xl\:pl-80 {
    padding-left: 80rem !important;
  }

  .xl\:pt-80 {
    padding-top: 80rem !important;
  }

  .xl\:pb-80 {
    padding-bottom: 80rem !important;
  }

  .xl\:p-90 {
    padding: 90rem !important;
  }

  .xl\:px-90 {
    padding-left: 90rem !important;
    padding-right: 90rem !important;
  }

  .xl\:py-90 {
    padding-top: 90rem !important;
    padding-bottom: 90rem !important;
  }

  .xl\:pr-90 {
    padding-right: 90rem !important;
  }

  .xl\:pl-90 {
    padding-left: 90rem !important;
  }

  .xl\:pt-90 {
    padding-top: 90rem !important;
  }

  .xl\:pb-90 {
    padding-bottom: 90rem !important;
  }

  .xl\:p-100 {
    padding: 100rem !important;
  }

  .xl\:px-100 {
    padding-left: 100rem !important;
    padding-right: 100rem !important;
  }

  .xl\:py-100 {
    padding-top: 100rem !important;
    padding-bottom: 100rem !important;
  }

  .xl\:pr-100 {
    padding-right: 100rem !important;
  }

  .xl\:pl-100 {
    padding-left: 100rem !important;
  }

  .xl\:pt-100 {
    padding-top: 100rem !important;
  }

  .xl\:pb-100 {
    padding-bottom: 100rem !important;
  }

  .xl\:p-10p {
    padding: 10% !important;
  }

  .xl\:px-10p {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .xl\:py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }

  .xl\:pr-10p {
    padding-right: 10% !important;
  }

  .xl\:pl-10p {
    padding-left: 10% !important;
  }

  .xl\:pt-10p {
    padding-top: 10% !important;
  }

  .xl\:pb-10p {
    padding-bottom: 10% !important;
  }

  .xl\:p-20p {
    padding: 20% !important;
  }

  .xl\:px-20p {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .xl\:py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }

  .xl\:pr-20p {
    padding-right: 20% !important;
  }

  .xl\:pl-20p {
    padding-left: 20% !important;
  }

  .xl\:pt-20p {
    padding-top: 20% !important;
  }

  .xl\:pb-20p {
    padding-bottom: 20% !important;
  }

  .xl\:p-30p {
    padding: 30% !important;
  }

  .xl\:px-30p {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }

  .xl\:py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }

  .xl\:pr-30p {
    padding-right: 30% !important;
  }

  .xl\:pl-30p {
    padding-left: 30% !important;
  }

  .xl\:pt-30p {
    padding-top: 30% !important;
  }

  .xl\:pb-30p {
    padding-bottom: 30% !important;
  }

  .xl\:p-40p {
    padding: 40% !important;
  }

  .xl\:px-40p {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }

  .xl\:py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }

  .xl\:pr-40p {
    padding-right: 40% !important;
  }

  .xl\:pl-40p {
    padding-left: 40% !important;
  }

  .xl\:pt-40p {
    padding-top: 40% !important;
  }

  .xl\:pb-40p {
    padding-bottom: 40% !important;
  }

  .xl\:p-50p {
    padding: 50% !important;
  }

  .xl\:px-50p {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }

  .xl\:py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }

  .xl\:pr-50p {
    padding-right: 50% !important;
  }

  .xl\:pl-50p {
    padding-left: 50% !important;
  }

  .xl\:pt-50p {
    padding-top: 50% !important;
  }

  .xl\:pb-50p {
    padding-bottom: 50% !important;
  }

  .xl\:p-60p {
    padding: 60% !important;
  }

  .xl\:px-60p {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }

  .xl\:py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }

  .xl\:pr-60p {
    padding-right: 60% !important;
  }

  .xl\:pl-60p {
    padding-left: 60% !important;
  }

  .xl\:pt-60p {
    padding-top: 60% !important;
  }

  .xl\:pb-60p {
    padding-bottom: 60% !important;
  }

  .xl\:p-70p {
    padding: 70% !important;
  }

  .xl\:px-70p {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }

  .xl\:py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }

  .xl\:pr-70p {
    padding-right: 70% !important;
  }

  .xl\:pl-70p {
    padding-left: 70% !important;
  }

  .xl\:pt-70p {
    padding-top: 70% !important;
  }

  .xl\:pb-70p {
    padding-bottom: 70% !important;
  }

  .xl\:p-80p {
    padding: 80% !important;
  }

  .xl\:px-80p {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }

  .xl\:py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }

  .xl\:pr-80p {
    padding-right: 80% !important;
  }

  .xl\:pl-80p {
    padding-left: 80% !important;
  }

  .xl\:pt-80p {
    padding-top: 80% !important;
  }

  .xl\:pb-80p {
    padding-bottom: 80% !important;
  }

  .xl\:p-90p {
    padding: 90% !important;
  }

  .xl\:px-90p {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }

  .xl\:py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }

  .xl\:pr-90p {
    padding-right: 90% !important;
  }

  .xl\:pl-90p {
    padding-left: 90% !important;
  }

  .xl\:pt-90p {
    padding-top: 90% !important;
  }

  .xl\:pb-90p {
    padding-bottom: 90% !important;
  }

  .xl\:p-100p {
    padding: 100% !important;
  }

  .xl\:px-100p {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }

  .xl\:py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }

  .xl\:pr-100p {
    padding-right: 100% !important;
  }

  .xl\:pl-100p {
    padding-left: 100% !important;
  }

  .xl\:pt-100p {
    padding-top: 100% !important;
  }

  .xl\:pb-100p {
    padding-bottom: 100% !important;
  }

  .xl\:m-none {
    margin: 0 !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:m-xs {
    margin: 0.25rem !important;
  }

  .xl\:my-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:mx-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:mt-xs {
    margin-top: 0.25rem !important;
  }

  .xl\:mb-xs {
    margin-bottom: 0.25rem !important;
  }

  .xl\:mr-xs {
    margin-right: 0.25rem !important;
  }

  .xl\:ml-xs {
    margin-left: 0.25rem !important;
  }

  .xl\:-m-xs {
    margin: -0.25rem !important;
  }

  .xl\:-mx-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:-mt-xs {
    margin-top: -0.25rem !important;
  }

  .xl\:-mb-xs {
    margin-bottom: -0.25rem !important;
  }

  .xl\:-ml-xs {
    margin-left: -0.25rem !important;
  }

  .xl\:-mr-xs {
    margin-right: -0.25rem !important;
  }

  .xl\:m-sm {
    margin: 0.5rem !important;
  }

  .xl\:my-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:mx-sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:mt-sm {
    margin-top: 0.5rem !important;
  }

  .xl\:mb-sm {
    margin-bottom: 0.5rem !important;
  }

  .xl\:mr-sm {
    margin-right: 0.5rem !important;
  }

  .xl\:ml-sm {
    margin-left: 0.5rem !important;
  }

  .xl\:-m-sm {
    margin: -0.5rem !important;
  }

  .xl\:-mx-sm {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:-mt-sm {
    margin-top: -0.5rem !important;
  }

  .xl\:-mb-sm {
    margin-bottom: -0.5rem !important;
  }

  .xl\:-ml-sm {
    margin-left: -0.5rem !important;
  }

  .xl\:-mr-sm {
    margin-right: -0.5rem !important;
  }

  .xl\:m-1 {
    margin: 1rem !important;
  }

  .xl\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:mt-1 {
    margin-top: 1rem !important;
  }

  .xl\:mb-1 {
    margin-bottom: 1rem !important;
  }

  .xl\:mr-1 {
    margin-right: 1rem !important;
  }

  .xl\:ml-1 {
    margin-left: 1rem !important;
  }

  .xl\:-m-1 {
    margin: -1rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-mt-1 {
    margin-top: -1rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -1rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -1rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -1rem !important;
  }

  .xl\:m-2 {
    margin: 2rem !important;
  }

  .xl\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:mt-2 {
    margin-top: 2rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: 2rem !important;
  }

  .xl\:mr-2 {
    margin-right: 2rem !important;
  }

  .xl\:ml-2 {
    margin-left: 2rem !important;
  }

  .xl\:-m-2 {
    margin: -2rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -2rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -2rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -2rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -2rem !important;
  }

  .xl\:m-3 {
    margin: 3rem !important;
  }

  .xl\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:mt-3 {
    margin-top: 3rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 3rem !important;
  }

  .xl\:mr-3 {
    margin-right: 3rem !important;
  }

  .xl\:ml-3 {
    margin-left: 3rem !important;
  }

  .xl\:-m-3 {
    margin: -3rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -3rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -3rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -3rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -3rem !important;
  }

  .xl\:m-4 {
    margin: 4rem !important;
  }

  .xl\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:mt-4 {
    margin-top: 4rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 4rem !important;
  }

  .xl\:mr-4 {
    margin-right: 4rem !important;
  }

  .xl\:ml-4 {
    margin-left: 4rem !important;
  }

  .xl\:-m-4 {
    margin: -4rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -4rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -4rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -4rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -4rem !important;
  }

  .xl\:m-5 {
    margin: 5rem !important;
  }

  .xl\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:mt-5 {
    margin-top: 5rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 5rem !important;
  }

  .xl\:mr-5 {
    margin-right: 5rem !important;
  }

  .xl\:ml-5 {
    margin-left: 5rem !important;
  }

  .xl\:-m-5 {
    margin: -5rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -5rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -5rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -5rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -5rem !important;
  }

  .xl\:m-6 {
    margin: 6rem !important;
  }

  .xl\:my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xl\:mx-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xl\:mt-6 {
    margin-top: 6rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 6rem !important;
  }

  .xl\:mr-6 {
    margin-right: 6rem !important;
  }

  .xl\:ml-6 {
    margin-left: 6rem !important;
  }

  .xl\:-m-6 {
    margin: -6rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -6rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -6rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -6rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -6rem !important;
  }

  .xl\:m-7 {
    margin: 7rem !important;
  }

  .xl\:my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xl\:mx-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xl\:mt-7 {
    margin-top: 7rem !important;
  }

  .xl\:mb-7 {
    margin-bottom: 7rem !important;
  }

  .xl\:mr-7 {
    margin-right: 7rem !important;
  }

  .xl\:ml-7 {
    margin-left: 7rem !important;
  }

  .xl\:-m-7 {
    margin: -7rem !important;
  }

  .xl\:-mx-7 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xl\:-mt-7 {
    margin-top: -7rem !important;
  }

  .xl\:-mb-7 {
    margin-bottom: -7rem !important;
  }

  .xl\:-ml-7 {
    margin-left: -7rem !important;
  }

  .xl\:-mr-7 {
    margin-right: -7rem !important;
  }

  .xl\:m-8 {
    margin: 8rem !important;
  }

  .xl\:my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xl\:mx-8 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xl\:mt-8 {
    margin-top: 8rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 8rem !important;
  }

  .xl\:mr-8 {
    margin-right: 8rem !important;
  }

  .xl\:ml-8 {
    margin-left: 8rem !important;
  }

  .xl\:-m-8 {
    margin: -8rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -8rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -8rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -8rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -8rem !important;
  }

  .xl\:m-9 {
    margin: 9rem !important;
  }

  .xl\:my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xl\:mx-9 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xl\:mt-9 {
    margin-top: 9rem !important;
  }

  .xl\:mb-9 {
    margin-bottom: 9rem !important;
  }

  .xl\:mr-9 {
    margin-right: 9rem !important;
  }

  .xl\:ml-9 {
    margin-left: 9rem !important;
  }

  .xl\:-m-9 {
    margin: -9rem !important;
  }

  .xl\:-mx-9 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xl\:-mt-9 {
    margin-top: -9rem !important;
  }

  .xl\:-mb-9 {
    margin-bottom: -9rem !important;
  }

  .xl\:-ml-9 {
    margin-left: -9rem !important;
  }

  .xl\:-mr-9 {
    margin-right: -9rem !important;
  }

  .xl\:m-10 {
    margin: 10rem !important;
  }

  .xl\:my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xl\:mx-10 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xl\:mt-10 {
    margin-top: 10rem !important;
  }

  .xl\:mb-10 {
    margin-bottom: 10rem !important;
  }

  .xl\:mr-10 {
    margin-right: 10rem !important;
  }

  .xl\:ml-10 {
    margin-left: 10rem !important;
  }

  .xl\:-m-10 {
    margin: -10rem !important;
  }

  .xl\:-mx-10 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xl\:-mt-10 {
    margin-top: -10rem !important;
  }

  .xl\:-mb-10 {
    margin-bottom: -10rem !important;
  }

  .xl\:-ml-10 {
    margin-left: -10rem !important;
  }

  .xl\:-mr-10 {
    margin-right: -10rem !important;
  }

  .xl\:m-15 {
    margin: 15rem !important;
  }

  .xl\:my-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .xl\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .xl\:mt-15 {
    margin-top: 15rem !important;
  }

  .xl\:mb-15 {
    margin-bottom: 15rem !important;
  }

  .xl\:mr-15 {
    margin-right: 15rem !important;
  }

  .xl\:ml-15 {
    margin-left: 15rem !important;
  }

  .xl\:-m-15 {
    margin: -15rem !important;
  }

  .xl\:-mx-15 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .xl\:-mt-15 {
    margin-top: -15rem !important;
  }

  .xl\:-mb-15 {
    margin-bottom: -15rem !important;
  }

  .xl\:-ml-15 {
    margin-left: -15rem !important;
  }

  .xl\:-mr-15 {
    margin-right: -15rem !important;
  }

  .xl\:m-20 {
    margin: 20rem !important;
  }

  .xl\:my-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .xl\:mx-20 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .xl\:mt-20 {
    margin-top: 20rem !important;
  }

  .xl\:mb-20 {
    margin-bottom: 20rem !important;
  }

  .xl\:mr-20 {
    margin-right: 20rem !important;
  }

  .xl\:ml-20 {
    margin-left: 20rem !important;
  }

  .xl\:-m-20 {
    margin: -20rem !important;
  }

  .xl\:-mx-20 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .xl\:-mt-20 {
    margin-top: -20rem !important;
  }

  .xl\:-mb-20 {
    margin-bottom: -20rem !important;
  }

  .xl\:-ml-20 {
    margin-left: -20rem !important;
  }

  .xl\:-mr-20 {
    margin-right: -20rem !important;
  }

  .xl\:m-30 {
    margin: 30rem !important;
  }

  .xl\:my-30 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .xl\:mx-30 {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }

  .xl\:mt-30 {
    margin-top: 30rem !important;
  }

  .xl\:mb-30 {
    margin-bottom: 30rem !important;
  }

  .xl\:mr-30 {
    margin-right: 30rem !important;
  }

  .xl\:ml-30 {
    margin-left: 30rem !important;
  }

  .xl\:-m-30 {
    margin: -30rem !important;
  }

  .xl\:-mx-30 {
    margin-left: -30rem !important;
    margin-right: -30rem !important;
  }

  .xl\:-mt-30 {
    margin-top: -30rem !important;
  }

  .xl\:-mb-30 {
    margin-bottom: -30rem !important;
  }

  .xl\:-ml-30 {
    margin-left: -30rem !important;
  }

  .xl\:-mr-30 {
    margin-right: -30rem !important;
  }

  .xl\:m-40 {
    margin: 40rem !important;
  }

  .xl\:my-40 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important;
  }

  .xl\:mx-40 {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }

  .xl\:mt-40 {
    margin-top: 40rem !important;
  }

  .xl\:mb-40 {
    margin-bottom: 40rem !important;
  }

  .xl\:mr-40 {
    margin-right: 40rem !important;
  }

  .xl\:ml-40 {
    margin-left: 40rem !important;
  }

  .xl\:-m-40 {
    margin: -40rem !important;
  }

  .xl\:-mx-40 {
    margin-left: -40rem !important;
    margin-right: -40rem !important;
  }

  .xl\:-mt-40 {
    margin-top: -40rem !important;
  }

  .xl\:-mb-40 {
    margin-bottom: -40rem !important;
  }

  .xl\:-ml-40 {
    margin-left: -40rem !important;
  }

  .xl\:-mr-40 {
    margin-right: -40rem !important;
  }

  .xl\:m-50 {
    margin: 50rem !important;
  }

  .xl\:my-50 {
    margin-top: 50rem !important;
    margin-bottom: 50rem !important;
  }

  .xl\:mx-50 {
    margin-left: 50rem !important;
    margin-right: 50rem !important;
  }

  .xl\:mt-50 {
    margin-top: 50rem !important;
  }

  .xl\:mb-50 {
    margin-bottom: 50rem !important;
  }

  .xl\:mr-50 {
    margin-right: 50rem !important;
  }

  .xl\:ml-50 {
    margin-left: 50rem !important;
  }

  .xl\:-m-50 {
    margin: -50rem !important;
  }

  .xl\:-mx-50 {
    margin-left: -50rem !important;
    margin-right: -50rem !important;
  }

  .xl\:-mt-50 {
    margin-top: -50rem !important;
  }

  .xl\:-mb-50 {
    margin-bottom: -50rem !important;
  }

  .xl\:-ml-50 {
    margin-left: -50rem !important;
  }

  .xl\:-mr-50 {
    margin-right: -50rem !important;
  }

  .xl\:m-60 {
    margin: 60rem !important;
  }

  .xl\:my-60 {
    margin-top: 60rem !important;
    margin-bottom: 60rem !important;
  }

  .xl\:mx-60 {
    margin-left: 60rem !important;
    margin-right: 60rem !important;
  }

  .xl\:mt-60 {
    margin-top: 60rem !important;
  }

  .xl\:mb-60 {
    margin-bottom: 60rem !important;
  }

  .xl\:mr-60 {
    margin-right: 60rem !important;
  }

  .xl\:ml-60 {
    margin-left: 60rem !important;
  }

  .xl\:-m-60 {
    margin: -60rem !important;
  }

  .xl\:-mx-60 {
    margin-left: -60rem !important;
    margin-right: -60rem !important;
  }

  .xl\:-mt-60 {
    margin-top: -60rem !important;
  }

  .xl\:-mb-60 {
    margin-bottom: -60rem !important;
  }

  .xl\:-ml-60 {
    margin-left: -60rem !important;
  }

  .xl\:-mr-60 {
    margin-right: -60rem !important;
  }

  .xl\:m-70 {
    margin: 70rem !important;
  }

  .xl\:my-70 {
    margin-top: 70rem !important;
    margin-bottom: 70rem !important;
  }

  .xl\:mx-70 {
    margin-left: 70rem !important;
    margin-right: 70rem !important;
  }

  .xl\:mt-70 {
    margin-top: 70rem !important;
  }

  .xl\:mb-70 {
    margin-bottom: 70rem !important;
  }

  .xl\:mr-70 {
    margin-right: 70rem !important;
  }

  .xl\:ml-70 {
    margin-left: 70rem !important;
  }

  .xl\:-m-70 {
    margin: -70rem !important;
  }

  .xl\:-mx-70 {
    margin-left: -70rem !important;
    margin-right: -70rem !important;
  }

  .xl\:-mt-70 {
    margin-top: -70rem !important;
  }

  .xl\:-mb-70 {
    margin-bottom: -70rem !important;
  }

  .xl\:-ml-70 {
    margin-left: -70rem !important;
  }

  .xl\:-mr-70 {
    margin-right: -70rem !important;
  }

  .xl\:m-80 {
    margin: 80rem !important;
  }

  .xl\:my-80 {
    margin-top: 80rem !important;
    margin-bottom: 80rem !important;
  }

  .xl\:mx-80 {
    margin-left: 80rem !important;
    margin-right: 80rem !important;
  }

  .xl\:mt-80 {
    margin-top: 80rem !important;
  }

  .xl\:mb-80 {
    margin-bottom: 80rem !important;
  }

  .xl\:mr-80 {
    margin-right: 80rem !important;
  }

  .xl\:ml-80 {
    margin-left: 80rem !important;
  }

  .xl\:-m-80 {
    margin: -80rem !important;
  }

  .xl\:-mx-80 {
    margin-left: -80rem !important;
    margin-right: -80rem !important;
  }

  .xl\:-mt-80 {
    margin-top: -80rem !important;
  }

  .xl\:-mb-80 {
    margin-bottom: -80rem !important;
  }

  .xl\:-ml-80 {
    margin-left: -80rem !important;
  }

  .xl\:-mr-80 {
    margin-right: -80rem !important;
  }

  .xl\:m-90 {
    margin: 90rem !important;
  }

  .xl\:my-90 {
    margin-top: 90rem !important;
    margin-bottom: 90rem !important;
  }

  .xl\:mx-90 {
    margin-left: 90rem !important;
    margin-right: 90rem !important;
  }

  .xl\:mt-90 {
    margin-top: 90rem !important;
  }

  .xl\:mb-90 {
    margin-bottom: 90rem !important;
  }

  .xl\:mr-90 {
    margin-right: 90rem !important;
  }

  .xl\:ml-90 {
    margin-left: 90rem !important;
  }

  .xl\:-m-90 {
    margin: -90rem !important;
  }

  .xl\:-mx-90 {
    margin-left: -90rem !important;
    margin-right: -90rem !important;
  }

  .xl\:-mt-90 {
    margin-top: -90rem !important;
  }

  .xl\:-mb-90 {
    margin-bottom: -90rem !important;
  }

  .xl\:-ml-90 {
    margin-left: -90rem !important;
  }

  .xl\:-mr-90 {
    margin-right: -90rem !important;
  }

  .xl\:m-100 {
    margin: 100rem !important;
  }

  .xl\:my-100 {
    margin-top: 100rem !important;
    margin-bottom: 100rem !important;
  }

  .xl\:mx-100 {
    margin-left: 100rem !important;
    margin-right: 100rem !important;
  }

  .xl\:mt-100 {
    margin-top: 100rem !important;
  }

  .xl\:mb-100 {
    margin-bottom: 100rem !important;
  }

  .xl\:mr-100 {
    margin-right: 100rem !important;
  }

  .xl\:ml-100 {
    margin-left: 100rem !important;
  }

  .xl\:-m-100 {
    margin: -100rem !important;
  }

  .xl\:-mx-100 {
    margin-left: -100rem !important;
    margin-right: -100rem !important;
  }

  .xl\:-mt-100 {
    margin-top: -100rem !important;
  }

  .xl\:-mb-100 {
    margin-bottom: -100rem !important;
  }

  .xl\:-ml-100 {
    margin-left: -100rem !important;
  }

  .xl\:-mr-100 {
    margin-right: -100rem !important;
  }

  .xl\:m-10p {
    margin: 10% !important;
  }

  .xl\:my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }

  .xl\:mx-10p {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .xl\:mt-10p {
    margin-top: 10% !important;
  }

  .xl\:mb-10p {
    margin-bottom: 10% !important;
  }

  .xl\:mr-10p {
    margin-right: 10% !important;
  }

  .xl\:ml-10p {
    margin-left: 10% !important;
  }

  .xl\:-m-10p {
    margin: -10% !important;
  }

  .xl\:-mx-10p {
    margin-left: -10% !important;
    margin-right: -10% !important;
  }

  .xl\:-mt-10p {
    margin-top: -10% !important;
  }

  .xl\:-mb-10p {
    margin-bottom: -10% !important;
  }

  .xl\:-ml-10p {
    margin-left: -10% !important;
  }

  .xl\:-mr-10p {
    margin-right: -10% !important;
  }

  .xl\:m-20p {
    margin: 20% !important;
  }

  .xl\:my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }

  .xl\:mx-20p {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .xl\:mt-20p {
    margin-top: 20% !important;
  }

  .xl\:mb-20p {
    margin-bottom: 20% !important;
  }

  .xl\:mr-20p {
    margin-right: 20% !important;
  }

  .xl\:ml-20p {
    margin-left: 20% !important;
  }

  .xl\:-m-20p {
    margin: -20% !important;
  }

  .xl\:-mx-20p {
    margin-left: -20% !important;
    margin-right: -20% !important;
  }

  .xl\:-mt-20p {
    margin-top: -20% !important;
  }

  .xl\:-mb-20p {
    margin-bottom: -20% !important;
  }

  .xl\:-ml-20p {
    margin-left: -20% !important;
  }

  .xl\:-mr-20p {
    margin-right: -20% !important;
  }

  .xl\:m-30p {
    margin: 30% !important;
  }

  .xl\:my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }

  .xl\:mx-30p {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }

  .xl\:mt-30p {
    margin-top: 30% !important;
  }

  .xl\:mb-30p {
    margin-bottom: 30% !important;
  }

  .xl\:mr-30p {
    margin-right: 30% !important;
  }

  .xl\:ml-30p {
    margin-left: 30% !important;
  }

  .xl\:-m-30p {
    margin: -30% !important;
  }

  .xl\:-mx-30p {
    margin-left: -30% !important;
    margin-right: -30% !important;
  }

  .xl\:-mt-30p {
    margin-top: -30% !important;
  }

  .xl\:-mb-30p {
    margin-bottom: -30% !important;
  }

  .xl\:-ml-30p {
    margin-left: -30% !important;
  }

  .xl\:-mr-30p {
    margin-right: -30% !important;
  }

  .xl\:m-40p {
    margin: 40% !important;
  }

  .xl\:my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }

  .xl\:mx-40p {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }

  .xl\:mt-40p {
    margin-top: 40% !important;
  }

  .xl\:mb-40p {
    margin-bottom: 40% !important;
  }

  .xl\:mr-40p {
    margin-right: 40% !important;
  }

  .xl\:ml-40p {
    margin-left: 40% !important;
  }

  .xl\:-m-40p {
    margin: -40% !important;
  }

  .xl\:-mx-40p {
    margin-left: -40% !important;
    margin-right: -40% !important;
  }

  .xl\:-mt-40p {
    margin-top: -40% !important;
  }

  .xl\:-mb-40p {
    margin-bottom: -40% !important;
  }

  .xl\:-ml-40p {
    margin-left: -40% !important;
  }

  .xl\:-mr-40p {
    margin-right: -40% !important;
  }

  .xl\:m-50p {
    margin: 50% !important;
  }

  .xl\:my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }

  .xl\:mx-50p {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }

  .xl\:mt-50p {
    margin-top: 50% !important;
  }

  .xl\:mb-50p {
    margin-bottom: 50% !important;
  }

  .xl\:mr-50p {
    margin-right: 50% !important;
  }

  .xl\:ml-50p {
    margin-left: 50% !important;
  }

  .xl\:-m-50p {
    margin: -50% !important;
  }

  .xl\:-mx-50p {
    margin-left: -50% !important;
    margin-right: -50% !important;
  }

  .xl\:-mt-50p {
    margin-top: -50% !important;
  }

  .xl\:-mb-50p {
    margin-bottom: -50% !important;
  }

  .xl\:-ml-50p {
    margin-left: -50% !important;
  }

  .xl\:-mr-50p {
    margin-right: -50% !important;
  }

  .xl\:m-60p {
    margin: 60% !important;
  }

  .xl\:my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }

  .xl\:mx-60p {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }

  .xl\:mt-60p {
    margin-top: 60% !important;
  }

  .xl\:mb-60p {
    margin-bottom: 60% !important;
  }

  .xl\:mr-60p {
    margin-right: 60% !important;
  }

  .xl\:ml-60p {
    margin-left: 60% !important;
  }

  .xl\:-m-60p {
    margin: -60% !important;
  }

  .xl\:-mx-60p {
    margin-left: -60% !important;
    margin-right: -60% !important;
  }

  .xl\:-mt-60p {
    margin-top: -60% !important;
  }

  .xl\:-mb-60p {
    margin-bottom: -60% !important;
  }

  .xl\:-ml-60p {
    margin-left: -60% !important;
  }

  .xl\:-mr-60p {
    margin-right: -60% !important;
  }

  .xl\:m-70p {
    margin: 70% !important;
  }

  .xl\:my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }

  .xl\:mx-70p {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }

  .xl\:mt-70p {
    margin-top: 70% !important;
  }

  .xl\:mb-70p {
    margin-bottom: 70% !important;
  }

  .xl\:mr-70p {
    margin-right: 70% !important;
  }

  .xl\:ml-70p {
    margin-left: 70% !important;
  }

  .xl\:-m-70p {
    margin: -70% !important;
  }

  .xl\:-mx-70p {
    margin-left: -70% !important;
    margin-right: -70% !important;
  }

  .xl\:-mt-70p {
    margin-top: -70% !important;
  }

  .xl\:-mb-70p {
    margin-bottom: -70% !important;
  }

  .xl\:-ml-70p {
    margin-left: -70% !important;
  }

  .xl\:-mr-70p {
    margin-right: -70% !important;
  }

  .xl\:m-80p {
    margin: 80% !important;
  }

  .xl\:my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }

  .xl\:mx-80p {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }

  .xl\:mt-80p {
    margin-top: 80% !important;
  }

  .xl\:mb-80p {
    margin-bottom: 80% !important;
  }

  .xl\:mr-80p {
    margin-right: 80% !important;
  }

  .xl\:ml-80p {
    margin-left: 80% !important;
  }

  .xl\:-m-80p {
    margin: -80% !important;
  }

  .xl\:-mx-80p {
    margin-left: -80% !important;
    margin-right: -80% !important;
  }

  .xl\:-mt-80p {
    margin-top: -80% !important;
  }

  .xl\:-mb-80p {
    margin-bottom: -80% !important;
  }

  .xl\:-ml-80p {
    margin-left: -80% !important;
  }

  .xl\:-mr-80p {
    margin-right: -80% !important;
  }

  .xl\:m-90p {
    margin: 90% !important;
  }

  .xl\:my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }

  .xl\:mx-90p {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }

  .xl\:mt-90p {
    margin-top: 90% !important;
  }

  .xl\:mb-90p {
    margin-bottom: 90% !important;
  }

  .xl\:mr-90p {
    margin-right: 90% !important;
  }

  .xl\:ml-90p {
    margin-left: 90% !important;
  }

  .xl\:-m-90p {
    margin: -90% !important;
  }

  .xl\:-mx-90p {
    margin-left: -90% !important;
    margin-right: -90% !important;
  }

  .xl\:-mt-90p {
    margin-top: -90% !important;
  }

  .xl\:-mb-90p {
    margin-bottom: -90% !important;
  }

  .xl\:-ml-90p {
    margin-left: -90% !important;
  }

  .xl\:-mr-90p {
    margin-right: -90% !important;
  }

  .xl\:m-100p {
    margin: 100% !important;
  }

  .xl\:my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }

  .xl\:mx-100p {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }

  .xl\:mt-100p {
    margin-top: 100% !important;
  }

  .xl\:mb-100p {
    margin-bottom: 100% !important;
  }

  .xl\:mr-100p {
    margin-right: 100% !important;
  }

  .xl\:ml-100p {
    margin-left: 100% !important;
  }

  .xl\:-m-100p {
    margin: -100% !important;
  }

  .xl\:-mx-100p {
    margin-left: -100% !important;
    margin-right: -100% !important;
  }

  .xl\:-mt-100p {
    margin-top: -100% !important;
  }

  .xl\:-mb-100p {
    margin-bottom: -100% !important;
  }

  .xl\:-ml-100p {
    margin-left: -100% !important;
  }

  .xl\:-mr-100p {
    margin-right: -100% !important;
  }

  .xl\:fixed {
    position: fixed !important;
  }

  .xl\:absolute {
    position: absolute !important;
  }

  .xl\:relative {
    position: relative !important;
  }

  .xl\:sticky {
    position: sticky !important;
  }

  .xl\:top-0 {
    top: 0 !important;
  }

  .xl\:right-0 {
    right: 0 !important;
  }

  .xl\:bottom-0 {
    bottom: 0 !important;
  }

  .xl\:left-0 {
    left: 0 !important;
  }

  .xl\:trbl-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-10 {
    z-index: 10 !important;
  }

  .xl\:z-20 {
    z-index: 20 !important;
  }

  .xl\:z-100 {
    z-index: 100 !important;
  }

  .-xl\:z-1 {
    z-index: -1 !important;
  }

  .xl\:border-0 {
    border: 0 !important;
  }

  .xl\:round {
    border-radius: 100vw !important;
  }

  .xl\:rounded-0 {
    border-radius: 0 !important;
  }

  .xl\:rounded-xxl {
    border-radius: 2rem !important;
  }

  .xl\:rounded-xl {
    border-radius: 1rem !important;
  }

  .xl\:rounded-lg {
    border-radius: 0.75rem !important;
  }

  .xl\:rounded-md {
    border-radius: 0.5rem !important;
  }

  .xl\:rounded-sm {
    border-radius: 0.25rem !important;
  }

  .xl\:rounded-xs {
    border-radius: 0.125rem !important;
  }
}
/* open-sans-regular - latin */
@font-face {
  font-display: auto;
  font-family: "Open Sans";
  src: url("../fonts/open-sans-v18-latin-regular.eot");
  src: url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg");
  font-style: normal;
  font-weight: 400;
}
/* hind-700 - latin */
@font-face {
  font-display: auto;
  font-family: "Hind";
  src: url("../fonts/hind-v11-latin-700.eot");
  src: url("../fonts/hind-v11-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/hind-v11-latin-700.woff2") format("woff2"), url("../fonts/hind-v11-latin-700.woff") format("woff"), url("../fonts/hind-v11-latin-700.ttf") format("truetype"), url("../fonts/hind-v11-latin-700.svg#Hind") format("svg");
  font-style: normal;
  font-weight: 700;
}
.font-brand {
  font-family: sans-serif;
}

h1,
h2,
h3,
.is-style-default {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h1,
h2,
h3,
.is-style-default {
    font-size: 2rem;
  }
}

.is-style-heading-size-1,
.heading-size-1 {
  font-size: calc(1.875rem + 7.5vw);
}
@media (min-width: 1200px) {
  .is-style-heading-size-1,
.heading-size-1 {
    font-size: 7.5rem;
  }
}

.is-style-heading-size-2,
.heading-size-2 {
  font-size: calc(1.625rem + 4.5vw);
}
@media (min-width: 1200px) {
  .is-style-heading-size-2,
.heading-size-2 {
    font-size: 5rem;
  }
}

.is-style-heading-size-3,
.heading-size-3 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .is-style-heading-size-3,
.heading-size-3 {
    font-size: 2rem;
  }
}

.is-style-heading-size-4,
.heading-size-4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .is-style-heading-size-4,
.heading-size-4 {
    font-size: 1.5rem;
  }
}

.is-style-heading-size-4,
.heading-size-4,
.is-style-heading-size-3,
.heading-size-3,
.is-style-heading-size-2,
.heading-size-2,
.is-style-heading-size-1,
.heading-size-1,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Hind";
  text-transform: uppercase;
}

/** Common */
/**
 * Global
 */
body {
  font-family: "Open Sans";
}

a {
  color: #25373e;
  text-decoration: none;
  background-color: transparent;
  transition: opacity 100ms ease-in-out;
}
a:hover {
  opacity: 0.85;
}

svg,
img,
embed,
object {
  display: block;
  height: auto;
  max-width: 100%;
}

#map {
  height: 600px;
  width: 100%;
}

/** Components */
/**
* Gutenberg Color helper classes
* - rememer to set colors for backend
*/
.has-primary-color {
  color: #25373e;
}

.has-primary-background-color {
  background-color: #25373e;
}

.has-secondary-color {
  color: #e9a844;
}

.has-secondary-background-color {
  background-color: #e9a844;
}

.has-tertiary-color {
  color: #3d6574;
}

.has-tertiary-background-color {
  background-color: #3d6574;
}

.has-white-color {
  color: #fff;
}

.has-white-background-color {
  background-color: #fff;
}

.has-light-primary-color {
  color: #25373eed;
}

.has-light-primary-background-color {
  background-color: #25373eed;
}

.has-extra-light-primary-color {
  color: #25383d0f;
}

.has-extra-light-primary-background-color {
  background-color: #25383d0f;
}

.has-light-secondary-color {
  color: #e9a8449c;
}

.has-light-secondary-background-color {
  background-color: #e9a8449c;
}

/**
* Container settings for Gutenberg Markup
*/
.container, .site-content > *, .wp-block-group__inner-container {
  margin-left: auto !important;
  margin-right: auto !important;
}

.wp-block-group.no-container-margin .wp-block-group__inner-container {
  margin: 0 !important;
  max-width: unset;
}
.wp-block-group.marker-before {
  padding-left: 4em;
  margin-top: -50px;
}
.wp-block-group.marker-before::before {
  content: "";
  background-size: 50px 50px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  top: 53px;
  left: -4em;
  background-image: url("../img/green-marker.svg");
}
.wp-block-group.marker-before.marker-white::before {
  background-image: url("../img/white-marker.svg");
}
.wp-block-group.marker-before.marker-bestattungen {
  padding-left: 5em;
  margin-top: 0;
}
.wp-block-group.marker-before.marker-bestattungen::before {
  position: absolute;
  top: 0;
  left: 2em;
}

.wp-block-column .mail-tel a {
  color: #e9a844;
}
.wp-block-column .mail-tel a::before {
  content: "";
  background-size: 30px 30px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 30px;
  position: relative;
  top: 10px;
}
.wp-block-column .mail-tel a:first-of-type::before {
  background-image: url("../img/telefon-icon.svg");
}
.wp-block-column .mail-tel a:last-of-type::before {
  background-image: url("../img/mail-icon.svg");
}

.wp-block-separator.is-style-wide {
  border: 1px solid;
}

.alignfull {
  max-width: 100%;
}

.wp-block-button a {
  font-family: "Hind";
  text-transform: uppercase;
}

/**
* Gutenberg Circle Block
*/
.circles {
  position: absolute;
  height: 22em;
  width: 100%;
  z-index: 0;
}
@media (max-width: 991px) {
  .circles.circles-hide-mobile {
    display: none;
  }
}
.circles .circle-animated {
  content: "";
  border-radius: 50%;
  border: 1px solid #25373e;
  width: 150px;
  height: 150px;
  position: absolute;
  opacity: 0;
  left: -5em;
  top: 5em;
}

@keyframes scaleIn {
  from {
    transform: scale(0.1, 0.1);
    opacity: 1;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
/**
* Gutenberg Accordion Plugin
*/
.c-accordion__title {
  color: #25373e;
  border-bottom: 1px solid #25373e;
  padding-bottom: 0.5em;
  padding-right: 3em;
  margin: 1em 0;
  font-size: 1em;
}
@media (max-width: 991px) {
  .c-accordion__title {
    padding-right: 4em;
    font-size: 1em;
  }
}
.c-accordion__title span {
  display: flex;
  font-size: 17px;
  color: black;
  font-weight: normal;
  margin-top: 5px;
}
.c-accordion__title::after {
  content: "˅";
  top: 30%;
  background-color: none;
  border-radius: 28px;
  color: #25373e;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  transition: all 0.5s ease;
}
@media (max-width: 991px) {
  .c-accordion__title::after {
    font-size: 12px;
    padding: 8px 14px;
  }
}
.c-accordion__title:focus {
  outline: none;
}

.is-open .c-accordion__title::after {
  content: "˄";
  font-weight: bold;
  padding: 12px 15px;
}

.c-accordion__content img {
  height: auto;
}

/**
* Add Helper Classes
*/
@media (min-width: 600px) {
  .xs-show,
.xs-block {
    display: block !important;
  }

  .xs-flex {
    display: flex !important;
  }

  .xs-initial {
    display: initial !important;
  }
}
@media (min-width: 782px) {
  .sm-show,
.sm-block {
    display: block !important;
  }

  .sm-flex {
    display: flex !important;
  }

  .sm-initial {
    display: initial !important;
  }
}
@media (min-width: 1024px) {
  .md-show,
.md-block {
    display: block !important;
  }

  .md-flex {
    display: flex !important;
  }

  .md-initial {
    display: initial !important;
  }
}
@media (min-width: 1220px) {
  .lg-show,
.lg-block {
    display: block !important;
  }

  .lg-flex {
    display: flex !important;
  }

  .lg-initial {
    display: initial !important;
  }
}
@media (min-width: 1460px) {
  .xl-show,
.xl-block {
    display: block !important;
  }

  .xl-flex {
    display: flex !important;
  }

  .xl-initial {
    display: initial !important;
  }
}
@media (max-width: 600px) {
  .block-media-text-reverse-mobile .wp-block-media-text__media {
    grid-row: 2 !important;
  }
  .block-media-text-reverse-mobile .wp-block-media-text__content {
    grid-row: 1 !important;
  }
  .block-media-text-reverse-mobile.has-media-on-the-right .wp-block-media-text__media {
    grid-row: 1 !important;
  }
  .block-media-text-reverse-mobile.has-media-on-the-right .wp-block-media-text__content {
    grid-row: 2 !important;
  }
}
/**
 * Button
 */
.faq-btn {
  z-index: 50;
  position: absolute;
}

/**
 * Form
 */
.hf-form {
  color: #25373e;
}
.hf-form .hf-message {
  text-align: center;
  font-family: "Hind";
  font-size: 2em;
  margin: 3em 0;
}
.hf-form input[type=text],
.hf-form input[type=email],
.hf-form textarea,
.hf-form select {
  background: #25383d0f;
  color: #25373e;
  padding: 1rem;
  border: 0;
}
.hf-form input[type=text]:focus,
.hf-form input[type=email]:focus,
.hf-form textarea:focus,
.hf-form select:focus {
  border: 1px solid #25373e !important;
  outline: 0;
}
.hf-form input[type=submit],
.hf-form button[type=submit] {
  border: 0;
  background: #e9a844;
  padding: 1em;
  color: #25373e;
}
.hf-form input[type=submit]:hover,
.hf-form button[type=submit]:hover {
  cursor: pointer;
}

/** Partials */
/**
 * Header
 */
.site-header {
  top: 0;
  background: white;
  transition: all 2s ease;
  transform: translateX(0%);
}
@media (min-width: 782px) {
  .site-header.active {
    background: none;
  }
  .site-header.active .menu {
    height: 0;
  }
  .site-header.transform {
    transform: translateY(-100%);
  }
}

.nav-main-link {
  color: #25373e;
}

.main-logo-link {
  display: flex;
  align-items: center;
  height: 100%;
}

li.menu-item.menu-item-26 {
  background: #e9a844;
}
li.menu-item.menu-item-26.page-list__item--current {
  background: #e9a8449c;
}
li.menu-item.page-list__item--current {
  background: #25383d0f;
}
li.menu-item a {
  color: #25373e;
  font-family: "Hind";
  text-transform: uppercase;
}

nav .menu-content {
  min-height: 4rem;
  transition: all 2s ease;
  transform: translateX(0%);
}
@media (max-width: 782px) {
  nav .menu-content {
    height: 4rem;
  }
}
nav .menu-content .main-logo {
  max-width: 150px;
  height: auto;
}
@media (max-width: 782px) {
  nav .menu-content .main-logo {
    max-width: 100px;
  }
}
nav .menu-content li.menu-item-has-children::after {
  content: "▼";
  font-family: "Hind";
  position: relative;
  left: -40px;
  top: 0;
  font-size: 13px;
}
nav .menu-content .nav-drop {
  position: fixed;
  top: 4rem;
  background: #e9a844;
  overflow: hidden;
  font-size: initial;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column;
  transition: all 1s ease;
  /* stylelint-disable */
  max-height: 0;
  /* stylelint-enable */
}
@media (max-width: 782px) {
  nav .menu-content .nav-drop {
    top: 4rem;
    right: 0;
    width: 100%;
  }
}
nav .menu-content .nav-drop.show {
  max-height: 40em;
}
nav .menu-content .nav-drop li {
  padding: 1em 0;
}
nav .menu-content .nav-drop li a {
  color: white;
}
@media (min-width: 782px) {
  nav .menu-content.active {
    flex-direction: column;
  }
  nav .menu-content.active li.menu-item-has-children {
    position: relative;
  }
  nav .menu-content.active li.menu-item-has-children::after {
    position: absolute;
    margin: 1.45em auto;
    right: 0;
    width: 1em;
  }
  nav .menu-content.active .main-logo {
    display: none;
  }
  nav .menu-content.active .desktop-menu {
    flex-direction: column;
    margin-top: 15vh;
  }
  nav .menu-content.active li.menu-item {
    color: #25373e;
    background: #ffffffc2;
  }
  nav .menu-content.active li.menu-item.menu-item-26 {
    background: #e9a844;
  }
  nav .menu-content.active .menu-item-has-children {
    flex-direction: column;
    align-items: baseline;
    overflow: hidden;
  }
  nav .menu-content.active .menu-item-has-children .nav-drop {
    display: contents;
  }
  nav .menu-content.active .menu-item-has-children .nav-drop li {
    height: 0;
    padding: 0;
  }
  nav .menu-content.active .menu-item-has-children .nav-drop li a {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  nav .menu-content.active .menu-item-has-children .nav-drop.show li {
    height: auto;
    padding: 1em 0;
    background: #e9a8449c;
    width: 100%;
  }
  nav .menu-content.transform {
    transform: translateX(100%);
  }
}
nav .menu-content .burger-menu {
  font-size: 1.5em;
}
nav .menu-content .burger-menu::after {
  content: "" !important;
}
nav .menu-content .burger-menu a {
  color: #25373e;
}
nav .menu-content .burger-menu .nav-drop li {
  text-align: center;
  width: 100%;
  transition: padding 0.5s ease;
}
nav .menu-content .burger-menu .nav-drop li.menu-transform {
  padding: 0;
}
nav .menu-content .burger-menu .nav-drop li::after {
  content: "" !important;
}
nav .menu-content .burger-menu .nav-drop li .nav-drop {
  transition: none;
}
nav .menu-content .burger-menu .nav-drop li .nav-drop.show {
  display: contents;
}
nav .menu-content .burger-menu .nav-drop li .nav-drop.show li {
  background: white;
}
nav .menu-content .burger-menu .nav-drop li .nav-drop.show li a {
  color: #25373e;
}

@media (max-width: 782px) {
  .desktop-menu {
    display: none !important;
  }
}

@media (max-width: 782px) {
  .mobile-menu {
    display: flex !important;
  }
}

.start-header {
  position: relative;
  color: white;
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}
@media (max-width: 782px) {
  .start-header {
    height: calc(100vh - 4rem);
  }
}
.start-header .wp-buttons {
  bottom: 4em;
  position: absolute;
  width: 100%;
}
.start-header .main-logo-link {
  height: auto;
  justify-content: center;
  margin-top: 4em;
}
.start-header h1 {
  padding: 2px;
  font-size: 5.5em;
  font-weight: bold;
  letter-spacing: 11px;
  text-align: center;
  display: inline-block;
}
.start-header video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
}

.site-footer {
  background: #25373eed;
  color: white;
}
.site-footer a {
  color: #e9a844;
}
.site-footer h4 {
  color: #e9a844;
}
.site-footer .main-logo {
  width: 13em;
}
.site-footer nav.site-footer--nav li {
  list-style: none;
}
.site-footer nav.site-footer--nav li a {
  color: white;
  font-family: "Open Sans";
  text-transform: inherit;
}
.site-footer .pre-footer {
  background: #25373eed;
}
.site-footer .pre-footer nav.site-footer--nav {
  display: flex;
}
.site-footer .pre-footer nav.site-footer--nav li {
  padding-right: 1em;
}
.site-footer .pre-footer nav.site-footer--nav li a {
  font-family: "Hind";
  text-transform: uppercase;
}