/**
 * Header
 */

.site-header {
  top: 0;
  background: white;
  transition: all 2s ease;
  transform: translateX(0%);
  @media (min-width: breakpoint(sm)) {
    &.active {
      background: none;

      .menu {
        height: 0;
      }
    }

    &.transform {
      transform: translateY(-100%);
    }
  }
}

.nav-main {
  &-link {
    color: color('primary');
  }
}

// Logo size
.main-logo-link {
  display: flex;
  align-items: center;
  height: 100%;
}

li.menu-item {
  &.menu-item-26 {
    background: color('secondary');

    &.page-list__item--current {
      background: color('light-secondary');
    }
  }

  &.page-list__item--current {
    background: color('extra-light-primary');
  }

  a {
    color: color('primary');
    font-family: $font-family-heading;
    text-transform: uppercase;
  }
}

nav {
  .menu-content {
    min-height: 4rem;
    transition: all 2s ease;
    transform: translateX(0%);

    @media (max-width: breakpoint(sm)) {
      height: 4rem;
    }
    // Logo size
    .main-logo {
      max-width: 150px;
      height: auto;
      @media (max-width: breakpoint(sm)) {
        max-width: 100px;
      }
    }

    li.menu-item-has-children {
      &::after {
        content: '▼'; // USE BACKGROUND with ICON
        font-family: $font-family-heading;
        position: relative;
        left: -40px;
        top: 0;
        font-size: 13px;
      }
    }

    .nav-drop {
      position: fixed;
      top: 4rem;
      background: color('secondary');
      overflow: hidden;
      font-size: initial;
      height: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-flow: column;
      transition: all 1s ease;
      /* stylelint-disable */
      max-height: 0;

      @media (max-width: breakpoint(sm)) {
        top: 4rem;
        right: 0;
        width: 100%;
      }

      &.show {
        max-height: 40em;
      }
      /* stylelint-enable */

      li {
        padding: 1em 0;

        a {
          color: white;
        }
      }
    }

    @media (min-width: breakpoint(sm)) {
      &.active {
        flex-direction: column;

        li.menu-item-has-children {
          position: relative;

          &::after {
            position: absolute;
            margin: 1.45em auto;
            right: 0;
            width: 1em;
          }
        }

        .main-logo {
          display: none;
        }

        .desktop-menu {
          flex-direction: column;
          margin-top: 15vh;
        }

        li.menu-item {
          color: color('primary');
          background: #ffffffc2;

          &.menu-item-26 {
            background: #e9a844;
          }
        }

        .menu-item-has-children {
          flex-direction: column;
          align-items: baseline;
          overflow: hidden;

          .nav-drop {
            display: contents;

            li {
              height: 0;
              padding: 0;

              a {
                padding-left: 3rem !important;
                padding-right: 3rem !important;
              }
            }

            &.show {
              li {
                height: auto;
                padding: 1em 0;
                background: color('light-secondary');
                width: 100%;
              }
            }
          }
        }
      }

      &.transform {
        transform: translateX(100%);
      }
    }

    .burger-menu {
      font-size: 1.5em;

      &::after {
        content: '' !important;
      }

      a {
        color: color('primary');
      }

      .nav-drop li {
        text-align: center;
        width: 100%;
        transition: padding 0.5s ease;

        &.menu-transform {
          padding: 0;
        }

        &::after {
          content: '' !important;
        }

        .nav-drop {
          transition: none;

          &.show {
            display: contents;

            li {
              background: white;

              a {
                color: color('primary');
              }
            }
          }
        }
      }
    }
  }
}

.desktop-menu {
  @media (max-width: breakpoint(sm)) {
    display: none !important;
  }
}

.mobile-menu {
  @media (max-width: breakpoint(sm)) {
    display: flex !important;
  }
}

.start-header {
  position: relative;
  color: white;
  width: 100%;
  height: 100vh;

  @media (max-width: breakpoint(sm)) {
    height: calc(100vh - 4rem);
  }

  text-align: center;
  overflow: hidden;

  .wp-buttons {
    bottom: 4em;
    position: absolute;
    width: 100%;
  }

  .main-logo-link {
    height: auto;
    justify-content: center;
    margin-top: 4em;
  }

  h1 {
    padding: 2px;
    font-size: 5.5em;
    font-weight: bold;
    letter-spacing: 11px;
    text-align: center;
    display: inline-block;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    object-position: center;
    object-fit: cover;
  }
}
