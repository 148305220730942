// *
// * Include Fonts and define basic font ajustments and styling
// *

$fonts-path: '../fonts';

/* open-sans-regular - latin */
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  src: url($fonts-path+'/open-sans-v18-latin-regular.eot');
  src: url($fonts-path+'/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    url($fonts-path+'/open-sans-v18-latin-regular.woff2') format('woff2'), url($fonts-path+'/open-sans-v18-latin-regular.woff') format('woff'),
    url($fonts-path+'/open-sans-v18-latin-regular.ttf') format('truetype'), url($fonts-path+'/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
  font-style: normal;
  font-weight: 400;
}

/* hind-700 - latin */
@font-face {
  font-display: auto;
  font-family: 'Hind';
  src: url($fonts-path+'/hind-v11-latin-700.eot');
  src: url($fonts-path+'/hind-v11-latin-700.eot?#iefix') format('embedded-opentype'), url($fonts-path+'/hind-v11-latin-700.woff2') format('woff2'),
    url($fonts-path+'/hind-v11-latin-700.woff') format('woff'), url($fonts-path+'/hind-v11-latin-700.ttf') format('truetype'),
    url($fonts-path+'/hind-v11-latin-700.svg#Hind') format('svg');
  font-style: normal;
  font-weight: 700;
}

.font-brand {
  font-family: sans-serif;
}

h1,
h2,
h3,
.is-style-default {
  @include font-size(2rem);
}

.is-style-heading-size-1,
.heading-size-1 {
  @include font-size(7.5rem);
}

.is-style-heading-size-2,
.heading-size-2 {
  @include font-size(5rem);
}

.is-style-heading-size-3,
.heading-size-3 {
  @include font-size(2rem);
}

.is-style-heading-size-4,
.heading-size-4 {
  @include font-size(1.5rem);
}

.is-style-heading-size-4,
.heading-size-4,
.is-style-heading-size-3,
.heading-size-3,
.is-style-heading-size-2,
.heading-size-2,
.is-style-heading-size-1,
.heading-size-1,
h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-heading;
  text-transform: uppercase;
}
